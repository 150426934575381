import { Input } from '@successmode-ktp/kui';
import FormWrapper from '../FormWrapper';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { uploadStoreImg } from '@/api/coupon';
import { getFileName } from '@/utils/file.util';

type Props = {
  value?: string;
  onChange?: (v: string) => void;
};

const StoreMainImage = ({ value, onChange }: Props) => {
  const [image, setImage] = useState(value || '');

  useEffect(() => {
    onChange && onChange(image);
  }, [image]);

  useEffect(() => {
    if (value) {
      setImage(value);
    }
  }, [value]);

  const { mutate: uploadImg } = useMutation(
    (files: any) => uploadStoreImg(files),
    {
      onSuccess: (data) => {
        const { images } = data;
        setImage(images![0]);
      },
      onError: () => {
        alert('이미지 업로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
      },
    },
  );

  const getImagePath = (file: File | undefined) => {
    if (!file) return;

    const blob = new Blob([file], { type: file.type }); // Blob 객체 생성
    const formData = new FormData();
    formData.append('files', blob, file.name);
    uploadImg(formData);
  };

  return (
    <FormWrapper
      label='대표 이미지'
      addonAfter={<FileSelect htmlFor='main_image'>파일 선택</FileSelect>}
    >
      <HideInput
        id='main_image'
        type='file'
        onChange={(e) => getImagePath(e.target.files?.[0])}
      />
      <Input
        value={getFileName(image)}
        size='lg'
        onChange={(e) => setImage(e.target.value)}
        placeholder='대표 이미지를 첨부해주세요.'
        style={{ fontSize: '14px' }}
        readOnly
      />
    </FormWrapper>
  );
};
export default StoreMainImage;

const FileSelect = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #3a3b3e;
  border-radius: 8px;
  border: 1px solid #e5e6e8;
  background-color: #f5f6f7;
  white-space: nowrap;
  padding: 4px 12px;
  cursor: pointer;
`;

const HideInput = styled.input`
  position: absolute;
  top: -100px;
  left: -100px;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
