import { GetSettlementsQuery } from '@/types/api/coupon.type';
import { getLatestYear, monthData } from '@/utils/common';
import { Dropdown } from '@successmode-ktp/kui';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = {
  onChange: (v: GetSettlementsQuery) => void;
};

const DateSelector = ({ onChange }: Props) => {
  const today = new Date();
  const todayYear = today.getFullYear().toString();
  const todayMonth = (today.getMonth() + 1).toString();
  const [year, setYear] = useState(todayYear);
  const [month, setMonth] = useState(todayMonth);

  useEffect(() => {
    const firstDay = new Date(Number(year), Number(month) - 1, 1);
    const lastDay = new Date(Number(year), Number(month), 0);
    onChange({
      startDate: format(firstDay, 'yyyy-MM-dd'),
      endDate: format(lastDay, 'yyyy-MM-dd'),
    });
  }, [year, month]);

  return (
    <Wrapper>
      <Dropdown
        options={getLatestYear(2024, new Date().getFullYear())}
        selectedValue={year}
        setSelectedValue={setYear}
        size='md'
        style={{
          width: '100px',
        }}
        variant='solid'
      />
      <Dropdown
        options={monthData}
        selectedValue={month}
        setSelectedValue={setMonth}
        size='md'
        style={{
          width: '80px',
        }}
        variant='solid'
      />
    </Wrapper>
  );
};
export default DateSelector;

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 30px;
`;
