import { Button, Icon, Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
};

function UploadConfirmModal({ isOpen, onClose, onSave }: Props) {
  return (
    <Modal isOpen={isOpen} hasBackdrop order={2}>
      <Modal.Header>
        <Title>
          <Icon.Upload size={32} fillColor='#111111' />
          안내 배너 업로드
        </Title>
      </Modal.Header>
      <Content>안내 배너를 업로드하시겠습니까?</Content>
      <Modal.Footer>
        <Modal.Button variant='cancel' onClick={onClose} />
        <Button onClick={onSave}>업로드</Button>
      </Modal.Footer>
    </Modal>
  );
}

const Title = styled.h3`
  margin: 0;
  color: #030303;
  font-size: 21px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const Content = styled.p`
  width: 436px;
  color: #5f6165;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
`;

export default UploadConfirmModal;
