import store from '@/store';
import api from '..';
import { ActiveAdPayload, AdResponse, SwitchAdPayload } from '@/types/ad.type';

const getTargetServerURL = () => {
  return store.getState().serverState === 'prod'
    ? 'https://erp.successmode.co.kr'
    : 'https://erp.successmode.co.kr/tpay/test';
};

export const getAdList = async (id: number) => {
  const res = await api.getAxios<AdResponse, any>(
    `${getTargetServerURL()}/advertisement/${id}`,
  );
  return res.data;
};

export const uploadAd = async (payload: FormData) => {
  const res = await api.postAxios<null, FormData, null>(
    `${getTargetServerURL()}/advertisement`,
    payload,
    null,
  );
  return res.data;
};

export const switchAdList = async (payload: SwitchAdPayload) => {
  const res = await api.patchAxios<null, SwitchAdPayload, null>(
    `${getTargetServerURL()}/advertisement/priority`,
    payload,
  );
  return res.data;
};

export const activeAd = async (payload: ActiveAdPayload) => {
  const res = await api.patchAxios<null, ActiveAdPayload, null>(
    `${getTargetServerURL()}/advertisement`,
    payload,
  );
  return res.data;
};

export const deleteAd = async (id: number) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/advertisement/${id}`,
  );
  return res.data;
};

export const getDefaultAdList = async () => {
  const res = await api.getAxios<AdResponse, any>(
    `${getTargetServerURL()}/advertisement/default`,
  );
  return res.data;
};

export const uploadDefaultAd = async (payload: FormData) => {
  const res = await api.postAxios<null, FormData, null>(
    `${getTargetServerURL()}/advertisement/default`,
    payload,
    null,
  );
  return res.data;
};

export const switchDefaultAdList = async (payload: SwitchAdPayload) => {
  const res = await api.patchAxios<null, SwitchAdPayload, null>(
    `${getTargetServerURL()}/advertisement/default/priority`,
    payload,
  );
  return res.data;
};

export const activeDefaultAd = async (payload: ActiveAdPayload) => {
  const res = await api.patchAxios<null, ActiveAdPayload, null>(
    `${getTargetServerURL()}/advertisement/default`,
    payload,
  );
  return res.data;
};

export const deleteDefaultAd = async (id: number) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/advertisement/default/${id}`,
  );
  return res.data;
};
