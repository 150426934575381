import { Quill } from 'react-quill';
import './EditorToolbar.css';

const Size = Quill.import('formats/size');
Size.whitelist = ['10', '13', '16', '18', '20', '24', '32', '48', '64'];
Quill.register(Size, true);

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {},
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

export const EditorToolbar = () => (
  <div id='toolbar'>
    <span className='ql-formats'>
      <button className='ql-bold' />
      <button className='ql-italic' />
      <button className='ql-underline' />
      <button className='ql-strike' />
    </span>
    <span className='ql-formats'>
      <select className='ql-size'>
        <option value='10'>10 px</option>
        <option value='13' selected>
          13 px
        </option>
        <option value='16'>16 px</option>
        <option value='18'>18 px</option>
        <option value='20'>20 px</option>
        <option value='24'>24 px</option>
        <option value='32'>32 px</option>
        <option value='48'>48 px</option>
        <option value='64'>64 px</option>
      </select>
      <select className='ql-color' />
      <select className='ql-background' />
    </span>
    <span className='ql-formats'>
      <button className='ql-list' value='ordered' />
      <button className='ql-list' value='bullet' />
      <button className='ql-indent' value='-1' />
      <button className='ql-indent' value='+1' />
      <select className='ql-align'>
        <option value='' selected />
        <option value='center' />
        <option value='right' />
        <option value='justify' />
      </select>
    </span>
    <span className='ql-formats'>
      <button className='ql-link' />
      <button className='ql-image' />
    </span>
  </div>
);

export default EditorToolbar;
