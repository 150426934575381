import { TableBody, TableRow } from '@material-ui/core';
import CustomTableCell from '../../common/CustomTableCell';
import styled from 'styled-components';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';
import { Store } from '@/types/api/coupon.type';

type Props = {
  data: Store[];
};

function StoreList({ data }: Props) {
  const updateUrlParams = useUpdateUrlParams();

  const onMoveDetail = (id: string, name: string) => {
    updateUrlParams({ id, name });
  };

  return (
    <TableBody>
      {data?.map((store) => (
        <StyledTableRow
          key={store.tpayFranchiseeId}
          onClick={() =>
            onMoveDetail(store.promote_store_id, store.promote_store_name)
          }
        >
          <CustomTableCell style={{ paddingLeft: '18px', width: '90px' }}>
            {store.tpayFranchiseeId}
          </CustomTableCell>
          <CustomTableCell width='200'>
            {store.promote_store_name}
          </CustomTableCell>
          <CustomTableCell>{store.created_at.split('T')[0]}</CustomTableCell>
        </StyledTableRow>
      ))}
    </TableBody>
  );
}

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;

export default StoreList;
