import { Input } from '@successmode-ktp/kui';
import FormWrapper from '../FormWrapper';
import { useEffect, useState } from 'react';

type Props = {
  value?: string;
  onChange?: (v: string) => void;
};

const StoreNumber = ({ value, onChange }: Props) => {
  const [number, setNumber] = useState(value || '');

  useEffect(() => {
    onChange && onChange(number);
  }, [number]);

  useEffect(() => {
    if (value) {
      setNumber(value);
    }
  }, [value]);

  return (
    <FormWrapper label='연락처' required>
      <Input
        value={number}
        size='lg'
        onChange={(e) => setNumber(e.target.value)}
        placeholder='예) 010-1234-1234'
        style={{ fontSize: '14px' }}
      />
    </FormWrapper>
  );
};
export default StoreNumber;
