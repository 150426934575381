import { ReportSummary } from '@/types/report';
import { comma } from '@/utils/ktp';
import { nationCodeToName } from '@/utils/report';
import styled from 'styled-components';

type Props = {
  data: ReportSummary;
  startDate: string;
  endDate: string;
};

function Summary({ data, startDate, endDate }: Props) {
  const summaryList = [
    {
      title: '매장명',
      data: data.store,
    },
    {
      title: '환급 건수',
      data: `${comma(data.count)}건`,
    },
    {
      title: '총 고객결제금액',
      data: `${comma(data.totalPrice)}원`,
    },
    {
      title: '총 환급금액',
      data: `${comma(data.totalRefund)}원`,
    },
    {
      title: '최대구매국적',
      data: nationCodeToName(data.nation).name,
    },
    {
      title: '최다구매일',
      data: data.date,
    },
  ];

  return (
    <Section>
      <SummaryTitle>
        SUMMARY
        <span>
          ({startDate} ~ {endDate})
        </span>
      </SummaryTitle>
      <SummaryList>
        {summaryList.map((item, index) => (
          <SummaryItem key={index}>
            <SummaryKey>{item.title}</SummaryKey>
            <SummaryValue
              index={index}
              style={{
                fontSize: `${
                  item.title === '매장명' && item.data && item.data.length > 10
                    ? 13
                    : 16
                }px`,
              }}
            >
              {item.data}
            </SummaryValue>
          </SummaryItem>
        ))}
      </SummaryList>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SummaryTitle = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 22px;
  font-size: 16px;
  color: #ffffff;
  background-color: #1856cd;
  font-weight: 500;

  span {
    font-size: 12px;
    margin-left: 4px;
  }
`;

const SummaryList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  border-top: 4px solid #1856cd;
  border-bottom: 4px solid #1856cd;
`;

const SummaryItem = styled.div`
  width: 50%;
  display: flex;
  height: 36px;
  font-size: 14px;
`;

const SummaryKey = styled.dt`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`;

const SummaryValue = styled.dd<{ index: number }>`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
`;

export default Summary;
