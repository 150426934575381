import styled from 'styled-components';
import { Button, Icon } from '@successmode-ktp/kui';
import ICON_CHECKBOX from '@/assets/common/check.png';
import ICON_CHECKBOX_FILL from '@/assets/common/checked.png';
import { useLocation } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { downloadSummaryRefund, downloadTotalRefund } from '@/api/ktp';
import {
  downloadSummaryRefund as downloadSummaryRefundHospital,
  downloadTotalRefund as downloadTotalRefundHospital,
} from '@/api/medical';

type Props = {
  isOpen: boolean;
  onToggle: () => void;
  title: string;
  isSummary?: boolean;
  isMedical?: boolean;
};

function FileSelector({
  isOpen,
  onToggle,
  title,
  isSummary = false,
  isMedical = false,
}: Props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const year = searchParams.get('year') || new Date().getFullYear().toString();
  const month =
    searchParams.get('month') || (new Date().getMonth() + 1).toString();

  const [selection, setSelection] = useState<string[]>([]);

  const isQuarter = ['3', '6', '9', '12'].includes(month);

  const FILE_OPTIONS = useMemo(() => {
    if (isQuarter) {
      return [
        {
          title: '월별',
          value: 'MONTH',
        },
        {
          title: '분기별',
          value: 'QUARTER',
        },
      ];
    }

    return [
      {
        title: '월별',
        value: 'MONTH',
      },
    ];
  }, [isQuarter]);

  const handleSelect = (value: string) => {
    const newValue = selection.includes(value)
      ? selection.filter((item) => item !== value)
      : [...selection, value];
    setSelection(newValue);
  };

  const { mutate: mutateDownloadAll, isLoading } = useMutation(
    isSummary
      ? isMedical
        ? downloadSummaryRefundHospital
        : downloadSummaryRefund
      : isMedical
      ? downloadTotalRefundHospital
      : downloadTotalRefund,
  );

  const handleDownloadAll = () => {
    if (isSummary) {
      selection.forEach((s) => {
        const isQuarter =
          s === 'QUARTER' && ['3', '6', '9', '12'].includes(month);
        mutateDownloadAll(
          { isQuarter, month, year },
          {
            onSuccess: () => {
              alert('파일 생성 및 다운로드가 완료되었습니다');
              setSelection([]);
            },
          },
        );
      });
    } else {
      mutateDownloadAll(
        { isQuarter: false, month, year },
        {
          onSuccess: () => {
            alert('파일 생성 및 다운로드가 완료되었습니다');
            setSelection([]);
          },
        },
      );
    }
  };

  return (
    <Dropdown>
      <ButtonWrapper type='button' onClick={onToggle}>
        <span>{title}</span>
        <Caret isOpen={isOpen}>
          <Icon.ArrowDropUp />
        </Caret>
      </ButtonWrapper>
      {isOpen && (
        <Wrapper>
          {isSummary &&
            FILE_OPTIONS.map((option) => {
              return (
                <OptionWrapper
                  key={option.value}
                  type='button'
                  onClick={() => handleSelect(option.value)}
                >
                  {selection.find((item) => item === option.value) ? (
                    <img src={ICON_CHECKBOX_FILL} alt='' width={24} />
                  ) : (
                    <img src={ICON_CHECKBOX} alt='' width={24} />
                  )}
                  {option.title}
                </OptionWrapper>
              );
            })}
          <Button
            size='sm'
            type='button'
            onClick={handleDownloadAll}
            disabled={(isSummary && selection.length === 0) || isLoading}
          >
            다운로드
          </Button>
        </Wrapper>
      )}
    </Dropdown>
  );
}

const Dropdown = styled.div`
  width: 132px;
  padding: 6px 16px;
  border-radius: 6px;
  border: 1px solid #cccccc;
  background: #ffffff;
  z-index: 10;
  height: 100%;
`;

const ButtonWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
`;

const OptionWrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #3a3b3e;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.28px;
`;

const Caret = styled.i<{ isOpen: boolean }>`
  transform: ${(props) => (props.isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: all 200ms;
`;

export default FileSelector;
