import Summary from '../section/Summary';
import Nation from '../section/Nation';
import NationDate from '../section/NationDate';
import PageWrapper from './PageWrapper';
import { ReportDateNation, ReportNation, ReportSummary } from '@/types/report';
import { useMemo } from 'react';

type Props = {
  id: number;
  name: string;
  nationData?: ReportNation;
  dateNationData?: ReportDateNation;
};

function Page1({ id, name, nationData, dateNationData, ...props }: Props) {
  const dayData = useMemo(() => {
    if (dateNationData) {
      const entries = Object.entries(dateNationData);
      const firstEntry = entries[0];
      const lastEntry = entries[entries.length - 1];

      const startDate = `${firstEntry[0].split('-')[1]}/${
        firstEntry[0].split('-')[2]
      }`;
      const endDate = `${lastEntry[0].split('-')[1]}/${
        lastEntry[0].split('-')[2]
      }`;

      return { startDate, endDate };
    }
    return { startDate: '', endDate: '' };
  }, [dateNationData]);

  const summary = {
    store: name,
    ...props,
  } as ReportSummary;

  return (
    <PageWrapper>
      <Summary
        data={summary}
        startDate={dayData.startDate}
        endDate={dayData.endDate}
      />
      <Nation data={nationData} />
      <NationDate data={dateNationData} />
    </PageWrapper>
  );
}

export default Page1;
