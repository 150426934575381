import { Button, Icon, Popover } from '@successmode-ktp/kui';
import styled from 'styled-components';
import { useMemo, useRef, useState } from 'react';
import Form from './Form';
import { format, isBefore, parseISO } from 'date-fns';
import UploadConfirmModal from './UploadConfirmModal';
import { useMutation, useQueryClient } from 'react-query';
import { uploadBanner } from '@/api/medical';
import Editor from '@/components/medical/banner/Editor';
import PreviewBannerModal from '@/components/medical/banner/PreviewBannerModal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function CreateBannerModal({ isOpen, onClose }: Props) {
  const queryClient = useQueryClient();
  const quillRef = useRef(null);
  const [state, setState] = useState<any>({
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    starHour: '',
    endHour: '',
    starMinute: '',
    endMinute: '',
    originalVisible: true,
    directVisible: true,
    kioskVisible: true,
    isActivated: true,
    title: '',
    content: '',
    isReserved: false,
    noEndDate: true,
  });

  const [isOpenUploadConfirm, setIsOpenUploadConfirm] = useState(false);
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);

  const setHtmlContent = (value: any) => {
    setState((prev: any) => ({ ...prev, content: value }));
  };

  const onChange = (name: string, value: any) => {
    if (name === 'hospitalType') {
      let newState: any;
      if (value === 'total') {
        newState =
          state.originalVisible && state.directVisible && state.kioskVisible
            ? {
                originalVisible: false,
                directVisible: false,
                kioskVisible: false,
              }
            : {
                originalVisible: true,
                directVisible: true,
                kioskVisible: true,
              };
      } else {
        newState = { [value]: !state[value] };
      }
      setState((prev: any) => ({ ...prev, ...newState }));
    } else {
      setState((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const { mutate: mutateUploadBanner } = useMutation(uploadBanner);
  const onUploadBanner = () => {
    const startDateTime = state.isReserved
      ? `${state.startDate} ${state.startHour.padStart(
          2,
          '0',
        )}:${state.startMinute.padStart(2, '0')}`
      : null;
    const endDateTime = state.noEndDate
      ? null
      : `${state.endDate} ${state.endHour.padStart(
          2,
          '0',
        )}:${state.endMinute.padStart(2, '0')}`;

    mutateUploadBanner(
      {
        startDate: state.isReserved ? startDateTime : null,
        endDate: state.noEndDate ? null : endDateTime,
        originalVisible: state.originalVisible,
        directVisible: state.directVisible,
        kioskVisible: state.kioskVisible,
        isActivated: state.isActivated,
        title: state.title,
        content: state.content,
      },
      {
        onSuccess: () => {
          alert('배너 업로드에 성공하였습니다.');
          setIsOpenUploadConfirm(false);
          onClose();
          queryClient.invalidateQueries('bannerList');
        },
        onError: () => {
          alert('배너 업로드에 실패하였습니다.');
          setIsOpenUploadConfirm(false);
        },
      },
    );
  };

  const isDisabledUpload = useMemo(() => {
    const isCheckedHospitalType =
      state.originalVisible || state.directVisible || state.kioskVisible;

    const doc = new DOMParser().parseFromString(state.content, 'text/html');
    const content = doc.body;

    const firstCheck =
      isCheckedHospitalType &&
      !!state.title &&
      content.innerHTML.trim() !== '<p><br></p>';

    if (!firstCheck) return true;

    if (state.isReserved) {
      const hasData =
        !!state.startDate && !!state.startHour && !!state.startMinute;
      if (!hasData) return true;

      const startDateTime = `${state.startDate}T${state.startHour?.padStart(
        2,
        '0',
      )}:${state.startMinute?.padStart(2, '0')}:00`;
      const isStartBeforeNow = isBefore(parseISO(startDateTime), new Date());
      if (isStartBeforeNow) return true;
    }

    if (!state.noEndDate) {
      const hasData = !!state.endDate && !!state.endHour && !!state.endMinute;
      if (!hasData) return true;

      const endDateTime = `${state.endDate}T${state.endHour?.padStart(
        2,
        '0',
      )}:${state.endMinute?.padStart(2, '0')}:00`;
      const isEndBeforeNow = isBefore(parseISO(endDateTime), new Date());
      if (isEndBeforeNow) return true;
    }

    return false;
  }, [state]);

  return (
    <>
      <Popover
        isOpen={isOpen}
        hasBackdrop
        style={{
          padding: '40px',
          borderRadius: '12px',
          maxWidth: '1248px',
          width: '90vw',
          minWidth: '920px',
          maxHeight: '95dvh',
          overflowY: 'auto',
        }}
      >
        <CloseButton onClick={onClose}>
          <Icon.X size={32} fillColor='#111111' />
        </CloseButton>
        <Title>안내배너 신규작성</Title>
        <Form state={state} onChange={onChange} />
        <Editor
          htmlContent={state.content}
          setHtmlContent={setHtmlContent}
          isEditMode={true}
          quillRef={quillRef}
        />
        <ButtonWrapper>
          <Button
            variant='line'
            style={{ width: '200px' }}
            onClick={() => setIsOpenPreviewModal(true)}
          >
            미리보기
          </Button>
          <Button
            style={{ width: '200px' }}
            onClick={() => setIsOpenUploadConfirm(true)}
            disabled={isDisabledUpload}
          >
            업로드
          </Button>
        </ButtonWrapper>
      </Popover>
      {isOpenPreviewModal && (
        <PreviewBannerModal
          isOpen={isOpenPreviewModal}
          title={state.title}
          content={state.content}
          onClose={() => setIsOpenPreviewModal(false)}
          onSave={() => {
            setIsOpenPreviewModal(false);
            setIsOpenUploadConfirm(true);
          }}
        />
      )}
      {isOpenUploadConfirm && (
        <UploadConfirmModal
          isOpen={isOpenUploadConfirm}
          onClose={() => setIsOpenUploadConfirm(false)}
          onSave={onUploadBanner}
        />
      )}
    </>
  );
}

const Title = styled.h3`
  color: #212121;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 24px;
`;

const ButtonWrapper = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 40px;
  right: 40px;
`;

export default CreateBannerModal;
