import { useHistory } from 'react-router';
import styled from 'styled-components';
import Logo from '@assets/common/logo.png';
import { MENU_LIST } from '@constants/ktp';
import store from '@/store';
import ModeSelector from './ModeSelector';

function Header() {
  const history = useHistory();

  const isCurrPath = (path: string) => {
    const currPath = history.location.pathname;
    const [_, rootUrl] = currPath.split('/');
    const [__, targetUrl] = path.split('/');
    return rootUrl === targetUrl;
  };

  return (
    <>
      <Container>
        <LogoWrapper onClick={() => history.push('/ktp/prod/home')}>
          <LogoIcon src={Logo} />
          <LogoText>ADMIN</LogoText>
        </LogoWrapper>
        <MenuList>
          {MENU_LIST.map((item, idx) => (
            <MenuItemWrapper key={idx}>
              <MenuItem
                type='button'
                onClick={() => {
                  const mode = store.getState().serverState;
                  const [firstUrl, lastUrl] = item.path.split('mode');
                  history.push(firstUrl + mode + lastUrl);
                }}
                $isActive={isCurrPath(item.path)}
              >
                {item.title}
              </MenuItem>
            </MenuItemWrapper>
          ))}
        </MenuList>
        <ModeSelector />
      </Container>
    </>
  );
}

export default Header;

const Container = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid lightgray;
  position: relative;
  background-color: #ffffff;
  z-index: 30;
  padding: 0 40px 0 0;
  @media (max-width: 1700px) {
    height: 60px;
  }
`;

const LogoWrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 280px;
  padding: 0 40px;
  cursor: pointer;
`;

const LogoIcon = styled.img`
  width: 45px;
  height: 42px;
  @media (max-width: 1700px) {
    width: 38px;
    height: 34px;
  }
`;

const LogoText = styled.h1`
  font-size: 30px;
  line-height: 38px;
  color: #2f2f2f;
  @media (max-width: 1700px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const MenuList = styled.ul`
  display: flex;
  align-items: center;
  margin-left: 24px;
  height: 100%;
`;

const MenuItemWrapper = styled.li`
  height: 100%;
`;

const MenuItem = styled.button<{ $isActive: boolean }>`
  font-size: 20px;
  font-weight: 700;
  color: ${({ $isActive }) => ($isActive ? '#354eff' : '#2f2f2f')};
  padding: 0px 40px;
  height: 100%;
  cursor: pointer;
  transition: all 0.15s;
  background-color: ${({ $isActive }) => ($isActive ? '#e9e9e9' : 'inherit')};
  &:hover {
    color: #354eff;
  }
`;
