import { Button, Icon, Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';
import { useState } from 'react';
import AddCouponModal from '../coupon/CouponAdd.modal';
import ConfirmModal from '../Confirm.modal';
import PromotionName from './PromotionName';
import PromotionPeriod from './PromotionPeriod';
import CouponCardList from '../coupon/CouponCardList';
import { useMutation, useQueryClient } from 'react-query';
import { postPromotion } from '@/api/coupon';
import { CreateCouponTemplateRequest } from '@/types/api/coupon.type';

type Props = {
  storeId: string;
  isOpen: boolean;
  onClose: () => void;
};

function PromotionAddModal({ storeId, isOpen, onClose }: Props) {
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false);
  const [name, setName] = useState('');
  const [date, setDate] = useState({
    startDate: '',
    endDate: '',
  });
  const [coupons, setCoupons] = useState<CreateCouponTemplateRequest[]>([]);

  const makeCouponList = (coupon: CreateCouponTemplateRequest) => {
    setCoupons((prev) => [...prev, coupon]);
  };

  const queryClient = useQueryClient();
  const { mutate: createPromotion } = useMutation(
    () =>
      postPromotion({
        promoteStoreId: storeId,
        promotionName: name,
        ...date,
        coupons,
      }),
    {
      onSuccess: () => {
        setIsOpenSaveModal(false);
        queryClient.invalidateQueries('getPromotions');
        onClose();
      },
    },
  );

  return (
    <>
      <Modal isOpen={isOpen} hasBackdrop>
        <Modal.Header>
          <Title>프로모션 정보</Title>
        </Modal.Header>
        <Content>
          <PromotionName onChange={(v) => setName(v)} />
          <PromotionPeriod onChange={(v) => setDate(v)} />
          <Divider />
          <Button
            variant='line'
            size='lg'
            onClick={() => setIsOpenCouponModal(true)}
          >
            <Icon.Plus size={18} fillColor='var(--Blue-100)' />
            <AddButtonText>쿠폰 추가하기</AddButtonText>
          </Button>
          <CouponCardList data={coupons} setData={setCoupons} />
        </Content>
        <Modal.Footer>
          <Modal.Button onClick={onClose} variant='cancel'>
            닫기
          </Modal.Button>
          <Modal.Button onClick={() => setIsOpenSaveModal(true)} variant='ok'>
            저장하기
          </Modal.Button>
        </Modal.Footer>
      </Modal>
      {isOpenCouponModal && (
        <AddCouponModal
          storeId={storeId}
          date={{
            issuedAt: date.startDate,
            expireAt: date.endDate,
          }}
          isOpen={isOpenCouponModal}
          onClose={() => setIsOpenCouponModal(false)}
          onSubmit={(v) => makeCouponList(v)}
        />
      )}
      <ConfirmModal
        isOpen={isOpenSaveModal}
        onClose={() => setIsOpenSaveModal(false)}
        onOK={() => createPromotion()}
        title='프로모션을 저장하시겠습니까?'
        message='프로모션과 쿠폰이 발행됩니다.'
        okLabel='저장하기'
      />
    </>
  );
}

export default PromotionAddModal;

const Title = styled.h3`
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  margin-bottom: 16px;
  text-align: center;
`;

const Content = styled.div`
  width: 440px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Divider = styled.hr`
  margin: 0;
  width: 100%;
  height: 3px;
  background-color: #f5f6f7;
  border-width: 0;
  border-radius: 100px;
`;

const AddButtonText = styled.span`
  margin-left: 8px;
`;
