import { TextArea } from '@successmode-ktp/kui';
import FormWrapper from '../FormWrapper';
import { useEffect, useState } from 'react';
import { RequestLang } from '@/types/api/coupon.type';

type Props = {
  value?: RequestLang;
  onChange?: (v: RequestLang) => void;
};

const StoreContents = ({ value, onChange }: Props) => {
  const [KO, setKO] = useState(value?.KO || '');
  const [EN, setEN] = useState(value?.EN || '');
  const [ZH, setZH] = useState(value?.ZH || '');
  const [JA, setJA] = useState(value?.JA || '');

  useEffect(() => {
    onChange && onChange({ KO, EN, ZH, JA });
  }, [KO, EN, ZH, JA]);

  useEffect(() => {
    if (value) {
      setKO(value.KO);
      setEN(value.EN);
      setZH(value.ZH);
      setJA(value.JA);
    }
  }, [value]);

  return (
    <FormWrapper label='가맹점 설명' required>
      <TextArea
        value={KO}
        onChange={(e) => setKO(e.target.value)}
        placeholder='가맹점 설명을 입력해주세요 (제목과 본문 사이에 ###을 추가해주세요)'
        style={{ fontSize: '14px' }}
        rows={5}
      />
      <TextArea
        value={EN}
        onChange={(e) => setEN(e.target.value)}
        placeholder='영어 설명을 입력해주세요'
        style={{ fontSize: '14px' }}
        rows={5}
      />
      <TextArea
        value={ZH}
        onChange={(e) => setZH(e.target.value)}
        placeholder='중국어 설명을 입력해주세요'
        style={{ fontSize: '14px' }}
        rows={5}
      />
      <TextArea
        value={JA}
        onChange={(e) => setJA(e.target.value)}
        placeholder='일본어 설명을 입력해주세요'
        style={{ fontSize: '14px' }}
        rows={5}
      />
    </FormWrapper>
  );
};
export default StoreContents;
