import { Button, Modal } from '@successmode-ktp/kui';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import checkIcon from '@/assets/common/check.png';
import checkedIcon from '@/assets/common/checked.png';
import Icon from '@/components/common/Icon';
import { comma } from '@/utils/ktp';
import { sendNonDepartureEmail } from '@/api/medical';
import { useMutation } from 'react-query';
import PreviewMailModal from '@/components/medical/nonDeparture/PreviewMailModal';

type Props = {
  children: ReactNode | ReactNode[];
  ids: number[];
  refunded: number;
  email: string;
  hospitalName: string;
  onUnCheck: () => void;
};

function SendMailSection({
  children,
  ids,
  refunded,
  email,
  hospitalName,
  onUnCheck,
}: Props) {
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenComplete, setIsOpenComplete] = useState(false);
  const [isOpenFail, setIsOpenFail] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const onToggle = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setIsChecked(checked);
  };

  const { mutate: mutateSendMail } = useMutation(sendNonDepartureEmail);
  const onSend = () => {
    mutateSendMail(
      {
        refundIds: ids,
        email,
        hospitalName,
      },
      {
        onSuccess: () => {
          setIsOpenComplete(true);
          onUnCheck();
        },
        onError: () => {
          setIsOpenFail(true);
        },
      },
    );
  };

  useEffect(() => {
    if (!isOpenModal) {
      setIsChecked(false);
    }
  }, [isOpenModal]);

  return (
    <>
      <Button
        type='button'
        onClick={() => setIsOpenModal(true)}
        disabled={refunded === 0}
      >
        {children}
      </Button>

      <Modal isOpen={isOpenModal} hasBackdrop order={1}>
        <Modal.Header>
          <Title>메일 발송</Title>
        </Modal.Header>
        <Content>
          <DescriptionWrapper>
            <Description>
              선택된 건에 대한 부가세 환불 메일을 발송하시겠습니까?
            </Description>
            <Button
              variant='line'
              onClick={() => setIsOpenPreviewModal(true)}
              style={{ width: '72px' }}
              size='sm'
            >
              미리보기
            </Button>
          </DescriptionWrapper>
          <DataList>
            <DataItem>
              <DataTitle>선택 :</DataTitle>
              <Data>{comma(ids.length)}건</Data>
            </DataItem>
            <DataItem>
              <DataTitle>환불 금액 : </DataTitle>
              <DataItem>{comma(refunded)}원</DataItem>
            </DataItem>
          </DataList>
          <Checkbox>
            <Input id='email' type='checkbox' onChange={onToggle} />
            <Icon
              imgUrl={isChecked ? checkedIcon : checkIcon}
              width='24px'
              height='24px'
            />
            <CheckboxLabel htmlFor='email'>{email}</CheckboxLabel>
          </Checkbox>
        </Content>
        <Modal.Footer>
          <Modal.Button variant='cancel' onClick={() => setIsOpenModal(false)}>
            닫기
          </Modal.Button>
          <Button onClick={onSend} disabled={!isChecked}>
            발송
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal.Confirm
        isOpen={isOpenComplete}
        state='success'
        title='전송 완료'
        message='메일이 전송되었습니다.'
        onClick={() => {
          setIsOpenComplete(false);
          setIsOpenModal(false);
        }}
        order={2}
      />
      <Modal.Confirm
        isOpen={isOpenFail}
        state='fail'
        title='전송 실패'
        message='메일을 전송하는 데 실패하였습니다.'
        onClick={() => {
          setIsOpenFail(false);
        }}
        order={2}
      />

      <PreviewMailModal
        isOpen={isOpenPreviewModal}
        onClose={() => setIsOpenPreviewModal(false)}
        count={ids.length}
        price={refunded}
      />
    </>
  );
}

export default SendMailSection;

const Title = styled.h3`
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 24px;
`;

const Content = styled.div`
  width: 436px;
  padding-bottom: 16px;
  border-bottom: 1px solid #cbccce;
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Description = styled.p`
  color: #5f6165;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;

const DataList = styled.dl`
  margin: 0 0 24px 0;
  padding: 12px;
  background-color: #f5f6f7;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #5f6165;
`;

const DataItem = styled.div`
  display: flex;
`;

const DataTitle = styled.dt`
  margin-right: 4px;
`;

const Data = styled.dd`
  margin: 0;
`;

const Checkbox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
`;

const CheckboxLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #5f6165;
`;
