import { Input } from '@successmode-ktp/kui';
import FormWrapper from '../FormWrapper';
import { useEffect, useState } from 'react';

type Props = {
  value?: number;
  onChange?: (v: number) => void;
};

const CouponRule = ({ value, onChange }: Props) => {
  const [min, setMin] = useState(value || 0);

  useEffect(() => {
    onChange && onChange(min);
  }, [min]);

  return (
    <FormWrapper label='사용 조건'>
      <Input
        value={min.toString()}
        size='lg'
        onChange={(e) => setMin(Number(e.target.value))}
        placeholder='최소 구매 금액을 입력해주세요'
        addonAfter={<span>원</span>}
        style={{ fontSize: '14px' }}
      />
    </FormWrapper>
  );
};
export default CouponRule;
