import { StoreCategory } from '@/types/api/coupon.type';

export const couponColumns = [
  { toggle: false, type: 'id', title: 'No.' },
  { toggle: false, type: 'store', title: '가맹점명' },
  { toggle: false, type: 'date', title: '가맹 날짜' },
];

export const COLUMNS_COUPON_STORE = [
  {
    type: 'id',
    title: 'No.',
  },
  {
    type: 'promotion',
    title: '프로모션명',
  },
  {
    type: 'dateRange',
    title: '진행 기간',
  },
  {
    type: 'coupon',
    title: '쿠폰명',
  },
  {
    type: 'discount',
    title: '할인',
  },
  {
    type: 'created',
    title: '발행 개수',
  },
  {
    type: 'downloaded',
    title: '다운로드 수',
  },
  {
    type: 'used',
    title: '사용 개수',
  },
  {
    type: 'detailButton',
    title: '상세보기',
  },
];

export const COLUMNS_COUPON = [
  {
    toggle: false,
    type: 'name',
    title: '쿠폰명',
  },
  {
    toggle: false,
    type: 'discount',
    title: '할인',
  },
  {
    toggle: false,
    type: 'issued',
    title: '발행 개수',
  },
  {
    toggle: false,
    type: 'downloaded',
    title: '다운로드 수',
  },
  {
    toggle: false,
    type: 'used',
    title: '사용 개수',
  },
  {
    toggle: false,
    type: 'downloadList',
    title: '다운로드 처리',
  },
];

export const COLUMNS_COUPON_USED = [
  {
    toggle: false,
    type: 'name',
    title: '쿠폰명',
  },
  {
    toggle: false,
    type: 'usedDate',
    title: '사용일자',
  },
  {
    toggle: false,
    type: 'username',
    title: '고객명',
  },
  {
    toggle: false,
    type: 'price',
    title: '상품금액',
  },
  {
    toggle: false,
    type: 'refund',
    title: '환급액',
  },
  {
    toggle: false,
    type: 'discount',
    title: '할인',
  },
  {
    toggle: false,
    type: 'totalPrice',
    title: '결제금액',
  },
  {
    toggle: false,
    type: 'status',
    title: '상태',
  },
];

export const COLUMNS_SATTLEMENTS = [
  {
    type: 'id',
    title: 'No.',
  },
  {
    type: 'storeName',
    title: '가맹점명',
  },
  {
    type: 'dateRange',
    title: '정산일자',
  },
  {
    type: 'count',
    title: '사용 개수',
  },
  {
    type: 'amount',
    title: '정산 금액',
  },
  // {
  //   type: 'detailButton',
  //   title: '쿠폰 사용내역',
  // },
  // {
  //   type: 'detailButton',
  //   title: '정산',
  // },
];

export const COUPON_STORE_CATEGORY: StoreCategory[] = [
  'PHARMACY',
  'K_POP',
  'BEAUTY',
  'CURATED_STORE',
  'CLOTHES',
  'DESSERT',
  'SHOES',
  'BAGS',
  'SOUVENIR',
  'VAPE_PEN',
];
