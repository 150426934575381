import { Button, Icon, Modal, Pagination, Popover } from '@successmode-ktp/kui';
import styled, { css } from 'styled-components';
import ICON_ALERT from '@/assets/medical/icon_alert.png';

type Props = {
  isOpen: boolean;
  title: string;
  content: string;
  onClose: () => void;
  onSave?: () => void;
  isEditMode?: boolean;
};

function PreviewBannerModal({
  isOpen,
  title,
  content,
  onClose,
  onSave,
  isEditMode = false,
}: Props) {
  return (
    <Popover
      isOpen={isOpen}
      hasBackdrop
      order={1}
      style={{
        backgroundColor: '#F5F6F7',
        borderRadius: '10px',
        padding: '48px 64px',
        height: '95vh',
        overflowY: 'auto',
      }}
    >
      <Modal.Header>
        <Title>안내배너 미리보기</Title>
      </Modal.Header>
      <Body>
        <Header>
          <ContentTitle>
            <img src={ICON_ALERT} alt='' width={24} />
            {title}
          </ContentTitle>
          <CloseButton onClick={() => {}} disabled>
            <Icon.X size={24} fillColor='#CCCCCC' />
          </CloseButton>
        </Header>
        <Content dangerouslySetInnerHTML={{ __html: content }} />
        <NoticeWrapper>
          <NoticeList>
            <NoticeItem isHeader>
              <NoticeIndex>
                <IndexLabel>번호</IndexLabel>
              </NoticeIndex>
              <NoticeContent>제목</NoticeContent>
              <NoticeDate>등록일</NoticeDate>
            </NoticeItem>
            <NoticeItem onClick={() => {}} isActive={false}>
              <NoticeIndex>
                <NewLabel>NEW</NewLabel>
              </NoticeIndex>
              <NoticeContent>영수증웹 QR코드 안내</NoticeContent>
              <NoticeDate>2025-02-07</NoticeDate>
            </NoticeItem>
            <NoticeItem onClick={() => {}} isActive={false}>
              <NoticeIndex>
                <IndexLabel>2</IndexLabel>
              </NoticeIndex>
              <NoticeContent>
                병원 예약시스템을 위한 인터뷰어 모집
              </NoticeContent>
              <NoticeDate>2025-02-04</NoticeDate>
            </NoticeItem>
            <NoticeItem onClick={() => {}} isActive={false}>
              <NoticeIndex>
                <IndexLabel>3</IndexLabel>
              </NoticeIndex>
              <NoticeContent>세무자료 메일 수신기능 안내</NoticeContent>
              <NoticeDate>2025-02-03</NoticeDate>
            </NoticeItem>
          </NoticeList>
          <Pagination
            page={1}
            setPage={() => {}}
            totalPage={3}
            isFirstPage={true}
            isLastPage={false}
          />
        </NoticeWrapper>
      </Body>
      <Modal.Footer footerStyle={{ justifyContent: 'flex-end' }}>
        <Button
          theme='mono'
          onClick={onClose}
          style={{ width: '200px', backgroundColor: '#ddd' }}
        >
          닫기
        </Button>
        {!!onSave && (
          <Modal.Button
            variant='ok'
            onClick={onSave}
            style={{ width: '200px' }}
          >
            {isEditMode ? '수정 완료' : '업로드'}
          </Modal.Button>
        )}
      </Modal.Footer>
    </Popover>
  );
}

const Title = styled.h3`
  color: #111;
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  margin-bottom: 24px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  height: calc(
    95vh - 69px - 48px - 52px - 24px - 48px
  ); // header, padding-top, footer, footer margin, padding-bottom
  overflow-y: scroll;
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 20px;
  width: 90vw;
  max-width: 1104px;
  max-height: 778px;
  height: 100%;
`;

const Content = styled.div`
  height: calc(100% - 219px);
  overflow-y: auto;
  margin-bottom: 8px;
  font-size: 13px;

  * {
    all: revert;

    .ql-size-10 {
      font-size: 10px !important;
    }
    .ql-size-13 {
      font-size: 13px !important;
    }
    .ql-size-16 {
      font-size: 16px !important;
    }
    .ql-size-18 {
      font-size: 18px !important;
    }
    .ql-size-20 {
      font-size: 20px !important;
    }
    .ql-size-24 {
      font-size: 24px !important;
    }
    .ql-size-32 {
      font-size: 32px !important;
    }
    .ql-size-48 {
      font-size: 48px !important;
    }
    .ql-size-64 {
      font-size: 64px !important;
    }
  }

  img {
    width: 100%;
    max-width: 1024px;
  }
`;

const NoticeWrapper = styled.div`
  * {
    cursor: default;
  }
`;

const NoticeList = styled.div`
  border-top: 1px solid #e5e6e8;
  margin-bottom: 16px;
`;

const NoticeIndex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
`;

const NoticeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  width: calc(100% - 310px);
`;

const NoticeDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 100%;
`;

const NewLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  background-color: #246cf6;
  padding: 0 6px;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
`;

const IndexLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3a3b3e;
  font-size: 14px;
  font-weight: 500;
`;

const NoticeItem = styled.button<{ isHeader?: boolean; isActive?: boolean }>`
  width: 100%;
  display: flex;
  color: #3a3b3e;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #e5e6e8;
  height: 44px;

  ${({ isHeader = false }) =>
    isHeader &&
    css`
      color: #777;
      font-size: 14px;
      font-weight: 500;
      background-color: #fafafa;
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      color: #246cf6;
      font-weight: 600;
      background-color: #f4f8ff;

      ${NewLabel} {
        background-color: #246cf6;
        color: #fff
        font-weight: 600;
      }
    `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  width: 90vw;
  max-width: 1024px;
  border-bottom: 1px solid #eeeeee;
`;

const ContentTitle = styled.strong`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  color: var(--Blue-100);
  font-size: 18px;
  font-weight: 700;
`;

const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  cursor: default;
`;

export default PreviewBannerModal;
