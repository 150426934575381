import FlexWrap from '@/components/common/FlexWrap';
import React from 'react';
import { SubTitle, TransferContainer } from './DetailContent';
import { TextField } from '@material-ui/core';
import Typography from '@/components/common/Typography';
import styled, { css } from 'styled-components';

type Props = {
  refundInfo: any;
  isEdit: boolean;
  setData: React.Dispatch<React.SetStateAction<unknown>>;
};

const STATUS_LIST = [
  {
    name: '미완료',
    value: 'PAYMENT_WAIT',
  },
  {
    name: '송금중',
    value: 'PAYMENT_IN_PROGRESS',
  },
  {
    name: '완료',
    value: 'PAYMENT_COMPLETE',
  },
];

function RemittanceStatusRadio({ refundInfo, isEdit, setData }: Props) {
  const onChangeTransfer = (transferStatus: string) => {
    setData((prev: any) => {
      return {
        ...prev,
        detailRefundInfo: {
          ...prev.detailRefundInfo,
          paymentStatus: transferStatus,
        },
      };
    });
  };

  const onChangePaymentDate = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setData((prev: any) => {
      return {
        ...prev,
        detailRefundInfo: {
          ...prev.detailRefundInfo,
          paymentCompleteDate: e.target.value,
        },
      };
    });
  };

  const formatRefundStatus = (status: string) => {
    switch (status) {
      case 'VAN':
        return 'PAYMENT_COMPLETE';
      case 'KIOSK':
        return 'PAYMENT_COMPLETE';
      default:
        return status;
    }
  };

  return (
    <FlexWrap>
      <SubTitle>송금 상태</SubTitle>
      <TransferContainer dir='row'>
        {STATUS_LIST.map((status) => {
          return (
            <FlexWrap>
              <TransferInput
                type='radio'
                checked={
                  formatRefundStatus(refundInfo.paymentStatus) === status.value
                }
                onChange={() => onChangeTransfer(status.value)}
                disabled={!isEdit}
                isRadio
              />
              <label>{status.name}</label>
            </FlexWrap>
          );
        })}
        <Typography
          fontSize='16px'
          lineHeight='150%'
          fontWeight='500'
          color='#A7A7A7'
        >
          (송금일)
        </Typography>
        <TextField
          id='datetime-local'
          onChange={onChangePaymentDate}
          type='datetime-local'
          disabled={!isEdit || refundInfo.paymentStatus !== 'PAYMENT_COMPLETE'}
          value={
            refundInfo.paymentStatus === 'PAYMENT_COMPLETE' ||
            refundInfo.paymentStatus === 'VAN'
              ? refundInfo.paymentCompleteDate
              : ''
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
        {refundInfo.vanRefundId && <VanBadge>VAN 환급완료</VanBadge>}
      </TransferContainer>
    </FlexWrap>
  );
}

export default RemittanceStatusRadio;

export const Wrapper = styled(FlexWrap)`
  position: relative;
  flex: 1;
  align-items: center;
`;

export const TransferInput = styled.input<{ isRadio: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  outline: none;
  padding-left: 21px;
  flex: 1;
  border: none;
  background-color: #ffffff;
  min-height: ${(props) => (props.isRadio ? 'auto' : '56px')};
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;

const VanBadge = styled.div`
  border-radius: 8px;
  background: var(--KTP-Grayscale-Gray_10, #ddd);
  padding: 6px 12px;
  color: var(--KTP-Grayscale-Gray_02, #111);
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.24px;
`;
