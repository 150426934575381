import { Button, Icon, Modal, Popover } from '@successmode-ktp/kui';
import styled from 'styled-components';
import { useMemo, useRef, useState } from 'react';
import Form from './Form';
import { format, isBefore, parseISO } from 'date-fns';
import DeleteConfirmModal from './DeleteConfirmModal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteBanner, editBanner, getBannerDetail } from '@/api/medical';
import Editor from '@/components/medical/banner/Editor';
import PreviewBannerModal from '@/components/medical/banner/PreviewBannerModal';

type Props = {
  isOpen: number;
  onClose: () => void;
};

function DetailBannerModal({ isOpen, onClose }: Props) {
  const queryClient = useQueryClient();
  const quillRef = useRef(null);
  const [isViewMode, setIsViewMode] = useState(true);
  const [state, setState] = useState<any>({
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    starHour: format(new Date(), 'HH'),
    endHour: format(new Date(), 'HH'),
    starMinute: format(new Date(), 'mm'),
    endMinute: format(new Date(), 'mm'),
    originalVisible: false,
    directVisible: false,
    kioskVisible: false,
    isActivated: true,
    title: '',
    content: '',
    isReserved: false,
    noEndDate: false,
  });

  const [isOpenEditConfirm, setIsOpenEditConfirm] = useState(false);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const [isOpenCloseAlert, setIsOpenCloseAlert] = useState(false);

  const { data } = useQuery(
    ['bannerDetail'],
    () => getBannerDetail({ bannerNoticeId: isOpen }),
    {
      onSuccess: (data) => {
        // startDate가 현재 시각보다 적으면 즉시, 많으면 예약
        // endDate가 9999년이면 종료없음
        setState({
          startDate: format(new Date(data.startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(data.endDate), 'yyyy-MM-dd'),
          startHour: format(new Date(data.startDate), 'HH'),
          endHour: format(new Date(data.endDate), 'HH'),
          startMinute: format(new Date(data.startDate), 'mm'),
          endMinute: format(new Date(data.endDate), 'mm'),
          originalVisible: data.originalVisible,
          directVisible: data.directVisible,
          kioskVisible: data.kioskVisible,
          isActivated: data.isActivated,
          title: data.title,
          content: data.content,
          isReserved: !isBefore(parseISO(data.startDate), new Date()),
          noEndDate: format(new Date(data.endDate), 'yyyy') === '9999',
        });
      },
      enabled: isOpen !== -1,
    },
  );

  const setHtmlContent = (value: any) => {
    setState((prev: any) => ({ ...prev, content: value }));
  };

  const onChange = (name: string, value: any) => {
    if (name === 'hospitalType') {
      let newState: any;
      if (value === 'total') {
        newState =
          state.originalVisible && state.directVisible && state.kioskVisible
            ? {
                originalVisible: false,
                directVisible: false,
                kioskVisible: false,
              }
            : {
                originalVisible: true,
                directVisible: true,
                kioskVisible: true,
              };
      } else {
        newState = { [value]: !state[value] };
      }
      setState((prev: any) => ({ ...prev, ...newState }));
    } else {
      setState((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const { mutate: mutateEditBanner } = useMutation(editBanner);
  const onEditBanner = () => {
    const startDateTime = `${state.startDate} ${state.startHour.padStart(
      2,
      '0',
    )}:${state.startMinute.padStart(2, '0')}`;
    const endDateTime = state.noEndDate
      ? null
      : `${state.endDate} ${state.endHour.padStart(
          2,
          '0',
        )}:${state.endMinute.padStart(2, '0')}`;

    mutateEditBanner(
      {
        bannerNoticeId: isOpen,
        params: {
          startDate: startDateTime,
          endDate: endDateTime,
          originalVisible: state.originalVisible,
          directVisible: state.directVisible,
          kioskVisible: state.kioskVisible,
          isActivated: state.isActivated,
          title: state.title,
          content: state.content,
        },
      },
      {
        onSuccess: () => {
          alert('배너 수정에 성공하였습니다.');
          setIsOpenEditConfirm(true);
          onClose();
          queryClient.invalidateQueries('bannerList');
        },
        onError: () => {
          alert('배너 수정에 실패하였습니다.');
        },
      },
    );
  };

  const { mutate: mutateDeleteBanner } = useMutation(deleteBanner);
  const onDeleteBanner = () => {
    mutateDeleteBanner(isOpen, {
      onSuccess: () => {
        alert('배너를 성공적으로 삭제하였습니다.');
        setIsOpenDeleteConfirm(true);
        onClose();
        queryClient.invalidateQueries('bannerList');
      },
      onError: () => {
        alert('배너를 삭제하는 데 실패하였습니다.');
        setIsOpenDeleteConfirm(true);
      },
    });
  };

  const isDisabledUpload = useMemo(() => {
    const isCheckedHospitalType =
      state.originalVisible || state.directVisible || state.kioskVisible;

    const doc = new DOMParser().parseFromString(state.content, 'text/html');
    const content = doc.body;

    const firstCheck =
      isCheckedHospitalType &&
      !!state.title &&
      content.innerHTML.trim() !== '<p><br></p>';

    if (!firstCheck) return true;

    if (state.isReserved) {
      const hasData =
        !!state.startDate && !!state.startHour && !!state.startMinute;
      if (!hasData) return true;

      const startDateTime = `${state.startDate}T${state.startHour?.padStart(
        2,
        '0',
      )}:${state.startMinute?.padStart(2, '0')}:00`;
      const isStartBeforeNow = isBefore(parseISO(startDateTime), new Date());
      if (isStartBeforeNow) return true;
    }

    if (!state.noEndDate) {
      const hasData = !!state.endDate && !!state.endHour && !!state.endMinute;
      if (!hasData) return true;

      const endDateTime = `${state.endDate}T${state.endHour?.padStart(
        2,
        '0',
      )}:${state.endMinute?.padStart(2, '0')}:00`;
      const isEndBeforeNow = isBefore(parseISO(endDateTime), new Date());
      if (isEndBeforeNow) return true;
    }

    return false;
  }, [state]);

  return (
    <>
      <Popover
        isOpen={isOpen !== -1}
        hasBackdrop
        style={{
          padding: '40px',
          borderRadius: '12px',
          maxWidth: '1248px',
          width: '90vw',
          maxHeight: '95dvh',
          overflowY: 'auto',
        }}
      >
        <Header>
          <Title>안내배너</Title>
          <RightSection>
            <Button
              variant={isViewMode ? 'line' : 'solid'}
              style={{ width: '200px' }}
              onClick={() => setIsViewMode((prev) => !prev)}
            >
              {isViewMode ? '수정하기' : '수정완료'}
            </Button>
            <button
              onClick={() =>
                isViewMode ? onClose() : setIsOpenCloseAlert(true)
              }
            >
              <Icon.X size={32} fillColor='#111111' />
            </button>
          </RightSection>
        </Header>
        <Form state={state} onChange={onChange} disabled={isViewMode} />
        <Editor
          htmlContent={state.content}
          setHtmlContent={setHtmlContent}
          isEditMode={!isViewMode}
          quillRef={quillRef}
        />
        <ButtonWrapper>
          <Button
            theme='mono'
            style={{ width: '200px' }}
            onClick={() => setIsOpenDeleteConfirm(true)}
          >
            삭제
          </Button>
          <ButtonRightWrapper>
            <Button
              variant='line'
              style={{ width: '200px' }}
              onClick={() => setIsOpenPreviewModal(true)}
            >
              미리보기
            </Button>
            <Button
              theme='mono'
              style={{ width: '200px' }}
              onClick={() =>
                isViewMode ? onClose() : setIsOpenCloseAlert(true)
              }
            >
              닫기
            </Button>
          </ButtonRightWrapper>
        </ButtonWrapper>
      </Popover>
      {isOpenDeleteConfirm && (
        <DeleteConfirmModal
          isOpen={isOpenDeleteConfirm}
          onCancel={() => setIsOpenDeleteConfirm(false)}
          onDelete={onDeleteBanner}
        />
      )}
      {isOpenEditConfirm && (
        <Modal.Confirm
          isOpen={isOpenEditConfirm}
          onClick={() => setIsOpenEditConfirm(false)}
          title='안내 배너 수정완료'
          message='안내 배너가 수정되었습니다.'
        />
      )}
      {isOpenPreviewModal && (
        <PreviewBannerModal
          isOpen={isOpenPreviewModal}
          title={state.title}
          content={state.content}
          onClose={() => setIsOpenPreviewModal(false)}
          onSave={
            isViewMode
              ? undefined
              : () => {
                  setIsOpenPreviewModal(false);
                  onEditBanner();
                }
          }
          isEditMode
        />
      )}

      <Modal isOpen={isOpenCloseAlert} hasBackdrop order={3}>
        <Modal.Header>수정 내용이 저장되지 않았어요</Modal.Header>
        <CloseAlertContent>수정 전 내용이 그대로 노출됩니다</CloseAlertContent>
        <Modal.Footer>
          <Button theme='mono' onClick={() => setIsOpenCloseAlert(false)}>
            취소
          </Button>
          <Modal.Button
            variant='ok'
            onClick={() => {
              setIsOpenCloseAlert(false);
              onClose();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.h3`
  color: #212121;
  font-size: 30px;
  font-weight: 700;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
`;

const ButtonWrapper = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 24px;
`;

const ButtonRightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

const CloseAlertContent = styled.p`
  color: #5f6165;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  width: 336px;
  margin-top: 12px;
`;

export default DetailBannerModal;
