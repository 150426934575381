import styled from 'styled-components';
import FormWrapper from '../FormWrapper';
import Icon from '@/components/common/Icon';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import ICON_RADIO_ON from '@/assets/common/radioActive.png';
import ICON_RADIO_OFF from '@/assets/common/radio.png';
import { DatePicker } from '@successmode-ktp/kui';

type Period = {
  startDate: string;
  endDate: string;
};

type Props = {
  value?: Period;
  onChange?: (v: Period) => void;
};

const PromotionPeriod = ({ value, onChange }: Props) => {
  const today = format(new Date(), 'yyyy-MM-dd');
  const initialStartDate = value?.startDate ? value.startDate : today;
  const initialEndDate = value?.startDate ? value.startDate : today;
  const [isSelectedDate, setIsSelectedDate] = useState(true);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  useEffect(() => {
    const date = isSelectedDate
      ? { startDate, endDate }
      : { startDate, endDate: '' };
    onChange && onChange(date);
  }, [startDate, endDate, isSelectedDate]);

  return (
    <>
      <FormWrapper label='프로모션 기간 선택' required>
        <InputList>
          <Radio
            type='button'
            isSelected={!isSelectedDate}
            onClick={() => setIsSelectedDate(false)}
          >
            <InputWrapper>
              <RadioLabel htmlFor='no-date'>
                기간 없음
                <Icon
                  imgUrl={isSelectedDate ? ICON_RADIO_OFF : ICON_RADIO_ON}
                  width='20px'
                  height='20px'
                />
              </RadioLabel>
              <RadioInput type='radio' id='no-date' />
            </InputWrapper>
          </Radio>
          <Radio
            type='button'
            isSelected={isSelectedDate}
            onClick={() => setIsSelectedDate(true)}
          >
            <InputWrapper>
              <RadioLabel htmlFor='no-date'>
                기간 설정
                <Icon
                  imgUrl={isSelectedDate ? ICON_RADIO_ON : ICON_RADIO_OFF}
                  width='20px'
                  height='20px'
                />
              </RadioLabel>
              <RadioInput type='radio' id='no-date' />
            </InputWrapper>
          </Radio>
        </InputList>
      </FormWrapper>
      {isSelectedDate && (
        <FormWrapper label='기간 설정' required>
          <InputList>
            <DatePicker
              size='lg'
              value={startDate}
              onChange={(v) => setStartDate(v.toString())}
              max={endDate}
            />
            <DatePicker
              size='lg'
              value={endDate}
              onChange={(v) => setEndDate(v.toString())}
              min={today}
            />
          </InputList>
        </FormWrapper>
      )}
    </>
  );
};
export default PromotionPeriod;

const InputList = styled.div`
  display: flex;
  gap: 12px;

  & > div {
    width: 100%;
  }

  & input {
    font-size: 14px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid var(--Gray-09);
`;

const RadioLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
`;

const Radio = styled.button<{ isSelected: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;

  ${({ isSelected }) =>
    isSelected
      ? `
    ${RadioLabel} {
      color: #246CF6;
    }

    ${InputWrapper} {
      border-color: #246CF6;
    }
  `
      : `
      ${RadioLabel} {
      color: #cbccce;
    }
      `}
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
`;
