import api from '..';
import store from '@/store';
import {
  CreateCouponTemplateRequest,
  CreatePromotionRequest,
  DownloadCouponRequest,
  GetAllPromoteStoreResponse,
  GetCouponStoresQuery,
  GetPromotionDetailQuery,
  GetPromotionDetailResponse,
  GetPromotionResponse,
  GetSettlementDetailQuery,
  GetSettlementsQuery,
  ImageUploadResponse,
  PromoteStore,
  SearchStoreResponse,
  SettlementCompleteRequest,
  SettlementDetailResponse,
  SettlementResponse,
} from '@/types/api/coupon.type';

const getTargetServerURL = () => {
  return store.getState().serverState === 'prod'
    ? 'https://coupon.ktaxpay.com'
    : 'https://coupontest.ktaxpay.com';
};

// --------------------------------------------------
// COUPON
// --------------------------------------------------
export const deleteCoupon = async (templateId: string) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/coupon/${templateId}`,
  );
  return res.status;
};

export const createCoupon = async (payload: CreateCouponTemplateRequest) => {
  const res = await api.postAxios<null, CreateCouponTemplateRequest, null>(
    `${getTargetServerURL()}/coupon/template`,
    payload,
  );
  return res.data;
};

export const downloadCoupon = async (payload: DownloadCouponRequest) => {
  const res = await api.postAxios<null, DownloadCouponRequest, null>(
    `${getTargetServerURL()}/coupon/download/admin`,
    payload,
  );
  return res.data;
};

// --------------------------------------------------
// STORE
// --------------------------------------------------
export const createCouponStore = async (payload: PromoteStore) => {
  const res = await api.postAxios<null, PromoteStore, null>(
    `${getTargetServerURL()}/store`,
    payload,
  );
  return res.data;
};

export const putCouponStore = async (payload: PromoteStore) => {
  const res = await api.putAxios<null, PromoteStore, null>(
    `${getTargetServerURL()}/store`,
    payload,
  );
  return res.data;
};

export const getCouponStore = async (query: GetCouponStoresQuery) => {
  const res = await api.getAxios<
    GetAllPromoteStoreResponse,
    GetCouponStoresQuery
  >(`${getTargetServerURL()}/store/all`, query);
  return res.data;
};

export const getCouponStoreDetail = async (storeId: string) => {
  const res = await api.getAxios<PromoteStore, { storeId: string }>(
    `${getTargetServerURL()}/store/detail`,
    { storeId },
  );
  return res.data;
};

export const uploadStoreImg = async (formData: { files?: Array<Blob> }) => {
  const res = await api.postAxios<
    ImageUploadResponse,
    { files?: Array<Blob> },
    null
  >(`${getTargetServerURL()}/store/upload`, formData);
  return res.data;
};

export const searchStore = async (name: string) => {
  const res = await api.postAxios<SearchStoreResponse, { name: string }, null>(
    `${getTargetServerURL()}/store/search`,
    { name },
  );
  return res.data;
};

// --------------------------------------------------
// PROMOTION
// --------------------------------------------------
export const postPromotion = async (payload: CreatePromotionRequest) => {
  const res = await api.postAxios<null, CreatePromotionRequest, null>(
    `${getTargetServerURL()}/store/promotion`,
    payload,
  );
  return res.data;
};

export const getPromotion = async (storeId: string, promotionId?: string) => {
  const res = await api.getAxios<
    GetPromotionResponse,
    { storeId: string; promotionId?: string }
  >(`${getTargetServerURL()}/store/promotion`, { storeId, promotionId });
  return res.data;
};

export const deletePromotion = async (promotionId: string) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/store/promotion?promotionId=${promotionId}`,
  );
  return res.status;
};

export const getPromotionDetail = async (payload: GetPromotionDetailQuery) => {
  const res = await api.getAxios<
    GetPromotionDetailResponse,
    GetPromotionDetailQuery
  >(`${getTargetServerURL()}/store/promotion/detail`, payload);
  return res.data;
};

// --------------------------------------------------
// SETTLEMENTS
// --------------------------------------------------
export const getSettlements = async (payload: GetSettlementsQuery) => {
  const res = await api.getAxios<SettlementResponse, GetSettlementsQuery>(
    `${getTargetServerURL()}/settlements/result/all`,
    payload,
  );
  return res.data;
};

export const getSettlementsDetail = async (
  payload: GetSettlementDetailQuery,
) => {
  const res = await api.getAxios<
    SettlementDetailResponse,
    GetSettlementDetailQuery
  >(`${getTargetServerURL()}/settlements/detail`, payload);
  return res.data;
};

export const getSettlementsSchedule = async () => {
  const res = await api.getAxios<SettlementResponse, number>(
    `${getTargetServerURL()}/settlements`,
  );
  return res.data;
};

export const completeSettlements = async (settlementId: string) => {
  const res = await api.postAxios<null, SettlementCompleteRequest, null>(
    `${getTargetServerURL()}/settlements`,
    { settlementId },
  );
  return res.data;
};
