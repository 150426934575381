import styled from 'styled-components';
import FormWrapper from '../FormWrapper';
import { useEffect, useState } from 'react';
import { StoreCategory as StoreCategoryType } from '@/types/api/coupon.type';
import { COUPON_STORE_CATEGORY } from '@/constants/coupon';

type Props = {
  value?: StoreCategoryType;
  onChange?: (value: StoreCategoryType) => void;
};

const StoreCategory = ({ value, onChange }: Props) => {
  const [category, setCategory] = useState<StoreCategoryType>(
    value || 'PHARMACY',
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onChange && onChange(category);
  }, [category]);

  useEffect(() => {
    if (value) {
      setCategory(value);
    }
  }, [value]);

  const selectCategory = (category: StoreCategoryType) => {
    setCategory(category);
    setIsOpen(false);
  };

  return (
    <FormWrapper label='카테고리' required>
      <Wrapper>
        <Category type='button' onClick={() => setIsOpen(!isOpen)}>
          {category}
        </Category>
        <ListWrapper isOpen={isOpen}>
          {COUPON_STORE_CATEGORY.map((storeCategory, index) => {
            return (
              <ItemWrapper key={index}>
                <Item
                  type='button'
                  onClick={() => selectCategory(storeCategory)}
                >
                  {storeCategory}
                </Item>
              </ItemWrapper>
            );
          })}
        </ListWrapper>
      </Wrapper>
    </FormWrapper>
  );
};
export default StoreCategory;

const Wrapper = styled.div`
  position: relative;
`;

const Category = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  padding: 16px;
  height: 52px;
  border-radius: 8px;
  border: 1px solid var(--Gray-09);
  font-size: 14px;
`;

const ListWrapper = styled.ul<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 40px;
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--Gray-09);
  z-index: 10;
`;

const ItemWrapper = styled.li`
  width: 100%;
`;

const Item = styled.button`
  width: 100%;
  padding: 10px 0;
  &:hover {
    background-color: var(--Gray-09);
  }
`;
