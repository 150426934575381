import ConfirmModal from './Confirm.modal';

type Props = { isOpen: boolean; onClose: () => void };

function FinishModal({ isOpen, onClose }: Props) {
  return (
    <ConfirmModal
      isOpen={isOpen}
      title='정산 완료처리 하시겠습니까?'
      message='정산 완료처리됩니다.'
      okLabel='정산완료'
      onClose={onClose}
      onOK={onClose}
    />
  );
}

export default FinishModal;
