import { Input } from '@successmode-ktp/kui';
import FormWrapper from '../FormWrapper';
import { GrayButton } from './StoreAdd.modal';
import { useEffect, useState } from 'react';
import { RequestLang } from '@/types/api/coupon.type';

type Props = {
  value?: RequestLang;
  onChange?: (v: RequestLang) => void;
};

const StoreName = ({ value, onChange }: Props) => {
  const [KO, setKO] = useState(value?.KO || '');
  const [EN, setEN] = useState(value?.EN || '');
  const [ZH, setZH] = useState(value?.ZH || '');
  const [JA, setJA] = useState(value?.JA || '');

  useEffect(() => {
    onChange && onChange({ KO, EN, ZH, JA });
  }, [KO, EN, ZH, JA]);

  useEffect(() => {
    if (value) {
      setKO(value.KO);
      setEN(value.EN);
      setZH(value.ZH);
      setJA(value.JA);
    }
  }, [value]);

  return (
    <FormWrapper label='상호명' required>
      <Input
        value={KO}
        size='lg'
        onChange={(e) => setKO(e.target.value)}
        placeholder='상호명을 검색해주세요'
        // addonAfter={
        //   <GrayButton type='button' onClick={() => console.log(KO)}>
        //     검색
        //   </GrayButton>
        // }
        style={{ fontSize: '14px' }}
      />
      <Input
        value={EN}
        size='lg'
        onChange={(e) => setEN(e.target.value)}
        placeholder='영어 상호명을 입력해주세요'
        style={{ fontSize: '14px' }}
      />
      <Input
        value={ZH}
        size='lg'
        onChange={(e) => setZH(e.target.value)}
        placeholder='중국어 상호명을 입력해주세요'
        style={{ fontSize: '14px' }}
      />
      <Input
        value={JA}
        size='lg'
        onChange={(e) => setJA(e.target.value)}
        placeholder='일본어 상호명을 입력해주세요'
        style={{ fontSize: '14px' }}
      />
    </FormWrapper>
  );
};
export default StoreName;
