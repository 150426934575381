export const getEndPoint = (id?: number) => {
  const isStore = window.location.pathname.split('/').includes('ktp');

  if (isStore) {
    if (!id) {
      return 'default';
    }
    return 'store';
  }
  return 'medical';
};
