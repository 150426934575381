import { deleteAd, deleteDefaultAd } from '@/api/ktp/ad.api';
import { deleteMedicalAd } from '@/api/medical/ad.api';
import { getEndPoint } from '@/utils/api.util';
import { useMutation, useQueryClient } from 'react-query';

const useDeleteAd = (id?: number) => {
  const queryClient = useQueryClient();
  const endPoint = getEndPoint(id);

  const getTargetApi = (id: number) => {
    switch (endPoint) {
      case 'medical':
        return deleteMedicalAd(id);
      case 'store':
        return deleteAd(id);
      default:
        return deleteDefaultAd(id);
    }
  };

  const {
    mutate: handleDelete,
    isLoading,
    isSuccess,
  } = useMutation((id: number) => getTargetApi(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('getAdList');
    },
    onError: (error: any) => {
      console.error(error);
    },
  });

  return { handleDelete, isLoading, isSuccess };
};
export default useDeleteAd;
