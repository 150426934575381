import React from 'react';
import styled from 'styled-components';

import checkIcon from '@/assets/common/checkbox_v2_fill.png';
import checkGrayIcon from '@/assets/common/checkbox_v2.png';
interface IProps {
  id?: string;
  label?: string;
  value: string;
  disabled?: boolean;
  isCheck: boolean;
  onClick: (value: string) => void;
  size?: 'md' | 'lg';
  wrapperStyle?: React.CSSProperties;
  highlight?: boolean;
}

function CheckBox({
  id,
  label,
  value,
  disabled,
  isCheck = false,
  onClick,
  size = 'md',
  wrapperStyle,
  highlight = false,
}: IProps) {
  return (
    <CheckBoxWrapper
      id={id}
      onClick={() => disabled || onClick(value)}
      style={wrapperStyle}
    >
      <Checkbox isCheck={isCheck} disabled={disabled}>
        <CheckBoxImage src={isCheck ? checkIcon : checkGrayIcon} />
      </Checkbox>
      {label && (
        <Label htmlFor={id} size={size} highlight={highlight}>
          {label}
        </Label>
      )}
    </CheckBoxWrapper>
  );
}

const CheckBoxWrapper = styled.div<{ style?: React.CSSProperties }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  ${({ style }) => style && { ...style }};
`;

const Checkbox = styled.button<{ isCheck: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 4px;
`;

const CheckBoxImage = styled.img`
  width: 24px;
  height: 24px;
  text-align: center;
`;

const Label = styled.label<{
  size?: 'md' | 'lg';
  highlight?: boolean;
}>`
  font-size: ${({ size = 'md' }) => (size === 'md' ? '14px' : '16px')};
  font-weight: ${({ highlight }) => (highlight ? '700' : '500')};
  color: ${({ highlight }) => (highlight ? '#246CF6' : '#5f6165')};
  line-height: 19px;
`;

export default CheckBox;
