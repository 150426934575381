import { Icon, Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';
import Table from './Table';
import { NON_DEPARTURE_DETAIL_HEADER } from '@/constants/medical';
import ChangeStatusSection from './ChangeStatusSection';
import SendMailSection from './SendMailSection';
import { useMemo, useState } from 'react';
import { comma } from '@/utils/ktp';
import { useQuery } from 'react-query';
import { getDepartureStatusDetail } from '@/api/medical';

type Props = {
  isOpen: boolean;
  openId: number;
  onClose: () => void;
  year: string;
  month: string;
  totalCount: number;
  totalReturned: number;
  title: string;
};

const DetailModal = ({
  isOpen,
  openId,
  onClose,
  year,
  month,
  totalCount,
  totalReturned,
  title,
}: Props) => {
  const [selectedRow, setSelectedRow] = useState<number[]>([]);

  const { data } = useQuery(
    ['nonDepartureDetail', year, month, openId],
    () => getDepartureStatusDetail({ year, month, hospitalId: openId }),
    {
      enabled: openId > 0,
    },
  );

  const onToggleRow = ({ id, title }: { id: number; title: string }) => {
    setSelectedRow((prev) =>
      prev.includes(id)
        ? prev.filter((item: number) => item !== id)
        : [...prev, id],
    );
  };

  const onSelectAllRow = () => {
    if (selectedRow.length === 0) {
      const ids = data?.details.map((item) => item.refundId) || [];
      setSelectedRow(ids);
    } else {
      setSelectedRow([]);
    }
  };

  const onCloseModal = () => {
    setSelectedRow([]);
    onClose();
  };

  const totalRefunded = useMemo(() => {
    const sum = data?.details
      .filter((item) => selectedRow.includes(item.refundId))
      .reduce((acc, cur) => acc + cur.returned, 0);
    return sum || 0;
  }, [data, selectedRow]);

  return (
    <Modal isOpen={isOpen} hasBackdrop onClickBackdrop={onClose}>
      <Modal.Header>
        <Title>{title}</Title>
        <button type='button' onClick={onCloseModal}>
          <Icon.X />
        </button>
      </Modal.Header>
      <DataList>
        <DataWrapper>
          <DataTitle>
            <Icon.My fillColor={'#5089F8'} />
            외국인 환자 미출국 건
          </DataTitle>
          <Data>
            <DataCount>{comma(totalCount)}</DataCount> 건
          </Data>
        </DataWrapper>
        <DevideLine />
        <DataWrapper>
          <DataTitle>
            <Icon.Refund fillColor={'#5089F8'} />
            환불 금액
          </DataTitle>
          <Data>
            <DataCount>{comma(totalReturned)}</DataCount> 원
          </Data>
        </DataWrapper>
      </DataList>
      <Table
        column={NON_DEPARTURE_DETAIL_HEADER}
        row={data?.details || []}
        checkedRow={selectedRow}
        onCheckAllRow={onSelectAllRow}
        onClick={onToggleRow}
      />
      <Modal.Footer size='md'>
        <ButtonWrapper>
          <ChangeStatusSection
            ids={selectedRow}
            refunded={totalRefunded}
            onUnCheck={() => setSelectedRow([])}
          >
            상태 변경
          </ChangeStatusSection>
          {data?.details[0].email && (
            <SendMailSection
              ids={selectedRow}
              refunded={totalRefunded}
              email={data.details[0].email}
              hospitalName={data.details[0].hospitalName}
              onUnCheck={() => setSelectedRow([])}
            >
              메일 발송
            </SendMailSection>
          )}
        </ButtonWrapper>
      </Modal.Footer>
    </Modal>
  );
};
export default DetailModal;

const Title = styled.h3`
  color: var(--Mono-80, #3a3b3e);
  font-size: 28px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.56px;
`;

const DataList = styled.dl`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  border-radius: 12px;
  border: 1px solid var(--Mono-10, #e5e6e8);
  padding: 24px 16px;
  width: 90vw;
  max-width: 1400px;
`;

const DevideLine = styled.div`
  width: 1px;
  height: 70px;
  background-color: var(--Mono-10, #e5e6e8);
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1;
`;

const DataTitle = styled.dt`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Mono-80, #3a3b3e);
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  word-break: keep-all;
`;

const Data = styled.dd`
  color: var(--Mono-65, #5f6165);
  font-size: 21px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.42px;
`;

const DataCount = styled.span`
  color: var(--Blue-100, #246cf6);
  font-size: 21px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.42px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 400px;
  margin-left: auto;
`;
