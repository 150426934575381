import { Fragment, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMutation, useQueryClient } from 'react-query';

import FlexWrap from '@/components/common/FlexWrap';
import {
  formatAccountEngInfo,
  formatAccountInfo,
  formatMedicalRefundInfo,
  formatMedicalServiceAccount,
  formatMedicalServiceInfo,
  formatRefundAddressInfo,
  formatRefundPaymentInfo,
  GENDER_TYPES,
} from '@/constants/medical';
import {
  comma,
  formatPrice,
  getRefundAmount,
  removeCommaNumber,
} from '@/utils/ktp';
import { getServiceType } from '@/utils/medical';
import {
  downloadSupplyDocument,
  forceModifyRefund,
  sendReuploadMail,
  updateConfirmDate,
  updateRefundDetail,
} from '@/api/medical';
import SupplyStatementFile from './SupplyStatementFile';
import {
  chunkedAccountNumber,
  convertComponentToImage,
  copyToClipboard,
} from '@/utils/common';
import Icon from '@/components/common/Icon';
import Typography from '@/components/common/Typography';
import RemittanceStatusRadio from './RemittanceStatusRadio';
import MailingStatusRadio from './MailingStatusRadio';
import PaymentWayRadio from './PaymentWayRadio';
import ModifyNeedCheckbox from '@/components/common/Remittance/ModifyNeedCheckbox';
import TicketModal from './TicketModal';
import SuccessConfirmModal from './SuccessConfirmModal';
import RequestTicketModal from './RequestTicketModal';

import downloadIcon from '@assets/common/download.png';
import checkIconV2 from '@/assets/common/checkbox_v2.png';
import checkedIconV2 from '@/assets/common/checkbox_v2_fill.png';
import DepartureIcon from '@/assets/common/icon_departure.png';
import BadgeSuccess from '@/assets/common/badge_success.png';

import useModal from '@/hooks/common/useModal';
import { useRefundDelete } from '@/hooks/medical/useRefundDelete';
import DeleteModal from '@/components/common/Modal/DeleteModal';
import { Button } from '@successmode-ktp/kui';
import { format } from 'date-fns';
import { TextField } from '@material-ui/core';
import DepartureCheckSection from './DepartureCheckSection';

function DetailModalContent({ data, setData, refundIndex, onClose }) {
  const copySuccessRef = useRef(null);
  const { refundInfo, serviceInfo, customerInfo } = data;
  const [isOpenTicketModal, setIsOpenTicketModal] = useState(false);
  const [isOpenSuccessConfirmModal, setIsOpenSuccessConfirmModal] =
    useState(false);
  const [isOpenRequestTicketModal, setIsOpenRequestTicketModal] =
    useState(false);
  const [isOpenRequestErrorModal, setIsOpenRequestErrorModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isForcedEdit, setIsForcedEdit] = useState(false);
  const [isModifyNeeded, setIsModifyNeeded] = useState(
    data?.isModifyNeeded || false,
  );
  const [selectedAccountInfo, setSelectedAccountInfo] = useState(
    data?.selectedAccountInfo || [],
  );
  const {
    isOpenModal: isOpenDeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const { mutate: deleteMutation } = useRefundDelete();

  const checkPaymentInfo = customerInfo;
  const isDirectPaymentType = customerInfo.paymentType === 'DIRECT';
  const fileRef = useRef(null);

  const formattedRefundInfo = formatMedicalRefundInfo(
    refundInfo,
    serviceInfo,
    customerInfo,
  ); // 기본정보
  const formattedPaymentInfo = formatRefundPaymentInfo(
    customerInfo,
    refundInfo.nation,
  ); // 고객정보1
  const formattedAddressInfo = formatRefundAddressInfo(customerInfo); // 고객정보2
  const formattedAccountInfo = formatAccountInfo(customerInfo); // 고객정보3
  const formattedAccountEngInfo = formatAccountEngInfo(customerInfo); // 고객정보4
  const formattedServiceAccount = formatMedicalServiceAccount(serviceInfo); // 시술정보1
  const formattedServiceInfo = formatMedicalServiceInfo(serviceInfo); // 시술정보2

  const updateMutation = useMutation(
    ['updateMedicalInfo'],
    (payload) => updateRefundDetail(refundIndex, payload),
    {
      retry: false,
      onSuccess: () => {
        setIsEdit(false);
        alert('한급현황 수정이 완료되었습니다.');
      },
    },
  );

  const { mutate: mutateUpdateConfirmDate } = useMutation(updateConfirmDate);

  const { mutate: mutateSendReuploadMail } = useMutation(sendReuploadMail);

  // 의료공급확인서 다운
  const { data: downloadData, mutate: downloadMutation } = useMutation(
    ['downloadSupplyDocument'],
    (payload) => downloadSupplyDocument(payload),
    {
      retry: false,
      onSuccess: () => {
        setTimeout(() => {
          uploadExcelImage();
        }, 500);
      },
    },
  );

  const uploadExcelImage = async () => {
    const blob = await convertComponentToImage(fileRef.current);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `의료공급확인서_${refundIndex}`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const onClickEdit = () => {
    if (isEdit) {
      if (customerInfo.accountInfo?.nation !== undefined) {
        customerInfo.accountInfo.nation = refundInfo.nation;
      }
      const payload = {
        transferDate: refundInfo.paymentCompleteDate,
        memo: refundInfo.memo,
        mailSendCount: refundInfo.mailSendCount,
        mailSendDate: refundInfo.lastSentDate,
        paymentStatus: refundInfo.paymentStatus,
        needFullPayment: refundInfo.needFullPayment,
        accountInfo: customerInfo.accountInfo
          ? JSON.stringify(customerInfo.accountInfo)
          : null,
        isModifyNeeded,
        selectedAccountInfo,
      };
      updateMutation.mutate(payload);
    } else {
      setIsEdit(true);
    }
  };

  const onChangeMemo = (e) => {
    setData((prev) => {
      return {
        ...prev,
        refundInfo: {
          ...prev.refundInfo,
          memo: e.target.value,
        },
      };
    });
  };

  const onClickDownload = () => {
    const payload = { refundIndex };
    downloadMutation(payload);
  };

  const onChange = (e) => {
    const name = e.target.name;
    setData((prev) => {
      return {
        ...prev,
        customerInfo: {
          ...prev.customerInfo,
          accountInfo: {
            ...prev.customerInfo.accountInfo,
            [name]: e.target.value,
          },
        },
      };
    });
  };

  const onForceChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const formattedValue =
      name === 'gender'
        ? value
        : name === 'name'
        ? value.toUpperCase()
        : name === 'totalAmount'
        ? formatPrice(value)
        : value;

    setData((prev) => {
      return {
        ...prev,
        refundInfo: {
          ...prev.refundInfo,
          [name]: formattedValue,
        },
      };
    });
  };

  const onForceChangeServiceInfo = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const formattedValue = name === 'supplyDate' ? value : formatPrice(value);

    const total =
      removeCommaNumber(serviceInfo.totalPrice) -
      (customerInfo.paymentType === 'ORIGINAL'
        ? removeCommaNumber(serviceInfo.refund)
        : 0);

    if (Number(value?.replace(/,|원/g, '')) > total) {
      return;
    }

    setData((prev) => {
      if (name === 'card') {
        const changedCash = total - removeCommaNumber(formattedValue);
        return {
          ...prev,
          serviceInfo: {
            ...prev.serviceInfo,
            [name]: formattedValue,
            cash: comma(changedCash),
          },
        };
      }

      return {
        ...prev,
        serviceInfo: {
          ...prev.serviceInfo,
          [name]: formattedValue,
        },
      };
    });
  };

  const onForceChangePrice = (e, idx) => {
    const value = e.target.value;
    const formattedValue = formatPrice(value);

    setData((prev) => {
      const updatedDetails = [...prev.serviceInfo.details];
      updatedDetails[idx] = {
        ...updatedDetails[idx],
        price: formattedValue,
      };

      const changedTotalPrice = updatedDetails.reduce(
        (acc, cur) => acc + removeCommaNumber(cur.price),
        0,
      );

      const changedRefund = getRefundAmount(changedTotalPrice);

      const total =
        changedTotalPrice -
        (customerInfo.paymentType === 'ORIGINAL'
          ? getRefundAmount(changedTotalPrice)
          : 0);

      const changedCard =
        total > removeCommaNumber(prev.serviceInfo.cash)
          ? total - removeCommaNumber(prev.serviceInfo.cash)
          : 0;

      const changedCash =
        total > removeCommaNumber(prev.serviceInfo.cash)
          ? prev.serviceInfo.cash
          : total === 0
          ? 0
          : prev.serviceInfo.card;

      return {
        ...prev,
        serviceInfo: {
          ...prev.serviceInfo,
          details: updatedDetails,
          totalPrice: comma(changedTotalPrice),
          refund: comma(changedRefund.toString()),
          card: comma(changedCard),
          cash: comma(changedCash),
        },
      };
    });
  };

  const { mutate: mutateForcedEdit } = useMutation(forceModifyRefund);

  const onForcedEdit = () => {
    const payload = {
      refundId: refundIndex,
      paymentType: customerInfo.paymentType,
      touristName: refundInfo.name,
      createdDate: format(
        new Date(refundInfo.createdDate),
        'yyyy-MM-dd HH:mm:ss',
      ),
      supplyDate: serviceInfo.supplyDate,
      totalPrice: removeCommaNumber(serviceInfo.totalPrice),
      card: removeCommaNumber(serviceInfo.card),
      cash: removeCommaNumber(serviceInfo.cash),
      totalRefund: removeCommaNumber(serviceInfo.refund),
      orders: serviceInfo.details.map((detail) => {
        return {
          ...detail,
          price: removeCommaNumber(detail.price),
        };
      }),
      gender,
    };

    mutateForcedEdit(payload, {
      onSuccess: () => {
        alert('정보 강제 수정하였습니다.');
        setIsForcedEdit(false);
      },
      onError: () => alert('정보 강제 수정에 실패하였습니다.'),
    });
  };

  const selectModifyNeed = () => {
    if (!isEdit) return;
    if (isModifyNeeded) {
      setSelectedAccountInfo([]);
    }
    setIsModifyNeeded(!isModifyNeeded);
  };

  const selectCell = (item) => {
    if (isEdit) {
      if (selectedAccountInfo.includes(item?.option?.name)) {
        const filteredCell = selectedAccountInfo.filter(
          (cell) => cell !== item?.option?.name,
        );
        setSelectedAccountInfo(filteredCell);
      } else {
        setSelectedAccountInfo((prev) => [...prev, item?.option?.name]);
      }
    }
  };

  const queryClient = useQueryClient();

  const onConfirm = () => {
    mutateUpdateConfirmDate(
      { refundId: refundIndex },
      {
        onSuccess: () => {
          setIsOpenSuccessConfirmModal(true);
          setIsOpenTicketModal(false);
          queryClient.invalidateQueries({
            queryKey: 'hospitalRefundDetail',
          });
        },
      },
    );
  };

  const onSendReuploadMail = () => {
    mutateSendReuploadMail(
      {
        refundId: refundIndex,
        redirectUrl: 'http://192.168.0.116:3001/',
      },
      {
        onSuccess: () => {
          setIsOpenRequestTicketModal(true);
          queryClient.invalidateQueries({
            queryKey: 'hospitalRefundDetail',
          });
        },
        onError: () => {
          setIsOpenRequestErrorModal(true);
        },
      },
    );
  };

  const handleRefundDelete = () => {
    if (refundIndex) {
      deleteMutation(refundIndex, {
        onSuccess: () => {
          closeDeleteModal();
          onClose();
        },
      });
    }
  };

  const onCloseTicketModal = () => {
    setIsOpenTicketModal(false);
    queryClient.invalidateQueries({
      queryKey: 'hospitalRefundDetail',
    });
  };

  const onClickCopy = (value) => {
    copyToClipboard(value);
    copySuccessRef.current.classList.add('open');

    setTimeout(() => {
      copySuccessRef.current.classList.remove('open');
    }, 2000);
  };

  const isCHUNKAccount = (name) => {
    return (
      name === 'bankAccount' &&
      (refundInfo.nation === 'CHN' || refundInfo.nation === 'KOR')
    );
  };

  const [gender, setGender] = useState(customerInfo.gender);

  return (
    <>
      <StyledContainer>
        <FlexWrap
          justifyContent='space-between'
          alignItems='center'
          margin='0 0 32px 0'
        >
          <Title>환급 현황 상세보기</Title>
          <FlexWrap gap='16px'>
            <CloseBtn onClick={onClose}>닫기</CloseBtn>
            <EditButton isEdit={isEdit} onClick={onClickEdit}>
              {isEdit ? '수정완료' : '수정하기'}
            </EditButton>
          </FlexWrap>
        </FlexWrap>
        {/* 송금상태, 환급방식, 송금일자 */}
        <FlexWrap margin='0 0 36px 0'>
          <TransferStatus
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <RemittanceStatusRadio
              refundInfo={refundInfo}
              isEdit={isEdit}
              setData={setData}
            />
            <PaymentWayRadio
              customerInfo={customerInfo}
              isEdit={isForcedEdit}
              setData={setData}
            />
          </TransferStatus>
          <MailingStatusRadio
            refundInfo={refundInfo}
            isEdit={isEdit}
            setData={setData}
          />
        </FlexWrap>
        {data?.isModifyPossible && (
          <ModifyNeedCheckbox
            isModifyNeeded={isModifyNeeded}
            onClick={selectModifyNeed}
          />
        )}
        <MemoInput
          placeholder='메모를 입력하세요. (최대 1000자)'
          disabled={!isEdit}
          onChange={onChangeMemo}
          value={refundInfo.memo}
        />
        {/* 기본정보 */}
        <FlexWrap
          alignItems='center'
          justifyContent='space-between'
          margin='0 0 24px 0'
        >
          <Typography fontSize='18px' lineHeight='150%' fontWeight='700'>
            No.{refundIndex}
          </Typography>
          <RightSection>
            <Button
              size='sm'
              style={{
                width: '204px',
                backgroundColor: isForcedEdit ? '#E5E6E8' : '#3A3B3E',
                color: isForcedEdit ? '#3A3B3E' : '#FFFFFF',
              }}
              onClick={
                isForcedEdit ? onForcedEdit : () => setIsForcedEdit(true)
              }
            >
              {isForcedEdit ? '수정 완료' : '정보 강제 수정'}
            </Button>
            <Download onClick={onClickDownload}>
              <Icon imgUrl={downloadIcon} widht='16px' height='16px' />
              <Typography>의료 공급 내역서</Typography>
            </Download>
          </RightSection>
        </FlexWrap>
        <Table>
          {formattedRefundInfo.map((item, idx) => (
            <TableContainer
              key={idx}
              isForcedEdit={
                ['등록일자', '병원입력 고객 이름', '성별'].includes(item.label)
                  ? isForcedEdit
                  : false
              }
            >
              <TableHead
                isValue={!item.option.value || item.option.value === '-'}
              >
                {item.label ? item.label : ''}
              </TableHead>
              <TableContent>
                {item.label === '등록일자' ? (
                  <TextField
                    id='datetime-local'
                    disabled={!isForcedEdit}
                    name={item.option.name}
                    type='datetime-local'
                    value={refundInfo.createdDate || ''}
                    onChange={onForceChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                ) : item.label === '병원입력 고객 이름' ? (
                  <TransferInput
                    name={item.option.name}
                    disabled={!isForcedEdit}
                    value={item.option.value || ''}
                    onChange={onForceChange}
                    style={{ paddingLeft: '0px' }}
                  />
                ) : typeof item.option?.value === 'number' ? (
                  comma(item.option?.value) + '원'
                ) : item.label === '성별' ? (
                  <TransferContainer
                    dir='row'
                    gap='20px'
                    style={{ padding: '0px' }}
                  >
                    {GENDER_TYPES.map((type) => {
                      return (
                        <RadioWrap>
                          <RadioInput
                            id={`gender-${type.value}`}
                            type='radio'
                            checked={gender ? type.value === gender : ''}
                            onChange={() => setGender(type.value)}
                            isRadio
                            disabled={!isForcedEdit}
                          />
                          <RadioLabel htmlFor={`gender-${type.value}`}>
                            {type.name}
                          </RadioLabel>
                        </RadioWrap>
                      );
                    })}
                  </TransferContainer>
                ) : (
                  item.option?.value
                )}
                {item.label === '출국 확인일' &&
                  (refundInfo.ticketPath ? (
                    <DepartureButton onClick={() => setIsOpenTicketModal(true)}>
                      항공권
                      <img src={DepartureIcon} alt='' width={20} height={20} />
                    </DepartureButton>
                  ) : !refundInfo.departureConfirmDate &&
                    refundInfo.nation !== 'KOR' ? (
                    <DepartureCheckSection refundId={refundIndex} />
                  ) : (
                    <></>
                  ))}
              </TableContent>
            </TableContainer>
          ))}
        </Table>
        {isDirectPaymentType && (
          <Fragment>
            {/* 송금정보1 */}
            <Typography
              fontSize='18px'
              lineHeight='150%'
              fontWeight='700'
              margin='0 0 32px 0'
            >
              송금 정보
            </Typography>
            <Table>
              {formattedPaymentInfo?.map((items, index) => (
                <Fragment key={index}>
                  {items.map((item, idx) =>
                    ['firstNameEng', 'lastNameEng'].includes(
                      item.option.name,
                    ) && !['CHN', 'JPN'].includes(refundInfo.nation) ? (
                      <></>
                    ) : (
                      <TableContainer key={idx}>
                        <TableHead
                          isValue={
                            !item?.option?.value || item?.option?.value === '-'
                          }
                        >
                          {item?.label && data?.isModifyPossible ? (
                            <Checkbox
                              justifyContent='space-between'
                              onClick={() => selectCell(item)}
                            >
                              {item.label}
                              {item?.option?.name !== 'email' && (
                                <Icon
                                  imgUrl={
                                    selectedAccountInfo.includes(
                                      item?.option?.name,
                                    )
                                      ? checkedIconV2
                                      : checkIconV2
                                  }
                                  width='24px'
                                  height='24px'
                                  margin='0 21px 0 0'
                                />
                              )}
                            </Checkbox>
                          ) : (
                            item.label
                          )}
                        </TableHead>
                        <TransferInput
                          name={item?.option?.name}
                          value={item?.option?.value || ''}
                          disabled={
                            item?.option?.name === '' ||
                            item?.option?.name === 'email' ||
                            !isEdit
                          }
                          placeholder='-'
                          onChange={onChange}
                        />
                      </TableContainer>
                    ),
                  )}
                </Fragment>
              ))}
            </Table>
            {/* 송금정보2 */}
            <Table>
              {formattedAddressInfo?.map((items, index) => (
                <Fragment key={index}>
                  {items.map((item, idx) => (
                    <TableContainer key={idx}>
                      <TableHead
                        isValue={
                          !item.option.value || item.option.value === '-'
                        }
                      >
                        {item?.label && data?.isModifyPossible ? (
                          <Checkbox
                            justifyContent='space-between'
                            onClick={() => selectCell(item)}
                          >
                            {item.label}
                            <Icon
                              imgUrl={
                                selectedAccountInfo.includes(item?.option?.name)
                                  ? checkedIconV2
                                  : checkIconV2
                              }
                              width='24px'
                              height='24px'
                              margin='0 21px 0 0'
                            />
                          </Checkbox>
                        ) : (
                          <>
                            <Label> {item.label} </Label>
                            <LabelNation>{item.nationLabel}</LabelNation>
                          </>
                        )}
                      </TableHead>
                      <TransferInput
                        name={item.option.name}
                        value={
                          isCHUNKAccount(item.option.name)
                            ? chunkedAccountNumber(item.option.value)
                            : item.option.value || ''
                        }
                        disabled={item?.option?.name === '' || !isEdit}
                        placeholder='-'
                        onChange={onChange}
                      />
                      {isCHUNKAccount(item?.option?.name) &&
                        !!item.option.value && (
                          <>
                            <CopyButton
                              onClick={() => onClickCopy(item.option.value)}
                            >
                              복사
                            </CopyButton>
                            <CopySuccess ref={copySuccessRef} className=''>
                              <img src={BadgeSuccess} alt='' width={24} />
                              계좌번호가 복사되었습니다.
                            </CopySuccess>
                          </>
                        )}
                    </TableContainer>
                  ))}
                </Fragment>
              ))}
            </Table>
            {/* 송금정보3 */}
            <Table>
              {formattedAccountInfo?.map((items, index) => (
                <Fragment key={index}>
                  {items.map((item, idx) => (
                    <TableContainer key={idx}>
                      <TableHead
                        isValue={
                          !item.option.value || item.option.value === '-'
                        }
                      >
                        {item?.label && data?.isModifyPossible ? (
                          <Checkbox
                            justifyContent='space-between'
                            onClick={() => selectCell(item)}
                          >
                            <>
                              <Label> {item.label} </Label>
                              <LabelNation>{item.nationLabel}</LabelNation>
                            </>
                            <Icon
                              imgUrl={
                                selectedAccountInfo.includes(item?.option?.name)
                                  ? checkedIconV2
                                  : checkIconV2
                              }
                              width='24px'
                              height='24px'
                              margin='0 21px 0 5px'
                            />
                          </Checkbox>
                        ) : (
                          <>
                            <Label> {item.label} </Label>
                            <LabelNation>{item.nationLabel}</LabelNation>
                          </>
                        )}
                      </TableHead>
                      <TransferInput
                        name={item.option.name}
                        value={item.option.value || ''}
                        disabled={item?.option?.name === '' || !isEdit}
                        placeholder='-'
                        onChange={onChange}
                      />
                    </TableContainer>
                  ))}
                </Fragment>
              ))}
            </Table>
            {/* 송금정보4 중국인 */}
            {refundInfo.nation === 'CHN' && (
              <Table>
                {formattedAccountEngInfo?.map((items, index) => (
                  <Fragment key={index}>
                    {items.map((item, idx) => (
                      <TableContainer key={idx}>
                        <TableHead
                          isValue={
                            !item.option.value || item.option.value === '-'
                          }
                        >
                          {item?.label && data?.isModifyPossible ? (
                            <Checkbox
                              justifyContent='space-between'
                              onClick={() => selectCell(item)}
                            >
                              <>
                                <Label> {item.label} </Label>
                                <LabelNation>{item.nationLabel}</LabelNation>
                              </>
                              <Icon
                                imgUrl={
                                  selectedAccountInfo.includes(
                                    item?.option?.name,
                                  )
                                    ? checkedIconV2
                                    : checkIconV2
                                }
                                width='24px'
                                height='24px'
                                margin='0 21px 0 5px'
                              />
                            </Checkbox>
                          ) : (
                            <>
                              <Label> {item.label} </Label>
                              <LabelNation>{item.nationLabel}</LabelNation>
                            </>
                          )}
                        </TableHead>
                        <TransferInput
                          name={item.option.name}
                          value={item.option.value || ''}
                          disabled={item?.option?.name === '' || !isEdit}
                          placeholder='-'
                          onChange={onChange}
                        />
                      </TableContainer>
                    ))}
                  </Fragment>
                ))}
              </Table>
            )}
          </Fragment>
        )}
        {/* 시술정보1 */}
        <Typography
          fontSize='18px'
          lineHeight='150%'
          fontWeight='700'
          margin='0 0 32px 0'
        >
          시술정보
        </Typography>
        <Table>
          {formattedServiceAccount?.map((items, index) => (
            <Fragment key={index}>
              {items.map((item, idx) => (
                <TableContainer
                  key={idx}
                  isForcedEdit={
                    ['공급 일자'].includes(item.label) ? isForcedEdit : false
                  }
                >
                  <TableHead
                    isValue={!item.option.value || item.option.value === '-'}
                  >
                    {item.label}
                  </TableHead>
                  {item.label === '공급 일자' ? (
                    <TextField
                      id='date-local'
                      disabled={!isForcedEdit}
                      name={item.option.name}
                      type='date'
                      style={{ paddingLeft: 21 }}
                      value={serviceInfo.supplyDate || ''}
                      onChange={onForceChangeServiceInfo}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : item.label === '카드 결제액' ? (
                    <TransferInput
                      placeholder={item.placeholder}
                      name={item.option.name}
                      disabled={!isForcedEdit}
                      value={
                        isForcedEdit
                          ? item.option.value
                          : item.option.value !== ''
                          ? item.option.value + '원'
                          : ''
                      }
                      onChange={onForceChangeServiceInfo}
                    />
                  ) : (
                    <TransferInput
                      name={item.option.name}
                      value={
                        item.option.value
                          ? item.option?.value +
                            (item.label === '병원명' ? '' : '원')
                          : ''
                      }
                      disabled
                      placeholder={item.placeholder}
                    />
                  )}
                </TableContainer>
              ))}
            </Fragment>
          ))}
        </Table>
        {/* 시술정보2 */}
        <Table dir='column'>
          <ServiceInfoWrapper>
            {formattedServiceInfo.map((service, idxS) => (
              <ServiceInfoContainer key={idxS}>
                {service.map((item, idx) => (
                  <TableContainer key={idx} isService>
                    {idxS === 0 && (
                      <TableHead
                        isService
                        isValue={
                          !item.option.value || item.option.value === '-'
                        }
                      >
                        {item.label}
                      </TableHead>
                    )}
                    <TableContent
                      isService
                      noPadding={item.label === '시술 금액'}
                    >
                      {item.label === '시술 금액' ? (
                        <TransferInput
                          name={item.option.name}
                          disabled={!isForcedEdit}
                          value={
                            isForcedEdit
                              ? item.option.value
                              : item.option.value
                              ? item.option.value + '원'
                              : ''
                          }
                          onChange={(e) => onForceChangePrice(e, idxS)}
                          style={{ minHeight: '43px' }}
                        />
                      ) : item.option?.name === 'productType' ? (
                        getServiceType(item.option?.value)
                      ) : (
                        comma(item.option?.value) + '원'
                      )}
                    </TableContent>
                  </TableContainer>
                ))}
              </ServiceInfoContainer>
            ))}
          </ServiceInfoWrapper>
        </Table>
        <FlexWrap justifyContent='flex-end' margin='0 0 32px 0'>
          <DeleteButton onClick={openDeleteModal}>삭제하기</DeleteButton>
        </FlexWrap>
        {isOpenDeleteModal && (
          <DeleteModal
            title='환급 건 삭제'
            desc={`삭제를 원하실 경우 취소 코드를 입력해주세요.\n삭제된 환급내역은 복구할 수 없습니다.`}
            isOpen={isOpenDeleteModal}
            onCancel={closeDeleteModal}
            onDelete={handleRefundDelete}
          />
        )}
      </StyledContainer>
      {downloadData && (
        <SupplyStatementFile data={downloadData} fileRef={fileRef} />
      )}
      {isOpenTicketModal && (
        <TicketModal
          ticketPath={refundInfo.ticketPath}
          onConfirm={onConfirm}
          onClose={onCloseTicketModal}
          onRequest={onSendReuploadMail}
          ticketUploadStatus={refundInfo.ticketUploadStatus}
        />
      )}
      {isOpenSuccessConfirmModal && (
        <SuccessConfirmModal
          onClose={() => setIsOpenSuccessConfirmModal(false)}
        />
      )}
      {isOpenRequestTicketModal && (
        <RequestTicketModal
          onClose={() => setIsOpenRequestTicketModal(false)}
        />
      )}
      {isOpenRequestErrorModal && (
        <RequestTicketModal
          isError
          onClose={() => setIsOpenRequestErrorModal(false)}
        />
      )}
    </>
  );
}
const StyledContainer = styled.div`
  width: 100%;
`;
const Title = styled.h2`
  font-size: 28px;
`;
export const SubTitle = styled.p`
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
`;
const Table = styled(FlexWrap)`
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid #2f2f2f;
  border-right: 1px solid #2f2f2f;
  margin-bottom: 36px;
`;
const TableContainer = styled(FlexWrap)`
  position: relative;
  flex-direction: ${(props) => (props.isService ? 'column' : 'row')};
  width: 50%;
  border-bottom: 1px solid #2f2f2f;
  align-items: center;
  background-color: ${(props) => (props.isForcedEdit ? '#fff' : '#f5f5f5')};
`;
const CopyButton = styled.button`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  padding: 6px 16px;
  background-color: #3a3b3e;
  color: #fff;
  border: 1px solid #e5e6e8;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;
const CopySuccess = styled.div`
  position: absolute;
  bottom: -90%;
  right: 16px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #ffffff;
  background-color: #3a3b3e;
  font-size: 16px;
  font-weight: 500;
  padding: 16px 24px;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, -5px, 0);
  transition: all 200ms ease-in-out;

  &.open {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
  }
`;
const TableHead = styled(FlexWrap)`
  width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-left: 21px;
  min-height: 56px;
  background-color: #e0e0e0;
  border-left: 1px solid #2f2f2f;
  border-right: 1px solid #2f2f2f;
  color: ${(props) => props.isValue && '#757575'};
  ${(props) =>
    props.isService &&
    css`
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #2f2f2f;
    `}
`;
const Label = styled.span``;
const LabelNation = styled.span`
  margin-top: 5px;
  font-size: 12px;
  word-break: break-all;
`;

const TableContent = styled(FlexWrap)`
  align-items: center;
  justify-content: space-between;
  padding-left: 21px;
  ${({ isService }) =>
    isService &&
    css`
      width: 100%;
      padding: 12px 21px;
      border-left: 1px solid #2f2f2f;
    `}
  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}
`;
export const TransferStatus = styled(FlexWrap)`
  position: relative;
  flex: 1;
  align-items: center;
`;
const ServiceInfoWrapper = styled(FlexWrap)`
  width: 100%;
  flex-direction: column;
`;
const ServiceInfoContainer = styled(FlexWrap)`
  width: 100%;
`;
export const TransferContainer = styled(FlexWrap)`
  padding-left: 10px;
  gap: 10px;
  align-items: center;
`;
const TransferInput = styled.input`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  outline: none;
  padding-left: 21px;
  flex: 1;
  border: none;
  background-color: #ffffff;
  min-height: ${(props) => (props.isRadio ? 'auto' : '56px')};
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;
const EditButton = styled.button`
  background-color: ${(props) => (props.isEdit ? '#757575' : '#354eff')};
  color: #ffffff;
  border-radius: 4px;
  padding: 12px 44px;
  cursor: pointer;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
`;
const Download = styled.button`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  color: #246cf6;
  cursor: pointer;
`;
const CloseBtn = styled.button`
  color: #3a3b3e;
  background-color: #e5e6e8;
  padding: 12px 44px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
`;
const DeleteButton = styled.button`
  min-width: 140px;
  height: 44px;
  padding: 6px 32px;
  background-color: #3a3b3e;
  color: #ffffff;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
`;
const MemoInput = styled.textarea`
  padding: 24px 20px;
  width: 100%;
  height: 72px;
  overflow: auto;
  margin-bottom: 40px;
  border-radius: 6px;
  outline: none;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  color: #3a3b3e;
  resize: none;
`;

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const DepartureButton = styled.button`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 6px 12px;
  width: auto;
  height: 33px;
  margin-right: 20px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #e5e6e8;
  font-size: 14px;
  font-weight: 500;
  color: #030303;
`;

const RightSection = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const RadioWrap = styled(FlexWrap)`
  align-items: center;
  gap: 4px;
`;

const RadioInput = styled(StyledInput)`
  width: auto;
  margin: 0;
`;

const RadioLabel = styled.label`
  white-space: nowrap;
`;

export default DetailModalContent;
