import { Button } from '@successmode-ktp/kui';
import { useState } from 'react';
import styled from 'styled-components';
import DetailBannerModal from './DetailBannerModal';
import { format } from 'date-fns';

type Props = {
  column: { type: string; title: string }[];
  row: any[];
};

function DataTable({ column, row }: Props) {
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(-1);

  const showStatusList = [
    'isActivated',
    'originalVisible',
    'directVisible',
    'kioskVisible',
  ];

  const dataLabel = (data: any, type: string) => {
    if (showStatusList.includes(type)) {
      return data[type] ? 'O' : '-';
    }

    if (type === 'detailButton') {
      return (
        <Button
          variant='line'
          theme='mono'
          size='sm'
          style={{ width: '90px', color: '#CBCCCE' }}
          onClick={() => setIsOpenDetailModal(data.bannerNoticeId)}
        >
          상세보기
        </Button>
      );
    }

    if (type === 'createdDate') {
      return format(new Date(data[type]), 'yyyy-MM-dd');
    }
    return data[type];
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableHeadRow role='row'>
            {column.map(({ type, title }) => (
              <TableHeader
                key={type}
                role='columnheader'
                scope='col'
                width={type === 'id' ? '90px' : 'auto'}
              >
                <Header>
                  <HeaderLabel>{title}</HeaderLabel>
                </Header>
              </TableHeader>
            ))}
          </TableHeadRow>
        </TableHead>
        {row.length > 0 && (
          <tbody>
            {row.map((data, index) => (
              <Row role='row' key={data.id} tabIndex={0}>
                {column.map(({ type }) => (
                  <TableData key={type}>
                    <Data>
                      {type === 'id' ? index + 1 : dataLabel(data, type)}
                    </Data>
                  </TableData>
                ))}
              </Row>
            ))}
          </tbody>
        )}
      </Table>
      {row.length === 0 && <NoResult>결과가 존재하지 않습니다.</NoResult>}
      {isOpenDetailModal !== -1 && (
        <DetailBannerModal
          isOpen={isOpenDetailModal}
          onClose={() => setIsOpenDetailModal(-1)}
        />
      )}
    </>
  );
}

const Table = styled.table`
  width: 100%;
  background-color: #ffffff;
  color: #030303;
  border-collapse: collapse;
  margin-bottom: 50px;
`;

const TableHeadRow = styled.tr`
  height: 48px;
  border-bottom: 1px solid #cbccce;
`;

const TableHead = styled.thead``;

const TableHeader = styled.th<{ width: string }>`
  padding: 0 16px;
  width: ${({ width }) => width};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #030303;
`;

const HeaderLabel = styled.span`
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  white-space: nowrap;
`;

const Row = styled.tr`
  height: 48px;
  border-bottom: 1px solid #f5f6f7;
  cursor: pointer;
`;

const TableData = styled.td`
  padding: 0 20px;
`;

const Data = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  white-space: nowrap;
`;

const NoResult = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
`;

export default DataTable;
