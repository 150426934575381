import ActivateTable from '@/components/adSetting/ActivateTable';
import InActivateTable from '@/components/adSetting/InActivateTable';
import UploadModal from '@/components/adSetting/Upload.modal';
import FlexWrap from '@/components/common/FlexWrap';
import LoadingView from '@/components/common/LoadingView';
import Title from '@/components/common/Title';
import useGetAdList from '@/hooks/ad/useGetAdList';
import { Button, Icon } from '@successmode-ktp/kui';
import { useState } from 'react';
import styled from 'styled-components';

const AdBanner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, isLoading } = useGetAdList();

  return (
    <Wrapper>
      <Title>배너 광고 설정</Title>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          <ActivateTable data={data?.activatedAds || []} />
          <InActivateTable data={data?.deActivatedAds || []} />
        </>
      )}
      <BtnContainer>
        <Button style={{ width: '160px' }} onClick={() => setIsOpen(true)}>
          <Icon.Upload
            size={20}
            fillColor='#fff'
            style={{ marginRight: '4px' }}
          />
          배너 광고 업로드
        </Button>
      </BtnContainer>
      {isOpen && <UploadModal isOpen={isOpen} setIsOpen={setIsOpen} />}
    </Wrapper>
  );
};
export default AdBanner;

const Wrapper = styled.div`
  padding: 0 25px;
`;

const BtnContainer = styled(FlexWrap)`
  gap: 20px;
  justify-content: flex-end;
  margin-right: 40px;
`;
