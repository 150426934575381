import { CouponSimple } from '@/types/api/coupon.type';
import { createContext, useState } from 'react';

type Context = {
  couponData: CouponSimple[];
  setCouponData: React.Dispatch<React.SetStateAction<CouponSimple[]>>;
};

type Props = {
  children: React.ReactNode;
};

export const CouponContext = createContext<Context | null>(null);

const CouponProvider = ({ children }: Props) => {
  const [couponData, setCouponData] = useState<CouponSimple[]>([]);

  return (
    <CouponContext.Provider value={{ couponData, setCouponData }}>
      {children}
    </CouponContext.Provider>
  );
};

export default CouponProvider;
