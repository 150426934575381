import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { DatePicker, Input } from '@successmode-ktp/kui';
import styled from 'styled-components';
import checkIcon from '@/assets/common/check.png';
import checkedIcon from '@/assets/common/checked.png';
import Icon from '@/components/common/Icon';

type Props = {
  state: any;
  onChange: (name: string, value: any) => void;
  disabled?: boolean;
};

function DateRangePicker({ state, onChange, disabled = false }: Props) {
  const onChangeTime = (
    name: string,
    value: string,
    type: 'hour' | 'minute',
  ) => {
    let formattedValue = Number(value);

    if (isNaN(formattedValue)) {
      return;
    }

    if (type === 'hour') {
      if (formattedValue < 0) formattedValue = 0;
      if (formattedValue > 23) formattedValue = 23;
    } else if (type === 'minute') {
      if (formattedValue < 0) formattedValue = 0;
      if (formattedValue > 59) formattedValue = 59;
    }

    onChange(name, formattedValue.toString());
  };

  return (
    <Wrapper>
      <RadioGroup
        value={!!state.isReserved ? 'reserved' : 'now'}
        onChange={(e: any) =>
          onChange('isReserved', e.target.value === 'reserved')
        }
        style={{ display: 'flex', flexDirection: 'row', gap: '56px' }}
      >
        <FormControlLabel
          value='now'
          control={
            <Radio
              checked={!state.isReserved}
              name='now'
              color='primary'
              size='small'
              disabled={disabled}
            />
          }
          label='즉시'
        />
        <FormControlLabel
          value='reserved'
          control={
            <Radio
              checked={state.isReserved}
              name='reserved'
              color='primary'
              size='small'
              disabled={disabled}
            />
          }
          label='예약'
        />
      </RadioGroup>
      {state.isReserved && (
        <DatePickerWrapper>
          <DatePickerLabel>시작일</DatePickerLabel>
          <DatePicker
            value={state.startDate}
            onChange={(value) => onChange('startDate', value)}
            format='YYYY-MM-DD'
            disabled={disabled}
          />
          <TimeInputWrapper>
            <Input
              placeholder='00'
              value={state.startHour}
              onChange={(e) =>
                onChangeTime('startHour', e.target.value, 'hour')
              }
              style={{ width: '18px' }}
              disabled={disabled}
              hasClearButton={false}
              maxLength={2}
            />
            <span>시</span>
          </TimeInputWrapper>
          <TimeInputWrapper>
            <Input
              placeholder='00'
              value={state.startMinute}
              onChange={(e) =>
                onChangeTime('startMinute', e.target.value, 'minute')
              }
              style={{ width: '18px' }}
              disabled={disabled}
              hasClearButton={false}
              maxLength={2}
            />
            <span>분</span>
          </TimeInputWrapper>
        </DatePickerWrapper>
      )}
      <DatePickerWrapper>
        <DatePickerLabel>종료일</DatePickerLabel>
        <DatePicker
          value={state.endDate}
          onChange={(value) => onChange('endDate', value)}
          format='YYYY-MM-DD'
          disabled={disabled || state.noEndDate}
        />
        <TimeInputWrapper>
          <Input
            placeholder='00'
            value={state.endHour}
            onChange={(e) => onChangeTime('endHour', e.target.value, 'hour')}
            style={{ width: '18px' }}
            disabled={disabled || state.noEndDate}
            hasClearButton={false}
            maxLength={2}
          />
          <span>시</span>
        </TimeInputWrapper>
        <TimeInputWrapper>
          <Input
            placeholder='00'
            value={state.endMinute}
            onChange={(e) =>
              onChangeTime('endMinute', e.target.value, 'minute')
            }
            style={{ width: '18px' }}
            disabled={disabled || state.noEndDate}
            hasClearButton={false}
            maxLength={2}
          />
          <span>분</span>
        </TimeInputWrapper>
        <CheckboxWrapper>
          <Checkbox
            disabled={disabled}
            onClick={() => onChange('noEndDate', !state.noEndDate)}
          >
            <Icon
              imgUrl={state.noEndDate ? checkedIcon : checkIcon}
              width='24px'
              height='24px'
              margin='0 0 1px 18px'
            />
            <CheckboxLabel>종료 없음</CheckboxLabel>
          </Checkbox>
        </CheckboxWrapper>
      </DatePickerWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const TimeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const DatePickerLabel = styled.div`
  color: #777;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: default;
`;

const Checkbox = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const CheckboxLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: rgb(95, 97, 101);
  line-height: 19px;
  white-space: nowrap;
`;

export default DateRangePicker;
