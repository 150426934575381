import { Input } from '@successmode-ktp/kui';
import FormWrapper from '../FormWrapper';
import { useEffect, useState } from 'react';

type Props = {
  value?: string;
  onChange?: (v: string) => void;
};

const CouponName = ({ value, onChange }: Props) => {
  const [couponName, setCouponName] = useState(value || '');

  useEffect(() => {
    onChange && onChange(couponName);
  }, [couponName]);

  return (
    <FormWrapper label='쿠폰명' required>
      <Input
        value={couponName}
        size='lg'
        onChange={(e) => setCouponName(e.target.value)}
        placeholder='쿠폰명을 입력해주세요'
        style={{ fontSize: '14px' }}
      />
    </FormWrapper>
  );
};
export default CouponName;
