import { Button } from '@successmode-ktp/kui';
import { SectionTitle } from '../promotion/PromotionDetail.modal';
import DataTable from '@/components/common/DataTable';
import { COLUMNS_COUPON } from '@/constants/coupon';
import styled from 'styled-components';
import { TableBody, TableRow } from '@material-ui/core';
import ConfirmModal from '../Confirm.modal';
import CustomTableCell from '@/components/common/CustomTableCell';
import { useState } from 'react';
import {
  CouponTemplateSummary,
  DownloadCouponRequest,
} from '@/types/api/coupon.type';
import { useMutation, useQueryClient } from 'react-query';
import { downloadCoupon } from '@/api/coupon';

type Props = {
  data: CouponTemplateSummary[];
  onClick?: () => void;
};

const CouponTypeList = ({ data, onClick }: Props) => {
  const [selectData, setSelectData] = useState<{
    couponTemplateId: string;
    count: number;
  } | null>(null);
  const queryClient = useQueryClient();

  const { mutate: downloadCoupons } = useMutation(
    (payload: DownloadCouponRequest) => downloadCoupon(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getPromotions');
        queryClient.invalidateQueries('getPromotionDetailCoupons');
        setSelectData(null);
      },
    },
  );

  return (
    <section>
      <SectionTitle>
        쿠폰 종류
        <Button size='sm' style={{ width: '140px' }} onClick={onClick}>
          쿠폰 추가하기
        </Button>
      </SectionTitle>
      <DataTable
        columns={COLUMNS_COUPON}
        contentComponent={
          <>
            <TableBody>
              {data?.map((row) => (
                <StyledTableRow key={row.id}>
                  <CustomTableCell>{row.name}</CustomTableCell>
                  <CustomTableCell>{row.discount}</CustomTableCell>
                  <CustomTableCell>{row.issued}</CustomTableCell>
                  <CustomTableCell>{row.downloaded}</CustomTableCell>
                  <CustomTableCell>{row.used}</CustomTableCell>
                  <CustomTableCell width='300px'>
                    <ButtonList>
                      <Button
                        onClick={() =>
                          setSelectData({ couponTemplateId: row.id, count: 10 })
                        }
                        size='sm'
                        variant='line'
                        theme='mono'
                        style={{ width: '80px' }}
                      >
                        10장
                      </Button>
                      <Button
                        onClick={() =>
                          setSelectData({ couponTemplateId: row.id, count: 30 })
                        }
                        size='sm'
                        variant='line'
                        theme='mono'
                        style={{ width: '80px' }}
                      >
                        30장
                      </Button>
                      <Button
                        onClick={() =>
                          setSelectData({ couponTemplateId: row.id, count: 50 })
                        }
                        size='sm'
                        variant='line'
                        theme='mono'
                        style={{ width: '80px' }}
                      >
                        50장
                      </Button>
                    </ButtonList>
                  </CustomTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <ConfirmModal
              isOpen={!!selectData}
              onClose={() => setSelectData(null)}
              onOK={() => downloadCoupons(selectData!)}
              title='쿠폰 다운로드 처리 하시겠습니까?'
              message={`${selectData?.count}장 다운로드 수가 증가합니다.`}
              okLabel='사용하기'
            />
          </>
        }
      />
    </section>
  );
};
export default CouponTypeList;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;

const ButtonList = styled.div`
  display: flex;
  gap: 10px;
`;
