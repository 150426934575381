import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any';

export const convertHEICToJPEG = async (file: File) => {
  const blob = new Blob([file], { type: file.type });
  let jpgBlob = await heic2any({
    blob,
    toType: 'image/jpeg',
    quality: 0.5,
  });

  if (Array.isArray(jpgBlob)) {
    jpgBlob = jpgBlob[0];
  }

  const pngFile = new File([jpgBlob], file.name.split('.')[0] + '.jpeg', {
    type: 'image/jpeg',
  });
  return pngFile;
};

const sizeToMB = (size: number) => size / 1024 / 1024;

export const resizeFile = async (file: File) => {
  const MAX_SIZE_MB = 2;
  const options = {
    maxSizeMB: MAX_SIZE_MB,
  };

  if (sizeToMB(file.size) > MAX_SIZE_MB) {
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Image compression error:', error);
      return file;
    }
  }

  return file;
};

export const getFileName = (filePath: string) => {
  if (!filePath) return '';
  const arr = filePath.split('/');
  return arr[arr.length - 1];
};
