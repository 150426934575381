import { Input } from '@successmode-ktp/kui';
import FormWrapper from '../FormWrapper';
import { useEffect, useState } from 'react';
import { DiscountType } from '@/types/api/coupon.type';

type Props = {
  discountType: DiscountType;
  value?: number;
  onChange?: (v: number) => void;
};

const CouponPrice = ({ value, onChange, discountType }: Props) => {
  const [price, setPrice] = useState(value || 0);

  useEffect(() => {
    onChange && onChange(price);
  }, [price]);

  return (
    <FormWrapper label='할인 금액' required>
      <Input
        value={price.toString()}
        size='lg'
        onChange={(e) => setPrice(Number(e.target.value))}
        placeholder={`할인 ${
          discountType === 'RATE' ? '퍼센티지를' : '금액을'
        } 입력해주세요`}
        addonAfter={<span>{discountType === 'RATE' ? '%' : '원'}</span>}
        style={{ fontSize: '14px' }}
      />
    </FormWrapper>
  );
};
export default CouponPrice;
