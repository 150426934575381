import store from '@/store';
import { ActiveAdPayload, AdResponse, SwitchAdPayload } from '@/types/ad.type';
import api from '..';

const getTargetServerURL = () => {
  return store.getState().serverState === 'prod'
    ? 'https://erp.successmode.co.kr/medical'
    : 'https://erp.successmode.co.kr/medical/test';
};

export const getMedicalAdList = async (id: number) => {
  const res = await api.getAxios<AdResponse, any>(
    `${getTargetServerURL()}/hospital/advertisement/${id}`,
  );
  return res.data;
};

export const uploadMedicalAd = async (payload: FormData) => {
  const res = await api.postAxios<null, FormData, null>(
    `${getTargetServerURL()}/hospital/advertisement`,
    payload,
    null,
  );
  return res.data;
};

export const switchMedicalAdList = async (payload: SwitchAdPayload) => {
  const res = await api.patchAxios<null, SwitchAdPayload, null>(
    `${getTargetServerURL()}/hospital/advertisement/priority`,
    payload,
  );
  return res.data;
};

export const activeMedicalAd = async (payload: ActiveAdPayload) => {
  const res = await api.patchAxios<null, ActiveAdPayload, null>(
    `${getTargetServerURL()}/hospital/advertisement`,
    payload,
  );
  return res.data;
};

export const deleteMedicalAd = async (id: number) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/hospital/advertisement/${id}`,
  );
  return res.data;
};
