import { comma } from '@/utils/ktp';

type Props = {
  count: number;
  price: number;
};

function PreviewMailTemplate({ count, price }: Props) {
  return (
    <table
      cellPadding='0'
      cellSpacing='0'
      width='100%'
      style={{
        border: '0',
        fontFamily: "'Pretendard', 'Noto Sans KR', sans-serif",
        color: '#191919',
      }}
    >
      <tbody style={{ display: 'block', width: '700px', margin: '0 auto' }}>
        {/* KTP 로고 */}
        <tr>
          <td style={{ paddingBottom: '20px' }}>
            <img
              width='700'
              src='https://ktp-image.s3.ap-northeast-2.amazonaws.com/common/nondeparture_mail_header.jpg'
              alt='KTP 로고'
            />
          </td>
        </tr>
        <tr style={{ width: '100%', display: 'block' }}>
          <td width='100%' style={{ display: 'block' }}>
            {/* 본문 */}
            <table
              width='100%'
              cellPadding={0}
              cellSpacing={0}
              style={{
                backgroundColor: '#ffffff',
                border: '0',
                display: 'inline-block',
                borderRadius: '8px',
                borderCollapse: 'collapse',
                paddingTop: '40px',
                textAlign: 'center',
              }}
              className='container'
            >
              <tbody style={{ display: 'block', width: '100%' }}>
                <tr style={{ width: '100%', display: 'block' }}>
                  <th
                    align='center'
                    style={{
                      width: '100%',
                      display: 'block',
                      fontSize: '24px',
                      textAlign: 'left',
                      marginBottom: '24px',
                    }}
                  >
                    외국인 관광객 택스리펀 금액 미출국 건 확인 - 금액 보전 안내
                  </th>
                </tr>
                <tr
                  style={{
                    width: '100%',
                    display: 'block',
                  }}
                >
                  <th
                    align='center'
                    style={{
                      width: '100%',
                      display: 'block',
                      fontSize: '16px',
                      textAlign: 'left',
                      marginBottom: '24px',
                      fontWeight: '400',
                      color: '#0000ff',
                      paddingBottom: '16px',
                      borderBottom: '1px dashed #000000',
                    }}
                  >
                    (본 메일은 등록일자 기준 3개월 초과 미출국건 발생 시
                    발송되는 메일입니다.)
                  </th>
                </tr>
                <tr style={{ width: '100%', display: 'block' }}>
                  <th
                    align='center'
                    style={{
                      width: '100%',
                      display: 'block',
                      fontSize: '16px',
                      textAlign: 'left',
                      marginBottom: '24px',
                      fontWeight: '400',
                      lineHeight: '48px',
                      paddingBottom: '16px',
                      borderBottom: '1px dashed #000000',
                    }}
                  >
                    안녕하세요 KTP 입니다.
                    <br />
                    환급등록 건 중{' '}
                    <span style={{ fontWeight: '700' }}>
                      택스리펀 미출국건이 발생
                    </span>
                    하였습니다.
                    <br />
                    이에 해당 시술건 부가세 보전을 위해 메일 드립니다.
                  </th>
                </tr>
                <tr style={{ width: '100%', display: 'block' }}>
                  <th
                    align='center'
                    style={{
                      width: '100%',
                      display: 'block',
                      paddingBottom: '6px',
                      fontSize: '18px',
                      textAlign: 'left',
                      marginBottom: '24px',
                      fontWeight: '700',
                    }}
                  >
                    [위 문제에 대한 처리 지침]
                  </th>
                </tr>
                <tr style={{ width: '100%', display: 'block' }}>
                  <th
                    align='center'
                    style={{
                      width: '100%',
                      display: 'block',
                      paddingBottom: '6px',
                      fontSize: '16px',
                      textAlign: 'left',
                      marginBottom: '24px',
                      fontWeight: '400',
                      lineHeight: '20px',
                    }}
                  >
                    KTP는 국세청 지정 환급창구운영사업자로 위와 같은 상황에 대해{' '}
                    <span style={{ fontWeight: '700', color: '#998a00' }}>
                      대금 보전
                    </span>
                    의 의무를 가지고 있습니다.
                    <br />
                    매장에서 아래 부가세 보전 금액에 대해 송금받으실 계좌정보를
                    회신 주시면, 당월 15일 이내 지급 예정입니다.
                  </th>
                </tr>

                <tr style={{ marginBottom: '20px' }}>
                  <table style={{ borderCollapse: 'collapse' }}>
                    <tr
                      style={{
                        height: '62px',
                        borderTop: '1px solid #7f7f7f',
                        borderBottom: '1px solid #bfbfbf',
                      }}
                    >
                      <th
                        style={{
                          width: '200px',
                          backgroundColor: '#f2f2f2',
                          borderRight: '1px solid #bfbfbf',
                        }}
                      >
                        환수금액
                      </th>
                      <td
                        style={{
                          backgroundColor: '#f2f2f2',
                          width: '480px',
                          textAlign: 'center',
                          color: '#ff0000',
                        }}
                        colSpan={2}
                      >
                        {count}건 / {comma(price)}원
                      </td>
                    </tr>
                    <tr
                      style={{
                        height: '62px',
                        borderTop: '1px solid #7f7f7f',
                        borderBottom: '1px solid #bfbfbf',
                      }}
                    >
                      <th
                        style={{
                          width: '120px',
                          backgroundColor: '#f2f2f2',
                          borderRight: '1px solid #bfbfbf',
                        }}
                      >
                        보전 계좌 선택
                      </th>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #dddddd',
                        }}
                      >
                        ( ) CMS 등록 계좌
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        ( ) 이외의 다른 계좌
                      </td>
                    </tr>
                    <tr style={{ height: '40px' }}>
                      <th
                        rowSpan={3}
                        style={{
                          color: '#0000ff',
                          backgroundColor: '#f2f2f2',
                          borderRight: '1px solid #bfbfbf',
                        }}
                      >
                        이외의 다른 계좌 선택 시 (작성필요
                      </th>
                      <th
                        style={{
                          color: '#0000ff',
                          backgroundColor: '#f2f2f2',
                          borderBottom: '1px solid #bfbfbf',
                        }}
                      >
                        은행명
                      </th>
                      <td style={{ borderBottom: '1px solid #bfbfbf' }}></td>
                    </tr>
                    <tr style={{ height: '40px' }}>
                      <th
                        style={{ color: '#0000ff', backgroundColor: '#f2f2f2' }}
                      >
                        계좌번호
                      </th>
                      <td></td>
                    </tr>
                    <tr
                      style={{
                        height: '40px',
                        borderBottom: '1px solid #7f7f7f',
                        borderTop: '1px solid #bfbfbf',
                      }}
                    >
                      <th
                        style={{ color: '#0000ff', backgroundColor: '#f2f2f2' }}
                      >
                        소유주
                      </th>
                      <td></td>
                    </tr>
                  </table>
                </tr>

                <tr style={{ width: '100%', display: 'block' }}>
                  <th
                    align='center'
                    style={{
                      width: '100%',
                      display: 'block',
                      paddingBottom: '6px',
                      fontSize: '16px',
                      textAlign: 'left',
                      marginBottom: '24px',
                      fontWeight: '400',
                      lineHeight: '48px',
                    }}
                  >
                    관련한 문의는 KTP 고객센터(02-6275-8011)을 이용해주세요.
                    <br />
                    앞으로도 사업장에서 사후면세판매제도 운영함에 있어 최선의
                    솔루션을 제공하겠습니다.
                    <br />
                    감사합니다. KTP드림.
                  </th>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default PreviewMailTemplate;
