import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
function StoreTable({ columns, contentComponent }: any) {
  return (
    <TableContainer>
      <Table
        style={{ border: '1px solid #CBCCCE', borderCollapse: 'collapse' }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column: any, idx: number) => (
              <TableCell
                key={column.type + idx}
                style={{
                  backgroundColor: '#E5E6E8',
                  padding: '0 20px',
                  height: 48,
                  fontSize: 14,
                  fontWeight: 600,
                  border: '1px solid #CBCCCE',
                  borderCollapse: 'collapse',
                  textAlign: column.type === 'detailButton' ? 'center' : 'left',
                }}
              >
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {contentComponent}
      </Table>
    </TableContainer>
  );
}

export default StoreTable;
