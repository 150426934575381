import styled from 'styled-components';
import { SectionTitle } from '../promotion/PromotionDetail.modal';
import DataTable from '@/components/common/DataTable';
import { COLUMNS_COUPON_USED } from '@/constants/coupon';
// import { Pagination } from '@successmode-ktp/kui';
import { TableBody, TableRow } from '@material-ui/core';
import CustomTableCell from '@/components/common/CustomTableCell';
import { CouponUsage } from '@/types/api/coupon.type';
import { format } from 'date-fns';

type Props = {
  data?: CouponUsage[];
};

const CouponUsedList = ({ data }: Props) => {
  return (
    <section>
      <SectionTitle>쿠폰 사용 내역</SectionTitle>
      <TableWrapper>
        <DataTable
          columns={COLUMNS_COUPON_USED}
          contentComponent={
            <TableBody>
              {data?.map((row) => {
                const newDate = new Date(row.createdAt);
                return (
                  <StyledTableRow key={row.id}>
                    <CustomTableCell>{row.name}</CustomTableCell>
                    <CustomTableCell>
                      {format(newDate, 'yyyy-MM-dd HH:mm:ss')}
                    </CustomTableCell>
                    <CustomTableCell>{row.customerName}</CustomTableCell>
                    <CustomTableCell>
                      {row.price?.toLocaleString()}
                    </CustomTableCell>
                    <CustomTableCell>
                      {row.refund?.toLocaleString()}
                    </CustomTableCell>
                    <CustomTableCell>
                      {row.discount?.toLocaleString()}
                    </CustomTableCell>
                    <CustomTableCell>
                      {row.checkoutPrice?.toLocaleString()}
                    </CustomTableCell>
                    <CustomTableCell>{row.status}</CustomTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          }
        />
        {/* <Pagination page={1} setPage={() => {}} totalPage={10} /> */}
      </TableWrapper>
    </section>
  );
};
export default CouponUsedList;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;
