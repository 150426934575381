import AddStoreModal from '@/components/tourcoupon/store/StoreAdd.modal';
import PromotionAddModal from '@/components/tourcoupon/promotion/PromotionAdd.modal';
import StoreTable from '@/components/tourcoupon/StoreTable';
import StoreTableList from '@/components/tourcoupon/promotion/StoreTableList';
import { COLUMNS_COUPON_STORE } from '@/constants/coupon';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';
import { Container } from '@material-ui/core';
import { Button, Icon } from '@successmode-ktp/kui';
import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { getPromotion } from '@/api/coupon';
import CouponProvider from '@/context/coupon.context';

type Props = {
  id: string;
  name: string;
};

function PromotionPage({ id, name }: Props) {
  const updateUrlParams = useUpdateUrlParams();
  const [isOpenStoreModal, setIsOpenStoreModal] = useState(false);
  const [isOpenPromotionModal, setIsOpenPromotionModal] = useState(false);

  const onBack = () => {
    updateUrlParams({ id: '' });
  };

  const { data } = useQuery('getPromotions', () => getPromotion(id));

  return (
    <CouponProvider>
      <StyledContainer maxWidth='xl'>
        <Header>
          <Title onClick={onBack} style={{ cursor: 'pointer' }}>
            투어쿠폰 가맹점
          </Title>
          <Icon.ChevronRight size={24} fillColor='#030303' />
          <Title>{name}</Title>
        </Header>
        <ButtonWrapper>
          <Button
            onClick={() => setIsOpenStoreModal(true)}
            style={{ width: '140px' }}
            size='sm'
          >
            가맹점 정보
          </Button>
          <Button
            onClick={() => setIsOpenPromotionModal(true)}
            style={{ width: '140px' }}
            size='sm'
          >
            프로모션 추가
          </Button>
        </ButtonWrapper>
        <StoreTable
          columns={COLUMNS_COUPON_STORE}
          contentComponent={
            <StoreTableList storeId={id} data={data?.promotions || []} />
          }
        />
      </StyledContainer>
      {isOpenStoreModal && id && (
        <AddStoreModal
          isOpen={isOpenStoreModal}
          onClose={() => setIsOpenStoreModal(false)}
          id={id}
        />
      )}
      {isOpenPromotionModal && (
        <PromotionAddModal
          storeId={id}
          isOpen={isOpenPromotionModal}
          onClose={() => setIsOpenPromotionModal(false)}
        />
      )}
    </CouponProvider>
  );
}

export default PromotionPage;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 40px;
`;

const Title = styled.h3`
  font-size: 30px;
  font-weight: 700;
  color: #212121;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-bottom: 24px;
`;
