import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode | ReactNode[];
  label: string;
  required?: boolean;
  addonAfter?: ReactNode;
};

function FormWrapper({ children, label, required = false, addonAfter }: Props) {
  return (
    <Wrapper>
      <Header>
        <Label>
          {label}
          {required && <Required>*</Required>}
        </Label>
        {addonAfter && addonAfter}
      </Header>
      <InputWrapper>{children}</InputWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  color: #5f6165;
  font-size: 14px;
  font-weight: 600;
`;

const Required = styled.span`
  margin-left: 4px;
  color: #ed0828;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export default FormWrapper;
