import { getDepartureStatusList } from '@/api/medical';
import Title from '@/components/common/Title';
import DateSelector from '@/components/medical/nonDeparture/DateSelector';
import DetailModal from '@/components/medical/nonDeparture/Detail.modal';
import Table from '@/components/medical/nonDeparture/Table';
import TotalSummary from '@/components/medical/nonDeparture/TotalSummary';
import { NON_DEPARTURE_HEADER } from '@/constants/medical';
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

const HospitalNonDeparture = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openedInfo, setOpenedInfo] = useState<{ id: number; title: string }>({
    id: -1,
    title: '',
  });
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');

  const { data } = useQuery(
    ['nonDeparture', year, month],
    () => getDepartureStatusList({ year, month }),
    {
      enabled: !!(year && month),
    },
  );

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    if (currentMonth <= 3) {
      setYear((currentYear - 1).toString());
      setMonth((12 - 3 + currentMonth).toString());
    } else {
      setYear(currentYear.toString());
      setMonth(currentMonth.toString());
    }
  }, []);

  return (
    <>
      <StyledContainer maxWidth='xl'>
        <Title>미출국 현황</Title>
        <DateSelector
          year={year}
          month={month}
          onChangeYear={setYear}
          onChangeMonth={setMonth}
        />
        <TotalSummary
          totalCount={data?.totalCount || 0}
          totalReturned={data?.totalReturned || 0}
        />
        <Table
          column={NON_DEPARTURE_HEADER}
          row={data?.details || []}
          onClick={({ id, title }) => {
            setOpenedInfo({ id, title });
            setIsOpen(true);
          }}
        />
      </StyledContainer>
      {isOpen && (
        <DetailModal
          isOpen={isOpen}
          openId={openedInfo.id}
          onClose={() => setIsOpen(false)}
          year={year}
          month={month}
          totalCount={
            data?.details.find((item) => item.hospitalId === openedInfo.id)
              ?.count || 0
          }
          totalReturned={
            data?.details.find((item) => item.hospitalId === openedInfo.id)
              ?.returned || 0
          }
          title={openedInfo.title}
        />
      )}
    </>
  );
};
export default HospitalNonDeparture;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`;
