import { Input } from '@successmode-ktp/kui';
import FormWrapper from '../FormWrapper';
import { useEffect, useState } from 'react';

type Props = {
  value?: string;
  onChange?: (v: string) => void;
};

const StoreId = ({ value, onChange }: Props) => {
  const [id, setId] = useState(value || '');

  useEffect(() => {
    onChange && onChange(id);
  }, [id]);

  useEffect(() => {
    if (value) {
      setId(value);
    }
  }, [value]);

  return (
    <FormWrapper label='매장 No' required>
      <Input
        value={id}
        size='lg'
        onChange={(e) => setId(e.target.value)}
        placeholder='매장 No. 를 입력해주세요'
        style={{ fontSize: '14px' }}
      />
    </FormWrapper>
  );
};
export default StoreId;
