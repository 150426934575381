import { Input } from '@successmode-ktp/kui';
import FormWrapper from '../FormWrapper';
import { useEffect, useState } from 'react';

type Props = {
  value?: string;
  onChange?: (v: string) => void;
};

const PromotionName = ({ value, onChange }: Props) => {
  const [name, setName] = useState(value || '');

  useEffect(() => {
    onChange && onChange(name);
  }, [name]);

  return (
    <FormWrapper label='프로모션명' required>
      <Input
        value={name}
        size='lg'
        onChange={(e) => setName(e.target.value)}
        placeholder='프로모션명을 입력해주세요'
        style={{ fontSize: '14px' }}
      />
    </FormWrapper>
  );
};
export default PromotionName;
