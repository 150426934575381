import { getCouponStore } from '@/api/coupon';
import DataTable from '@/components/common/DataTable';
import Search from '@/components/common/Search';
import Title from '@/components/common/Title';
import PromotionPage from '@/components/tourcoupon/promotion/PromotionPage';
import StoreAddModal from '@/components/tourcoupon/store/StoreAdd.modal';
import StoreList from '@/components/tourcoupon/store/StoreList';
import { couponColumns } from '@/constants/coupon';
import { Container } from '@material-ui/core';
import { Button } from '@successmode-ktp/kui';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

function TourCoupon() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const detailId = searchParams.get('id');
  const storeName = searchParams.get('name');
  const [isOpenToAddStore, setIsOpenToAddStore] = useState(false);
  const searchKeyword = searchParams.get('search') || '';

  const { data } = useQuery(
    ['getCouponStores', searchKeyword],
    () => getCouponStore({ page: 0, pageSize: 100, storeName: searchKeyword }),
    {
      refetchOnWindowFocus: false,
    },
  );

  if (!!detailId) {
    return <PromotionPage id={detailId} name={storeName || ''} />;
  }

  return (
    <>
      <StyledContainer maxWidth='xl'>
        <Title>투어쿠폰 가맹점</Title>
        <Search data={[]} placeholder='상호명을 입력해주세요.' />
        <Navigation>
          <Button
            onClick={() => setIsOpenToAddStore(true)}
            style={{ width: '140px' }}
            size='sm'
          >
            가맹점 추가
          </Button>
          {/* <ViewDataSelection initialSize='10' /> */}
        </Navigation>
        <DataTable
          columns={couponColumns}
          contentComponent={<StoreList data={data?.stores || []} />}
        />
      </StyledContainer>
      {isOpenToAddStore && (
        <StoreAddModal
          isOpen={isOpenToAddStore}
          onClose={() => setIsOpenToAddStore(false)}
        />
      )}
    </>
  );
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 28px;
  margin-bottom: 16px;
`;

export default TourCoupon;
