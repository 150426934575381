import { Icon, Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';
import CouponUsedList from '../coupon/CouponUsedList';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function SattlementsDetailModal({ isOpen, onClose }: Props) {
  return (
    <>
      <Modal isOpen={isOpen} hasBackdrop onClickBackdrop={onClose}>
        <Modal.Header>
          <HeaderWrapper>
            <Title>노블코코</Title>
            <CloseButton onClick={onClose}>
              <Icon.X size={40} fillColor='#CBCCCE' />
            </CloseButton>
          </HeaderWrapper>
        </Modal.Header>
        <Content>
          <CouponUsedList />
        </Content>
      </Modal>
    </>
  );
}

const HeaderWrapper = styled.div`
  max-width: 1480px;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
`;

const Title = styled.h4`
  margin: 0;
  font-size: 32px;
  font-weight: 700;
  color: #030303;
`;

const CloseButton = styled.button``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

export default SattlementsDetailModal;
