import { activeAd, activeDefaultAd } from '@/api/ktp/ad.api';
import { activeMedicalAd } from '@/api/medical/ad.api';
import { ActiveAdPayload } from '@/types/ad.type';
import { getEndPoint } from '@/utils/api.util';
import { useMutation, useQueryClient } from 'react-query';

const useActiveAd = (id?: number) => {
  const queryClient = useQueryClient();
  const endPoint = getEndPoint(id);

  const getTargetApi = (payload: ActiveAdPayload) => {
    switch (endPoint) {
      case 'medical':
        return activeMedicalAd(payload);
      case 'store':
        return activeAd(payload);
      default:
        return activeDefaultAd(payload);
    }
  };

  const {
    mutate: changeToggleAd,
    isLoading,
    isSuccess,
  } = useMutation((payload: ActiveAdPayload) => getTargetApi(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('getAdList');
    },
    onError: (error: any) => {
      console.error(error);
    },
  });

  return { changeToggleAd, isLoading, isSuccess };
};
export default useActiveAd;
