import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';
import { getLatestYear, monthData } from '@/utils/common';
import { Dropdown } from '@successmode-ktp/kui';
import { useState } from 'react';
import styled from 'styled-components';

type Props = {
  year: string;
  month: string;
};

const DateSelector = ({ year, month }: Props) => {
  const updateUrlParams = useUpdateUrlParams();
  const [selecterYear, setSelectedYear] = useState<string>(
    year || new Date().getFullYear().toString(),
  );
  const [selectedMonth, setSelectedMonth] = useState<string>(
    month || new Date().getFullYear().toString(),
  );

  return (
    <Wrapper>
      <Dropdown
        options={getLatestYear(2024, new Date().getFullYear())}
        selectedValue={selecterYear}
        setSelectedValue={(year) => {
          setSelectedYear(year);
          updateUrlParams({ year });
        }}
        size='md'
        style={{
          width: '100px',
        }}
        variant='solid'
      />
      <Dropdown
        options={monthData}
        selectedValue={selectedMonth}
        setSelectedValue={(month) => {
          setSelectedMonth(month);
          updateUrlParams({ month });
        }}
        size='md'
        style={{
          width: '80px',
        }}
        variant='solid'
      />
    </Wrapper>
  );
};
export default DateSelector;

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 30px;
`;
