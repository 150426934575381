import { TableBody, TableRow } from '@material-ui/core';
import CustomTableCell from '../../common/CustomTableCell';
import styled from 'styled-components';
// import { Button } from '@successmode-ktp/kui';
import { useState } from 'react';
import SattlementsDetailModal from './SattlementsDetail.modal';
import FinishModal from '../Finish.modal';
import { Settlement } from '@/types/api/coupon.type';

type Props = {
  data: Settlement[];
};

function SattlementsTable({ data }: Props) {
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);
  const [isOpenFinishModal, setIsOpenFinishModal] = useState(false);

  return (
    <>
      <TableBody style={{ backgroundColor: '#ffffff' }}>
        {data.map((item) => (
          <StyledTableRow key={item.id}>
            <CustomTableCell
              style={{
                padding: '0 20px',
                maxWidth: '90px',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {item.id}
            </CustomTableCell>
            <CustomTableCell
              style={{
                padding: '0 20px',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {item.promoteStoreName}
            </CustomTableCell>
            <CustomTableCell
              style={{
                padding: '0 ',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {item.startDate} ~ {item.endDate}
            </CustomTableCell>
            <CustomTableCell
              style={{
                padding: '0 ',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {item.count}
            </CustomTableCell>
            <CustomTableCell
              style={{
                padding: '0 20px',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {item.settlementAmount}
            </CustomTableCell>
            {/* <CustomTableCell
              rowSpan={row.coupon.length}
              style={{
                padding: '0 20px',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              <Button
                onClick={() => setIsOpenDetailModal(true)}
                size='sm'
                variant='line'
                theme='mono'
                style={{ height: '29px', whiteSpace: 'nowrap' }}
              >
                사용내역보기
              </Button>
            </CustomTableCell>
            <CustomTableCell
              rowSpan={row.coupon.length}
              style={{ padding: '0 20px', width: '90px' }}
            >
              <Button
                size='sm'
                variant={row.status === 'FINISHED' ? 'solid' : 'line'}
                theme={row.status === 'FINISHED' ? 'mono' : 'primary'}
                style={{ width: '90px', height: '29px', whiteSpace: 'nowrap' }}
                onClick={() =>
                  row.status !== 'FINISHED' && setIsOpenFinishModal(true)
                }
                disabled={row.status === 'FINISHED'}
              >
                {row.status === 'FINISHED' ? '정산완료' : '정산하기'}
              </Button>
            </CustomTableCell> */}
          </StyledTableRow>
        ))}
      </TableBody>
      {isOpenDetailModal && (
        <SattlementsDetailModal
          isOpen={isOpenDetailModal}
          onClose={() => setIsOpenDetailModal(false)}
        />
      )}
      {isOpenFinishModal && (
        <FinishModal
          isOpen={isOpenFinishModal}
          onClose={() => setIsOpenFinishModal(false)}
        />
      )}
    </>
  );
}

const StyledTableRow = styled(TableRow)`
  cursor: pointer;

  :hover {
    background-color: #f7f9fc;
  }
`;

export default SattlementsTable;
