import Title from '@/components/common/Title';
import SattlementsTable from '@/components/tourcoupon/settlements/SattlementsTable';
import DateSelector from '@/components/tourcoupon/settlements/DateSelector';
import StoreTable from '@/components/tourcoupon/StoreTable';
import { COLUMNS_SATTLEMENTS } from '@/constants/coupon';
import { Container } from '@material-ui/core';
import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { getSettlements } from '@/api/coupon';
import { GetSettlementsQuery } from '@/types/api/coupon.type';

function SettleMentsPage() {
  const [date, setDate] = useState<GetSettlementsQuery>();

  const { data } = useQuery(
    ['getSettlementList', date],
    () => getSettlements(date!),
    {
      enabled: !!date,
    },
  );

  return (
    <>
      <StyledContainer maxWidth='xl'>
        <Title>정산</Title>
        <DateSelector onChange={(v) => setDate(v)} />
        <StoreTable
          columns={COLUMNS_SATTLEMENTS}
          contentComponent={<SattlementsTable data={data?.settlements || []} />}
        />
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export default SettleMentsPage;
