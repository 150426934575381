import { switchAdList, switchDefaultAdList } from '@/api/ktp/ad.api';
import { switchMedicalAdList } from '@/api/medical/ad.api';
import { SwitchAdPayload } from '@/types/ad.type';
import { getEndPoint } from '@/utils/api.util';
import { useMutation } from 'react-query';

const useSwitchAdList = (id?: number) => {
  const endPoint = getEndPoint(id);

  const getTargetApi = (payload: SwitchAdPayload) => {
    switch (endPoint) {
      case 'medical':
        return switchMedicalAdList(payload);
      case 'store':
        return switchAdList(payload);
      default:
        return switchDefaultAdList(payload);
    }
  };

  const {
    mutate: switchAd,
    isLoading,
    isSuccess,
  } = useMutation((payload: SwitchAdPayload) => getTargetApi(payload), {
    onError: (error: any) => {
      console.error(error);
    },
  });

  return { switchAd, isLoading, isSuccess };
};
export default useSwitchAdList;
