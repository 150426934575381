import { useQuery } from 'react-query';
import styled from 'styled-components';

import { getWithdrawalDetail } from '@/api/kiosk';
import Modal from '@/components/common/Modal';
import { GetWithdrawalDetailResponse } from '@/types/api/kiosk';
import WithdrawalModalContent from './WithdrawalModalContent';
import Typography from '@/components/common/Typography';
import FlexWrap from '@/components/common/FlexWrap';
import DeleteModal from '@/components/common/Modal/DeleteModal';

import useModal from '@/hooks/common/useModal';
import { useKioskTransactionDelete } from '@/hooks/kiosk/useKioskTransactionDelete';

type WithdrawalDetailModalProps = {
  kioskWithdrawalIndex: number;
  onClose: () => void;
};

function WithdrawalDetailModal({
  kioskWithdrawalIndex,
  onClose,
}: WithdrawalDetailModalProps) {
  const {
    isOpenModal: isOpenDeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const { mutate: deleteMutation } = useKioskTransactionDelete();

  const handleRefundDelete = () => {
    if (kioskWithdrawalIndex) {
      deleteMutation(kioskWithdrawalIndex, {
        onSuccess: () => {
          closeDeleteModal();
          onClose();
        },
      });
    }
  };

  const { isLoading, data } = useQuery<GetWithdrawalDetailResponse, number>(
    ['withdrawalDetail', kioskWithdrawalIndex],
    () => getWithdrawalDetail(kioskWithdrawalIndex),
    {
      refetchOnWindowFocus: false,
      onError: () =>
        alert('데이터 조회에 실패하였습니다. 관리자에게 문의해주세요.'),
    },
  );
  return (
    <Modal onClose={onClose} isLoading={isLoading}>
      {data ? (
        <WithdrawalModalContent
          data={data}
          onClose={onClose}
          kioskWithdrawalIndex={kioskWithdrawalIndex}
        />
      ) : (
        <StyledContainer>
          <Typography fontSize='24px'>환급 내역이 없습니다.</Typography>
          {/* 출금내역 완전 삭제 */}
          <FlexWrap justifyContent='flex-end'>
            <DeleteButton onClick={openDeleteModal}>삭제하기</DeleteButton>
          </FlexWrap>
          {isOpenDeleteModal && (
            <DeleteModal
              title='출금 건 삭제'
              desc={`삭제를 원하실 경우 취소 코드를 입력해주세요.\n삭제된 출금내역은 복구할 수 없습니다.`}
              isOpen={isOpenDeleteModal}
              onCancel={closeDeleteModal}
              onDelete={handleRefundDelete}
            />
          )}
        </StyledContainer>
      )}
    </Modal>
  );
}

export default WithdrawalDetailModal;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;
`;

const DeleteButton = styled.button`
  min-width: 140px;
  height: 44px;
  padding: 6px 32px;
  background-color: #3a3b3e;
  color: #ffffff;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
`;