import { Input } from '@successmode-ktp/kui';
import FormWrapper from '../FormWrapper';
import { useEffect, useState } from 'react';

type Props = {
  value?: number;
  onChange?: (v: number) => void;
};

const CouponCount = ({ value, onChange }: Props) => {
  const [quantity, setQuantity] = useState(value || 0);

  useEffect(() => {
    onChange && onChange(quantity);
  }, [quantity]);

  return (
    <FormWrapper label='발행 개수' required>
      <Input
        value={quantity.toString()}
        size='lg'
        onChange={(e) => setQuantity(Number(e.target.value))}
        placeholder='발행 갸수를 입력해주세요'
        addonAfter={<span>장</span>}
        style={{ fontSize: '14px' }}
      />
    </FormWrapper>
  );
};
export default CouponCount;
