import { Button, Icon, Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';
import { useState } from 'react';
import AddCouponModal from '../coupon/CouponAdd.modal';
import ConfirmModal from '../Confirm.modal';
import CouponTypeList from '../coupon/CouponTypeList';
import {
  CreateCouponTemplateRequest,
  PromotionDetail,
} from '@/types/api/coupon.type';
import CouponUsedList from '../coupon/CouponUsedList';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createCoupon,
  deletePromotion,
  getPromotion,
  getPromotionDetail,
} from '@/api/coupon';

type Props = {
  data: PromotionDetail;
  isOpen: boolean;
  onClose: () => void;
};

function PromotionDetailModal({ data, isOpen, onClose }: Props) {
  const { storeId, promotionId, date } = data;
  const [isOpenAddCoupon, setIsOpenAddCoupon] = useState(false);
  const [isOpenEndModal, setIsOpenEndModal] = useState(false);
  const [issuedAt, expireAt] = date.split(' ~ ');

  const queryClient = useQueryClient();
  const { mutate: create } = useMutation(
    (couponData: CreateCouponTemplateRequest) =>
      createCoupon({
        promotionId,
        ...couponData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getPromotions');
        queryClient.invalidateQueries('getPromotionDetailCoupons');
      },
    },
  );

  const { mutate: finishPromotion } = useMutation(
    () => deletePromotion(promotionId),
    {
      onSuccess: () => {
        setIsOpenEndModal(false);
        queryClient.invalidateQueries('getPromotions');
        onClose();
      },
    },
  );

  const getPromotionCoupons = async (storeId: string, promotionId: string) => {
    const res = await getPromotion(storeId, promotionId);
    const [promotion] = res.promotions || [];
    return promotion.coupons;
  };

  const { data: couponList } = useQuery(
    ['getPromotionDetailCoupons', storeId, promotionId],
    () => getPromotionCoupons(storeId, promotionId),
  );

  const getPromotionCouponsHistory = async () => {
    const res = await getPromotionDetail({
      storeId,
      promotionId,
      pageSize: 100,
    });
    return res.coupons;
  };

  const { data: couponHistory } = useQuery(
    ['getPromotionDetailCouponHistory', storeId, promotionId],
    () => getPromotionCouponsHistory(),
  );

  return (
    <>
      <Modal isOpen={isOpen} hasBackdrop onClickBackdrop={onClose}>
        <Modal.Header>
          <HeaderWrapper>
            <Title>{data.storeName} 상세보기</Title>
            <CloseButton onClick={onClose}>
              <Icon.X size={40} fillColor='#CBCCCE' />
            </CloseButton>
          </HeaderWrapper>
        </Modal.Header>
        <Content>
          <Section>
            <SectionTitle>진행 기간</SectionTitle>
            <DateWrapper>
              <Date>{date}</Date>
            </DateWrapper>
          </Section>
          <CouponTypeList
            data={couponList || []}
            onClick={() => setIsOpenAddCoupon(true)}
          />
          <CouponUsedList data={couponHistory || []} />
        </Content>
        <ModalFooter>
          <Button
            onClick={() => setIsOpenEndModal(true)}
            style={{
              backgroundColor: '#3A3B3E',
              color: '#fff',
              width: '162px',
            }}
          >
            프로모션 종료하기
          </Button>
        </ModalFooter>
      </Modal>
      {isOpenAddCoupon && (
        <AddCouponModal
          storeId={data.storeId}
          promotionId={data.promotionId}
          date={{ issuedAt, expireAt }}
          isOpen={isOpenAddCoupon}
          onClose={() => setIsOpenAddCoupon(false)}
          onSubmit={(v) => create(v)}
        />
      )}
      {isOpenEndModal && (
        <ConfirmModal
          isOpen={isOpenEndModal}
          onClose={() => setIsOpenEndModal(false)}
          onOK={() => finishPromotion()}
          title='프로모션을 종료하시겠습니까?'
          message='프로모션에 해당되는 모든 쿠폰이 삭제됩니다.'
          okLabel='종료하기'
          isWarning
        />
      )}
    </>
  );
}

const HeaderWrapper = styled.div`
  max-width: 1480px;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
`;

const Title = styled.h4`
  margin: 0;
  font-size: 32px;
  font-weight: 700;
  color: #030303;
`;

const CloseButton = styled.button``;

const ModalFooter = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const Section = styled.section``;

export const SectionTitle = styled.h5`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 16px 0;
  font-size: 18px;
  font-weight: 600;
  color: #030303;
`;

const DateWrapper = styled.div`
  padding: 16px;
  border: 1px solid #e5e6e8;
  border-radius: 6px;
`;

const Date = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #3a3b3e;
`;

export default PromotionDetailModal;
