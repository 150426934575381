import { Icon } from '@successmode-ktp/kui';
import { useState } from 'react';
import styled from 'styled-components';
import ConfirmModal from '../Confirm.modal';

type Props = {
  name: string;
  discount: number;
  created: string;
  condition: number;
  onDelete: () => void;
};

function CouponCard({ name, discount, created, condition, onDelete }: Props) {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  return (
    <>
      <Wrapper>
        <Header>
          <Name>{name}</Name>
          <DeleteButton onClick={() => setIsOpenDeleteModal(true)}>
            <Icon.XFilled size={16} fillColor='#E5E6E8' />
          </DeleteButton>
        </Header>
        <InformationList>
          <Information>
            <Title>할인 금액</Title>
            <Data>{discount}</Data>
          </Information>
          <Information>
            <Title>발행 개수</Title>
            <Data>{created}</Data>
          </Information>
          <Information>
            <Title>사용 조건</Title>
            <Data>{condition}</Data>
          </Information>
        </InformationList>
      </Wrapper>
      <ConfirmModal
        isOpen={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
        onOK={() => {
          onDelete();
          setIsOpenDeleteModal(false);
        }}
        title='쿠폰을 삭제하시겠습니까?'
        message='삭제된 쿠폰은 다시 추가해야 합니다.'
        okLabel='삭제하기'
        isWarning
      />
    </>
  );
}

export default CouponCard;

const Wrapper = styled.li`
  padding: 16px;
  border: 1px solid #cbccce;
  border-radius: 6px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const Name = styled.h5`
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #80848a;
`;

const DeleteButton = styled.button`
  width: 16px;
  height: 16px;
`;

const InformationList = styled.dl`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Information = styled.div`
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

const Title = styled.dt`
  color: #80848a;
`;

const Data = styled.dd`
  margin: 0;
  color: #3a3b3e;
`;
