import { getBannerList } from '@/api/medical';
import Title from '@/components/common/Title';
import CreateBannerModal from '@/components/medical/banner/CreateBannerModal';
import DataTable from '@/components/medical/banner/DataTable';
import {
  BANNER_FILTER_OPTIONS,
  BANNER_TABLE_HEADER,
  BANNER_VISIBLE_FILTER_OPTIONS,
} from '@/constants/medical';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';
import { Container } from '@material-ui/core';
import { Button, MultiSelection, Pagination } from '@successmode-ktp/kui';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

function HospitalBanner() {
  const location = useLocation();
  const updateUrlParams = useUpdateUrlParams();
  const searchParams = new URLSearchParams(location.search);
  const initialPage = searchParams.get('page') || '0';
  const initialSize = searchParams.get('size') || '10';

  const [page, setPage] = useState(
    initialPage === '0' ? 1 : Number(initialPage) + 1,
  );
  const [totalPage, setTotalPage] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  const [filterHospital, setFilterHospital] = useState<string[]>([
    'DIRECT',
    'ORIGINAL',
    'KIOSK',
  ]);
  const [filterVisible, setFilterVisible] = useState<string[]>([
    'visible',
    'invisible',
  ]);

  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);

  const { data } = useQuery(
    ['bannerList', page, filterHospital, filterVisible],
    () =>
      getBannerList({
        page: page - 1,
        size: Number(initialSize),
        bannerType:
          filterHospital.length === 0 || filterHospital.length === 3
            ? ['ALL']
            : filterHospital,
        isActive:
          filterVisible.length === 0 || filterVisible.length === 2
            ? null
            : filterVisible.includes('visible')
            ? 'true'
            : 'false',
      }),
    {
      onSuccess: (data) => {
        setTotalPage(data.totalPages);
        setIsFirstPage(data.first);
        setIsLastPage(data.last);
      },
    },
  );

  return (
    <>
      <StyledContainer maxWidth='xl'>
        <Title>배너 안내</Title>
        <FilterWrapper>
          <MultiSelection
            name='hospitalType'
            title='조회'
            options={BANNER_FILTER_OPTIONS}
            selectedItems={filterHospital}
            setSelectedItems={setFilterHospital}
          />
          <MultiSelection
            name='visibleType'
            title='노출여부'
            options={BANNER_VISIBLE_FILTER_OPTIONS}
            selectedItems={filterVisible}
            setSelectedItems={setFilterVisible}
          />
        </FilterWrapper>
        <DataTable column={BANNER_TABLE_HEADER} row={data?.content || []} />
        {totalPage > 0 && (
          <Pagination
            page={page}
            totalPage={totalPage}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            displaySize={5}
            setPage={(page) => {
              setPage(page);
              updateUrlParams({ page: (page - 1).toString() });
            }}
          />
        )}
        <BottomSection>
          <Button
            size='sm'
            style={{ width: '140px' }}
            onClick={() => setIsOpenCreateModal(true)}
          >
            신규작성
          </Button>
        </BottomSection>
      </StyledContainer>
      {isOpenCreateModal && (
        <CreateBannerModal
          isOpen={isOpenCreateModal}
          onClose={() => setIsOpenCreateModal(false)}
        />
      )}
    </>
  );
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  label {
    white-space: nowrap;
  }
`;

const BottomSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

export default HospitalBanner;
