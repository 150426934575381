import { Icon, Modal } from '@successmode-ktp/kui';
import styled, { css } from 'styled-components';
import { comma } from '@/utils/ktp';
import { useQuery } from 'react-query';
import { getRefundStoreDetail } from '@/api/ktp';
import { getRefundHospitalDetail } from '@/api/medical';
import { useLocation } from 'react-router-dom';

type Props = {
  isOpen: { id: number; name: string };
  onClose: () => void;
  isMedical?: boolean;
};

function DetailModal({ isOpen, onClose, isMedical = false }: Props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const year = searchParams.get('year') || new Date().getFullYear().toString();
  const month =
    searchParams.get('month') || (new Date().getMonth() + 1).toString();

  const { data } = useQuery(['refundStatementDetail'], () =>
    isMedical
      ? getRefundHospitalDetail({ hospitalId: isOpen.id, year, month })
      : getRefundStoreDetail({ franchiseeId: isOpen.id, year, month }),
  );

  const billList = isMedical
    ? [
        {
          label: '환급처리비용',
          value: data?.bill.totalBill || 0,
          unit: '원',
        },
        {
          label: '환급건수',
          value: data?.bill.count || 0,
          unit: '건',
        },
        {
          label: '판매금액',
          value: data?.bill.totalPrice || 0,
          unit: '원',
        },
        {
          label: '부가가치세',
          value: data?.bill.totalVat || 0,
          unit: '원',
        },
      ]
    : [
        {
          label: '환급처리비용',
          value: data?.totalBill || 0,
          unit: '원',
        },
        {
          label: '환급건수',
          value: data?.count || 0,
          unit: '건',
        },
        {
          label: '판매금액',
          value: data?.totalPrice || 0,
          unit: '원',
        },
        {
          label: '부가가치세',
          value: data?.totalVat || 0,
          unit: '원',
        },
      ];

  const refundResults = isMedical
    ? [
        {
          label: '건수',
          value: data?.refundResults.count || 0,
          unit: '건',
        },
        {
          label: '공급금액(세금포함)',
          value: data?.refundResults.totalVat || 0,
          unit: '원',
        },
        {
          label: '부가가치세',
          value: data?.refundResults.totalPrice || 0,
          unit: '원',
        },
        {
          label: '환급액',
          value: data?.refundResults.totalRefund || 0,
          unit: '원',
        },
      ]
    : [];

  return (
    <Modal
      isOpen={isOpen.id > -1}
      hasBackdrop
      wrapperStyle={{
        maxWidth: '95vw',
      }}
    >
      <ModalTitle>{isOpen.name}</ModalTitle>
      <CloseButton onClick={onClose}>
        <Icon.X size={24} fillColor='#5F6165' />
      </CloseButton>
      <Content>
        <DataContent isMedical={isMedical}>
          <Title>환급처리비용청구서</Title>
          <DataList>
            {billList.map((d) => (
              <DataItem key={d.label}>
                <DataTitle>{d.label}</DataTitle>
                <Data>
                  <DataValue>{comma(d.value)}</DataValue>
                  <DataUnit>{d.unit}</DataUnit>
                </Data>
              </DataItem>
            ))}
          </DataList>
        </DataContent>
        {isMedical && (
          <DataContent isMedical={isMedical}>
            <Title>실적명세서</Title>
            <DataList>
              {refundResults.map((d) => (
                <DataItem key={d.label}>
                  <DataTitle>{d.label}</DataTitle>
                  <Data>
                    <DataValue>{comma(d.value)}</DataValue>
                    <DataUnit>{d.unit}</DataUnit>
                  </Data>
                </DataItem>
              ))}
            </DataList>
          </DataContent>
        )}
      </Content>
    </Modal>
  );
}

const ModalTitle = styled.h3`
  color: #3a3b3e;
  text-align: left;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  flex-wrap: nowrap;

  @media screen and (max-width: 1400px) {
    flex-wrap: wrap;
  }
`;

const DataContent = styled.div<{ isMedical: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 24px 16px;
  width: 100%;
  border: 1px solid #e5e6e8;
  border-radius: 12px;

  ${({ isMedical }) =>
    isMedical
      ? css`
          max-width: 640px;
        `
      : css`
          max-width: 1000px;
        `}
`;

const Title = styled.strong`
  color: #3a3b3e;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
`;

const DataList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DataItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 148px;
  height: 100%;
  border-right: 1px solid #e5e6e8;
  padding: 0 16px;

  &:last-child {
    border-right: 0;
  }
`;

const DataTitle = styled.dt`
  color: #777;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
`;

const Data = styled.dd`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  white-space: nowrap;
`;

const DataValue = styled.span`
  color: #246cf6;
`;

const DataUnit = styled.span`
  color: #5f6165;
`;

const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 32px;
  right: 40px;
`;
export default DetailModal;
