import { uploadAd, uploadDefaultAd } from '@/api/ktp/ad.api';
import { uploadMedicalAd } from '@/api/medical/ad.api';
import { getEndPoint } from '@/utils/api.util';
import { useMutation, useQueryClient } from 'react-query';

const useUploadAd = (id?: number) => {
  const queryClient = useQueryClient();
  const endPoint = getEndPoint(id);

  const getTargetApi = (payload: FormData) => {
    switch (endPoint) {
      case 'medical':
        return uploadMedicalAd(payload);
      case 'store':
        return uploadAd(payload);
      default:
        return uploadDefaultAd(payload);
    }
  };

  const {
    mutate: upload,
    isLoading,
    isSuccess,
  } = useMutation((payload: FormData) => getTargetApi(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('getAdList');
    },
    onError: (error: any) => {
      console.error(error);
    },
  });

  return { upload, isLoading, isSuccess };
};
export default useUploadAd;
