import { useEffect, useState } from 'react';
import styled from 'styled-components';
import FormWrapper from '../FormWrapper';
import Icon from '@/components/common/Icon';
import ICON_RADIO_ON from '@/assets/common/radioActive.png';
import ICON_RADIO_OFF from '@/assets/common/radio.png';
import { DiscountType } from '@/types/api/coupon.type';

type Props = {
  value?: DiscountType;
  onChange?: (v: DiscountType) => void;
};

const CouponType = ({ value, onChange }: Props) => {
  const [discountType, setDiscountType] = useState<DiscountType>(
    value || 'AMOUNT',
  );

  useEffect(() => {
    onChange && onChange(discountType);
  }, [discountType]);

  return (
    <FormWrapper label='할인 방식' required>
      <InputList>
        <Radio
          type='button'
          isSelected={discountType === 'AMOUNT'}
          onClick={() => setDiscountType('AMOUNT')}
        >
          <InputWrapper>
            <RadioLabel htmlFor='no-date'>
              금액 할인
              <Icon
                imgUrl={
                  discountType === 'AMOUNT' ? ICON_RADIO_ON : ICON_RADIO_OFF
                }
                width='20px'
                height='20px'
              />
            </RadioLabel>
            <RadioInput type='radio' id='no-date' />
          </InputWrapper>
        </Radio>
        <Radio
          type='button'
          isSelected={discountType === 'RATE'}
          onClick={() => setDiscountType('RATE')}
        >
          <InputWrapper>
            <RadioLabel htmlFor='no-date'>
              % 할인
              <Icon
                imgUrl={
                  discountType === 'RATE' ? ICON_RADIO_ON : ICON_RADIO_OFF
                }
                width='20px'
                height='20px'
              />
            </RadioLabel>
            <RadioInput type='radio' id='no-date' />
          </InputWrapper>
        </Radio>
      </InputList>
    </FormWrapper>
  );
};
export default CouponType;

const InputList = styled.div`
  display: flex;
  gap: 12px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid var(--Gray-09);
`;

const RadioLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
`;

const Radio = styled.button<{ isSelected: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;

  ${({ isSelected }) =>
    isSelected
      ? `
    ${RadioLabel} {
      color: #246CF6;
    }

    ${InputWrapper} {
      border-color: #246CF6;
    }
  `
      : `
      ${RadioLabel} {
      color: #cbccce;
    }
      `}
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
`;
