import { Fragment, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';
import { TextField } from '@material-ui/core';

import {
  formatRefundDetail,
  formatRefundPaymentInfo,
  formatRefundAddressInfo,
  formatAccountInfo,
  formatReceiptForm,
  formatAccountEngInfo,
  comma,
  removeComma,
  getRefundAmount,
} from '@/utils/ktp';
import {
  chunkedAccountNumber,
  convertComponentToImage,
  copyToClipboard,
} from '@/utils/common';
import FlexWrap from '@/components/common/FlexWrap';
import {
  forceModifyRefund,
  getReceiptData,
  updateRefundDetail,
} from '@/api/ktp';
import Typography from '@/components/common/Typography';
import Icon from '@/components/common/Icon';
import ReceiptImage from '@/components/ktp/refund-status/ReceiptImage';
import ModifyNeedCheckbox from '@/components/common/Remittance/ModifyNeedCheckbox';
import RemittanceStatusRadio, { TransferInput } from './RemittanceStatusRadio';
import PaymentWayRadio from './PaymentWayRadio';
import MailingStatusRadio from './MailingStatusRadio';
import DeleteModal from '@/components/common/Modal/DeleteModal';

import downloadIcon from '@assets/common/download.png';
import checkIconV2 from '@/assets/common/checkbox_v2.png';
import checkedIconV2 from '@/assets/common/checkbox_v2_fill.png';
import BadgeSuccess from '@/assets/common/badge_success.png';

import useModal from '@/hooks/common/useModal';
import { useRefundDelete } from '@/hooks/ktp/useRefundDelete';
import { Button } from '@successmode-ktp/kui';
import { RefundMethodTypes } from '@/constants/ktp';
import { format } from 'date-fns';

function DetailContent({ data, setData, refundIndex, onClose }) {
  const copySuccessRef = useRef(null);
  const { detailRefundInfo, accountInfo, paymentStatus } = data;
  const [isEdit, setIsEdit] = useState(false);
  const [isForcedEdit, setIsForcedEdit] = useState(false);
  const [files, setFiles] = useState([]);
  const [isModifyNeeded, setIsModifyNeeded] = useState(
    data?.isModifyNeeded || false,
  );
  const [selectedAccountInfo, setSelectedAccountInfo] = useState(
    data?.selectedAccountInfo || [],
  );
  const checkPaymentInfo = accountInfo;
  const [receiptData, setReceiptData] = useState(null);
  const {
    isOpenModal: isOpenDeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const { mutate: deleteMutation } = useRefundDelete();

  const isHkg = checkPaymentInfo && accountInfo?.isHkgNation;

  const formattedRefundInfo = formatRefundDetail(detailRefundInfo, isHkg);
  const formattedPaymentInfo =
    checkPaymentInfo &&
    formatRefundPaymentInfo(accountInfo, detailRefundInfo.customerNational);
  const formattedAddressInfo =
    checkPaymentInfo && formatRefundAddressInfo(accountInfo);
  const formattedAccountInfo =
    checkPaymentInfo && formatAccountInfo(accountInfo);
  const formattedAccountEngInfo =
    checkPaymentInfo && formatAccountEngInfo(accountInfo);

  const downloadMutation = useMutation((payload) => getReceiptData(payload), {
    retry: false,
    onSuccess: (data) => {
      setReceiptData(formatReceiptForm(data));
    },
  });

  const updateMutation = useMutation(
    ['updateMedicalInfo'],
    (payload) => updateRefundDetail(refundIndex, payload),
    {
      retry: false,
      onSuccess: () => {
        setIsEdit(false);
        alert('환급현황 수정이 완료되었습니다.');
      },
    },
  );

  const onClickEdit = () => {
    if (isEdit) {
      const payload = {
        approvalFinishDate: detailRefundInfo.approvalFinishDate,
        memo: detailRefundInfo.memo,
        mailSendCount: detailRefundInfo.mailSendCount,
        transferDate:
          detailRefundInfo.paymentStatus === 'PAYMENT_COMPLETE'
            ? detailRefundInfo.paymentCompleteDate
            : null,
        mailSendDate: detailRefundInfo.lastSentDate,
        paymentStatus: detailRefundInfo.paymentStatus,
        needFullPayment: detailRefundInfo.needFullPayment,
        accountInfo: accountInfo ? JSON.stringify(accountInfo) : null,
        isModifyNeeded,
        selectedAccountInfo,
      };
      updateMutation.mutate(payload);
    } else {
      setIsEdit(true);
    }
  };

  const onClickDownload = () => {
    downloadMutation.mutate({ refundIndex });
  };

  const handleRefundDelete = () => {
    if (refundIndex) {
      deleteMutation(refundIndex, {
        onSuccess: () => {
          closeDeleteModal();
          onClose();
        },
      });
    }
  };

  const handleDownload = async () => {
    if (files.length < 1) return;
    const blob = await convertComponentToImage(files[0]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `환급전표_${refundIndex}`;
    link.click();
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    handleDownload();
  }, [files]);

  const onChangeApprovalFinishDate = (e) => {
    setData((prev) => {
      return {
        ...prev,
        detailRefundInfo: {
          ...prev.detailRefundInfo,
          approvalFinishDate: e.target.value,
        },
      };
    });
  };

  const onChangeMemo = (e) => {
    setData((prev) => {
      return {
        ...prev,
        detailRefundInfo: {
          ...prev.detailRefundInfo,
          memo: e.target.value,
        },
      };
    });
  };

  const onChange = (e) => {
    const name = e.target.name;
    setData((prev) => {
      return {
        ...prev,
        accountInfo: {
          ...prev.accountInfo,
          [name]: e.target.value,
        },
      };
    });
  };

  const onForceChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const formattedValue =
      name === 'customerName'
        ? value.toUpperCase()
        : name === 'totalAmount'
        ? Number(value?.replace(/,|원/g, ''))
          ? comma(Number(value?.replace(/,|원/g, '')))
          : ''
        : value;

    setData((prev) => {
      const changedTotalRefund =
        name === 'totalAmount'
          ? getRefundAmount(removeComma(formattedValue))
          : detailRefundInfo.totalRefund;
      return {
        ...prev,
        detailRefundInfo: {
          ...prev.detailRefundInfo,
          [name]: formattedValue,
          totalRefund: changedTotalRefund,
        },
      };
    });
  };

  const onForceChangePaymentType = (value) => {
    setData((prev) => {
      return {
        ...prev,
        detailRefundInfo: {
          ...prev.detailRefundInfo,
          paymentMethod: value,
        },
      };
    });
  };

  const { mutate: mutateForcedEdit } = useMutation(forceModifyRefund);

  const onForcedEdit = () => {
    const payload = {
      refundId: refundIndex,
      refundType: detailRefundInfo.refundType,
      createdDate: format(
        new Date(detailRefundInfo.createdDate),
        'yyyy-MM-dd HH:mm:ss',
      ),
      customerName: detailRefundInfo.customerName,
      totalAmount: removeComma(detailRefundInfo.totalAmount),
      refundAmount: detailRefundInfo.totalRefund,
      paymentMethod: detailRefundInfo.paymentMethod,
    };

    mutateForcedEdit(payload, {
      onSuccess: () => {
        alert('정보 강제 수정하였습니다.');
        setIsForcedEdit(false);
      },
      onError: () => alert('정보 강제 수정에 실패하였습니다.'),
    });
  };

  const selectModifyNeed = () => {
    if (!isEdit) return;
    if (isModifyNeeded) {
      setSelectedAccountInfo([]);
    }
    setIsModifyNeeded(!isModifyNeeded);
  };

  const selectCell = (item) => {
    if (isEdit) {
      if (selectedAccountInfo.includes(item?.option?.name)) {
        const filteredCell = selectedAccountInfo.filter(
          (cell) => cell !== item?.option?.name,
        );
        setSelectedAccountInfo(filteredCell);
      } else {
        setSelectedAccountInfo((prev) => [...prev, item?.option?.name]);
      }
    }
  };

  const onClickCopy = (value) => {
    copyToClipboard(value);
    copySuccessRef.current.classList.add('open');

    setTimeout(() => {
      copySuccessRef.current.classList.remove('open');
    }, 2000);
  };

  const isCHUNKAccount = (name) =>
    name === 'bankAccount' &&
    (data.detailRefundInfo.customerNational === 'CHN' ||
      data.detailRefundInfo.customerNational === 'KOR');

  return (
    <StyledContainer>
      <FlexWrap
        justifyContent='space-between'
        alignItems='center'
        margin='0 0 32px 0'
      >
        <Title>환급 현황 상세보기</Title>
        <FlexWrap gap='16px'>
          <CloseBtn onClick={onClose}>닫기</CloseBtn>
          <EditButton isEdit={isEdit} onClick={onClickEdit}>
            {isEdit ? '수정완료' : '수정하기'}
          </EditButton>
        </FlexWrap>
      </FlexWrap>
      {/* 송금상태, 결제방식, 송금일자 */}
      {paymentStatus && (
        <FlexWrap margin='0 0 36px 0'>
          <TransferStatus
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <RemittanceStatusRadio
              refundInfo={detailRefundInfo}
              isEdit={isEdit}
              setData={setData}
            />
            <PaymentWayRadio
              detailRefundInfo={detailRefundInfo}
              isEdit={isForcedEdit}
              setData={setData}
            />
          </TransferStatus>
          <MailingStatusRadio
            detailRefundInfo={detailRefundInfo}
            isEdit={isEdit}
            setData={setData}
          />
        </FlexWrap>
      )}
      {data?.isModifyPossible && (
        <ModifyNeedCheckbox
          isModifyNeeded={isModifyNeeded}
          onClick={selectModifyNeed}
        />
      )}
      <MemoInput
        placeholder='메모를 입력하세요. (최대 1000자)'
        disabled={!isEdit}
        onChange={onChangeMemo}
        value={detailRefundInfo.memo}
      />
      {/* 기본정보 */}
      <FlexWrap
        alignItems='center'
        justifyContent='space-between'
        margin='0 0 24px 0'
      >
        <Typography fontSize='18px' lineHeight='150%' fontWeight='700'>
          No.{refundIndex}
        </Typography>
        <RightSection>
          <Button
            size='sm'
            style={{
              width: '204px',
              backgroundColor: isForcedEdit ? '#E5E6E8' : '#3A3B3E',
              color: isForcedEdit ? '#3A3B3E' : '#FFFFFF',
            }}
            onClick={isForcedEdit ? onForcedEdit : () => setIsForcedEdit(true)}
          >
            {isForcedEdit ? '수정 완료' : '정보 강제 수정'}
          </Button>
          <Download onClick={onClickDownload}>
            <Icon imgUrl={downloadIcon} widht='16px' height='16px' />
            <Typography>환급 전표 이미지 파일</Typography>
          </Download>
        </RightSection>
      </FlexWrap>
      <StyledTable width='100%' align='center'>
        {formattedRefundInfo?.map((items, index) => (
          <tbody key={index}>
            <StyledTr>
              {items.map((item, idx) => (
                <Fragment key={idx}>
                  <StyledTh
                    isValue={
                      !item?.option?.value || item?.option?.value === '-'
                    }
                  >
                    {item.label}
                  </StyledTh>
                  <StyledTd
                    isEdit={item.label === '환급 확정일자' ? isEdit : false}
                    isForcedEdit={
                      [
                        '공급일자',
                        '매장입력 고객 이름',
                        '결제 방식',
                        '판매금액',
                      ].includes(item.label)
                        ? isForcedEdit
                        : false
                    }
                  >
                    {item.label === '환급 확정일자' ? (
                      <TextField
                        id='datetime-local'
                        disabled={!isEdit}
                        name={item.option.name}
                        type='datetime-local'
                        style={{ paddingLeft: 21 }}
                        value={detailRefundInfo.approvalFinishDate || ''}
                        onChange={onChangeApprovalFinishDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    ) : item.label === '결제 방식' ? (
                      <TransferContainer dir='row' gap='20px'>
                        {RefundMethodTypes.map((type) => (
                          <FlexWrap>
                            <TransferInput
                              id={`paymentMethod-${type.value}`}
                              type='radio'
                              checked={
                                type.value === detailRefundInfo.paymentMethod
                              }
                              onChange={() =>
                                onForceChangePaymentType(type.value)
                              }
                              disabled={!isForcedEdit}
                              isRadio
                            />
                            <label htmlFor={`paymentMethod-${type.value}`}>
                              {type.name}
                            </label>
                          </FlexWrap>
                        ))}
                      </TransferContainer>
                    ) : item.label === '공급일자' ? (
                      <TextField
                        id='datetime-local'
                        disabled={!isForcedEdit}
                        name={item.option.name}
                        type='datetime-local'
                        style={{ paddingLeft: 21 }}
                        value={detailRefundInfo.createdDate || ''}
                        onChange={onForceChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    ) : item.label === '판매금액' ? (
                      <StyledInput
                        name={item.option.name}
                        disabled={!isForcedEdit}
                        value={
                          isForcedEdit
                            ? item.option.value
                            : item.option.value
                            ? item.option.value + '원'
                            : ''
                        }
                        onChange={onForceChange}
                      />
                    ) : item.label === '매장입력 고객 이름' ? (
                      <StyledInput
                        name={item.option.name}
                        disabled={!isForcedEdit}
                        value={item.option.value || ''}
                        onChange={onForceChange}
                      />
                    ) : item.label === '출국 확인 및 반출승인번호' ? (
                      <DepartureSection>
                        <span style={{ whiteSpace: 'nowrap' }}>
                          {item.option.value}
                        </span>
                        {data?.detailRefundInfo.takeOutNumber && (
                          <>
                            <GoodsShipmentNumber
                              onClick={() =>
                                onClickCopy(data.detailRefundInfo.takeOutNumber)
                              }
                            >
                              {data.detailRefundInfo.takeOutNumber}
                            </GoodsShipmentNumber>
                            <CopySuccess ref={copySuccessRef} className=''>
                              <img src={BadgeSuccess} alt='' width={24} />
                              반출승인번호가 복사되었습니다.
                            </CopySuccess>
                          </>
                        )}
                      </DepartureSection>
                    ) : (
                      <StyledInput disabled value={item.option.value || ''} />
                    )}
                  </StyledTd>
                </Fragment>
              ))}
            </StyledTr>
          </tbody>
        ))}
      </StyledTable>
      {/* 송금정보1 */}
      {accountInfo && (
        <>
          <Typography
            fontSize='18px'
            lineHeight='150%'
            fontWeight='700'
            margin='0 0 32px 0'
          >
            송금 정보
          </Typography>
          <StyledTable width='100%' align='center'>
            {formattedPaymentInfo?.map((items, index) => (
              <tbody key={index}>
                <StyledTr>
                  {items.map((item, idx) =>
                    ['firstNameEng', 'lastNameEng'].includes(
                      item.option.name,
                    ) && detailRefundInfo.customerNational !== 'CHN' ? (
                      <></>
                    ) : (
                      <Fragment key={idx}>
                        <StyledTh
                          isValue={
                            !item?.option?.value || item?.option?.value === '-'
                          }
                        >
                          {item?.label && data?.isModifyPossible ? (
                            <Checkbox
                              justifyContent='space-between'
                              onClick={() => selectCell(item)}
                            >
                              {item.label}
                              <Icon
                                imgUrl={
                                  selectedAccountInfo.includes(
                                    item?.option?.name,
                                  )
                                    ? checkedIconV2
                                    : checkIconV2
                                }
                                width='24px'
                                height='24px'
                                margin='0 21px 0 0'
                              />
                            </Checkbox>
                          ) : (
                            item.label
                          )}
                        </StyledTh>
                        <StyledTd>
                          <StyledInput
                            name={item.option.name}
                            disabled={
                              item?.option?.name === '' ||
                              item?.option?.name === 'email' ||
                              !isEdit
                            }
                            onChange={onChange}
                            value={item.option.value || ''}
                            type='text'
                            placeholder='-'
                          />
                        </StyledTd>
                      </Fragment>
                    ),
                  )}
                </StyledTr>
              </tbody>
            ))}
          </StyledTable>

          {/* 송금정보2 */}
          <StyledTable width='100%' align='center'>
            {formattedAddressInfo?.map((items, index) => (
              <tbody key={index}>
                <StyledTr>
                  {items.map((item, idx) => (
                    <Fragment key={idx}>
                      <StyledTh
                        isValue={
                          !item?.option?.value || item?.option?.value === '-'
                        }
                      >
                        {item?.label && data?.isModifyPossible ? (
                          <Checkbox
                            justifyContent='space-between'
                            onClick={() => selectCell(item)}
                          >
                            {item.label}
                            <Icon
                              imgUrl={
                                selectedAccountInfo.includes(item?.option?.name)
                                  ? checkedIconV2
                                  : checkIconV2
                              }
                              width='24px'
                              height='24px'
                              margin='0 21px 0 0'
                            />
                          </Checkbox>
                        ) : (
                          item.label
                        )}
                      </StyledTh>
                      <StyledTd>
                        <StyledInput
                          name={item?.option?.name}
                          disabled={item?.option?.name === '' || !isEdit}
                          value={
                            isCHUNKAccount(item?.option?.name)
                              ? chunkedAccountNumber(item?.option?.value)
                              : item?.option?.value || ''
                          }
                          onChange={onChange}
                          type='text'
                          placeholder='-'
                        />
                        {isCHUNKAccount(item?.option?.name) &&
                          !!item.option.value && (
                            <>
                              <CopyButton
                                onClick={() => onClickCopy(item.option.value)}
                              >
                                복사
                              </CopyButton>
                              <CopySuccess ref={copySuccessRef} className=''>
                                <img src={BadgeSuccess} alt='' width={24} />
                                계좌번호가 복사되었습니다.
                              </CopySuccess>
                            </>
                          )}
                      </StyledTd>
                    </Fragment>
                  ))}
                </StyledTr>
              </tbody>
            ))}
          </StyledTable>

          {/* 송금정보3 */}
          <StyledTable width='100%' align='center'>
            {formattedAccountInfo?.map((items, index) => (
              <tbody key={index}>
                <StyledTr>
                  {items.map((item, idx) => (
                    <Fragment key={idx}>
                      <StyledTh>
                        {item?.label && data?.isModifyPossible ? (
                          <Checkbox
                            justifyContent='space-between'
                            onClick={() => selectCell(item)}
                          >
                            <div>
                              <Label> {item.label} </Label>
                              <LabelNation>{item.nationLabel}</LabelNation>
                            </div>
                            <Icon
                              imgUrl={
                                selectedAccountInfo.includes(item?.option?.name)
                                  ? checkedIconV2
                                  : checkIconV2
                              }
                              width='24px'
                              height='24px'
                              margin='0 21px 0 0'
                            />
                          </Checkbox>
                        ) : (
                          item.label
                        )}
                      </StyledTh>
                      <StyledTd>
                        <StyledInput
                          name={item.option.name}
                          disabled={item?.option?.name === '' || !isEdit}
                          value={item.option.value || ''}
                          onChange={onChange}
                          type='text'
                          placeholder='-'
                        />
                      </StyledTd>
                    </Fragment>
                  ))}
                </StyledTr>
              </tbody>
            ))}
          </StyledTable>
          {/* 송금정보4 중국인 */}
          {detailRefundInfo.customerNational === 'CHN' && (
            <StyledTable width='100%' align='center'>
              {formattedAccountEngInfo?.map((items, index) => (
                <tbody key={index}>
                  <StyledTr>
                    {items.map((item, idx) => (
                      <Fragment key={idx}>
                        <StyledTh>
                          {item?.label && data?.isModifyPossible ? (
                            <Checkbox
                              justifyContent='space-between'
                              onClick={() => selectCell(item)}
                            >
                              <div>
                                <Label> {item.label} </Label>
                                <LabelNation>{item.nationLabel}</LabelNation>
                              </div>
                              <Icon
                                imgUrl={
                                  selectedAccountInfo.includes(
                                    item?.option?.name,
                                  )
                                    ? checkedIconV2
                                    : checkIconV2
                                }
                                width='24px'
                                height='24px'
                                margin='0 21px 0 0'
                              />
                            </Checkbox>
                          ) : (
                            item.label
                          )}
                        </StyledTh>
                        <StyledTd>
                          <StyledInput
                            name={item.option.name}
                            disabled={item?.option?.name === '' || !isEdit}
                            value={item.option.value || ''}
                            onChange={onChange}
                            type='text'
                            placeholder='-'
                          />
                        </StyledTd>
                      </Fragment>
                    ))}
                  </StyledTr>
                </tbody>
              ))}
            </StyledTable>
          )}
        </>
      )}
      {/* 환급내역 완전 삭제 */}
      <FlexWrap justifyContent='flex-end' margin='0 0 32px 0'>
        <DeleteButton onClick={openDeleteModal}>삭제하기</DeleteButton>
      </FlexWrap>
      {/* 전표 다운로드 */}
      {receiptData && (
        <ReceiptImage
          info={receiptData}
          nation={detailRefundInfo.customerNational}
          index={0}
          setFiles={setFiles}
          style={{
            height: '90%',
            overflowY: 'scroll',
          }}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteModal
          title='환급 건 삭제'
          desc={`삭제를 원하실 경우 취소 코드를 입력해주세요.\n삭제된 환급내역은 복구할 수 없습니다.`}
          isOpen={isOpenDeleteModal}
          onCancel={closeDeleteModal}
          onDelete={handleRefundDelete}
        />
      )}
    </StyledContainer>
  );
}
const Title = styled.h2`
  font-size: 28px;
`;
export const SubTitle = styled.h3`
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
`;
const StyledInput = styled.input`
  color: black;
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  padding-left: 21px;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  flex: 1;
  min-height: ${(props) => (props.isRadio ? 'auto' : '56px')};
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;

const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td`
  ${borderStyles}
  position: relative;
  height: 40px;
  font-size: 14px;
  width: 335px;
  background-color: ${(props) =>
    props.isEdit || props.isForcedEdit ? '#fff' : '#f2f2f2'};
`;
const CopyButton = styled.button`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  padding: 6px 16px;
  background-color: #3a3b3e;
  color: #fff;
  border: 1px solid #e5e6e8;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;
const CopySuccess = styled.div`
  position: absolute;
  bottom: -90%;
  right: 16px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #ffffff;
  background-color: #3a3b3e;
  font-size: 16px;
  font-weight: 500;
  padding: 16px 24px;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, -5px, 0);
  transition: all 200ms ease-in-out;

  &.open {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
  }
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 56px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 335px;
  color: black;
  color: ${(props) => props.isValue && '#757575'};
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
`;
const StyledContainer = styled.div`
  width: 100%;
`;
const Label = styled.p`
  font-size: 14px;
  line-height: 28px;
`;
const LabelNation = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #5f6165;
`;
const EditButton = styled.button`
  background-color: ${(props) => (props.isEdit ? '#757575' : '#354eff')};
  color: #ffffff;
  border-radius: 4px;
  padding: 12px 44px;
  cursor: pointer;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
`;
const DeleteButton = styled.button`
  min-width: 140px;
  height: 44px;
  padding: 6px 32px;
  background-color: #3a3b3e;
  color: #ffffff;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
`;

const Download = styled.button`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  color: #246cf6;
  cursor: pointer;
  flex-shrink: 0;
`;
const CloseBtn = styled.button`
  color: #3a3b3e;
  background-color: #e5e6e8;
  padding: 12px 44px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
`;
export const TransferContainer = styled(FlexWrap)`
  padding-left: 10px;
  gap: 10px;
  align-items: center;
`;
const TransferStatus = styled(FlexWrap)`
  position: relative;
  flex: 1;
  align-items: center;
`;

const MemoInput = styled.textarea`
  padding: 24px 20px;
  width: 100%;
  height: 72px;
  overflow: auto;
  margin-bottom: 40px;
  border-radius: 6px;
  outline: none;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  color: #3a3b3e;
  resize: none;
`;

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const RightSection = styled.div`
  display: flex;
  gap: 16px;
`;

const DepartureSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 21px;
  gap: 12px;
`;

const GoodsShipmentNumber = styled.button`
  padding: 4px 12px;
  border-radius: 6px;
  background-color: #ddd;
  word-break: break-all;
  max-width: 260px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default DetailContent;
