import { Input } from '@successmode-ktp/kui';
import FormWrapper from '../FormWrapper';
import { GrayButton } from './StoreAdd.modal';
import { useEffect, useState } from 'react';
import DaumPostcodeEmbed, { Address } from 'react-daum-postcode';
import Modal from '@/components/common/Modal';
import { AddressData, Poi } from '@/types/api/coupon.type';

type Props = {
  value?: AddressData;
  onChange?: (v: AddressData) => void;
};

const StoreAddress = ({ value, onChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [addressBase, setAddressBase] = useState(value?.base_address || '');
  const [addressDetail, setAddressDetail] = useState(
    value?.address_detail || '',
  );
  const [addressEn, setAddressEn] = useState(value?.address_en || '');
  const [poi, setPoi] = useState<Poi>(
    value?.poi || {
      latitude: 0,
      longitude: 0,
    },
  );

  useEffect(() => {
    onChange &&
      onChange({
        base_address: addressBase,
        address_detail: addressDetail,
        address_en: addressEn,
        poi,
      });
  }, [addressBase, addressDetail, addressEn, poi]);

  useEffect(() => {
    if (value) {
      setAddressBase(value.base_address);
      setAddressDetail(value.address_detail);
      setAddressEn(value.address_en);
      setPoi(value.poi);
    }
  }, [JSON.stringify(value)]);

  const getPosition = (address: string) => {
    const geocoder = new window.kakao.maps.services.Geocoder();
    geocoder.addressSearch(address, (result: any[], status: string) => {
      if (
        status === window.kakao.maps.services.Status.OK &&
        result.length > 0
      ) {
        const { y: latitude, x: longitude } = result[0];
        setPoi({ latitude, longitude });
      } else {
        alert('존재하지 않는 주소입니다.');
      }
    });
  };

  const setAddress = (data: Address) => {
    setAddressBase(data.address);
    setAddressEn(data.addressEnglish);
    setIsOpen(false);
    getPosition(data.address);
  };

  return (
    <>
      <FormWrapper label='주소' required>
        <Input
          value={addressBase}
          size='lg'
          onChange={(e) => setAddressBase(e.target.value)}
          placeholder='도로명 주소를 검색해주세요'
          addonAfter={
            <GrayButton onClick={() => setIsOpen(true)}>주소 검색</GrayButton>
          }
          style={{ fontSize: '14px' }}
        />
        <Input
          value={addressDetail}
          size='lg'
          onChange={(e) => setAddressDetail(e.target.value)}
          placeholder='상세주소를 입력해주세요'
          style={{ fontSize: '14px' }}
        />
        <Input
          value={addressEn}
          size='lg'
          onChange={(e) => setAddressEn(e.target.value)}
          placeholder='영문 주소를 입력해주세요'
          style={{ fontSize: '14px' }}
        />
      </FormWrapper>
      {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          <DaumPostcodeEmbed onComplete={setAddress} />
        </Modal>
      )}
    </>
  );
};
export default StoreAddress;
