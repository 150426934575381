import { TableBody, TableRow } from '@material-ui/core';
import CustomTableCell from '../../common/CustomTableCell';
import styled from 'styled-components';
import { Button } from '@successmode-ktp/kui';
import { useState } from 'react';
import PromotionDetailModal from './PromotionDetail.modal';
import {
  DiscountType,
  PromotionDetail,
  PromotionSummary,
} from '@/types/api/coupon.type';
import { getPromotion, getPromotionDetail } from '@/api/coupon';

type Props = {
  storeId: string;
  data: PromotionSummary[];
};

function StoreTableList({ storeId, data }: Props) {
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);
  const [detailData, setDetailData] = useState<PromotionDetail>();

  const transformedData = data.map((row) => ({
    id: row.id,
    name: row.name,
    couponId: row.coupons.map((coupon) => coupon.id),
    promotion: row.name,
    dateRange: `${row.startDate} ~ ${row.endDate}`,
    coupon: row.coupons.map((coupon) => coupon.name),
    discount: row.coupons.map((coupon) => coupon.discount),
    discountType: row.coupons.map((coupon) => coupon.discountType),
    created: row.coupons.map((coupon) => coupon.issued),
    download: row.coupons.map((coupon) => coupon.downloaded),
    used: row.coupons.map((coupon) => coupon.used),
  }));

  return (
    <>
      <TableBody>
        {transformedData?.map((row, index: number) => (
          <StyledTableRow
            key={row.id}
            index={index}
            onClick={() => {
              setIsOpenDetailModal(true);
              setDetailData({
                date: row.dateRange,
                storeId,
                storeName: row.name,
                promotionId: row.id,
              });
            }}
          >
            <CustomTableCell
              rowSpan={row.coupon.length}
              style={{
                padding: '0 20px',
                maxWidth: '90px',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {row.id}
            </CustomTableCell>
            <CustomTableCell
              rowSpan={row.coupon.length}
              style={{
                padding: '0 20px',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {row.promotion}
            </CustomTableCell>
            <CustomTableCell
              rowSpan={row.coupon.length}
              style={{
                padding: '0 20px',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {row.dateRange}
            </CustomTableCell>
            <CustomTableCell
              style={{
                padding: '0 ',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {row.coupon.map((coupon: string, index: number) => (
                <CustomRow key={index}>
                  <CustomData>{coupon}</CustomData>
                </CustomRow>
              ))}
            </CustomTableCell>
            <CustomTableCell
              style={{
                padding: '0 ',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {row.discount.map((discount: number, index: number) => {
                const type = row.discountType[index];
                const unit = type === 'AMOUNT' ? '원' : '%';
                return (
                  <CustomRow key={index}>
                    <CustomData>{discount + unit}</CustomData>
                  </CustomRow>
                );
              })}
            </CustomTableCell>
            <CustomTableCell
              style={{
                padding: '0 ',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {row.created.map((created: number, index: number) => (
                <CustomRow key={index}>
                  <CustomData>{created}</CustomData>
                </CustomRow>
              ))}
            </CustomTableCell>
            <CustomTableCell
              style={{
                padding: '0 ',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {row.download.map(
                (download: number | undefined, index: number) => (
                  <CustomRow key={index}>
                    <CustomData>{download}</CustomData>
                  </CustomRow>
                ),
              )}
            </CustomTableCell>
            <CustomTableCell
              style={{
                padding: '0 ',
                borderRight: '1px solid #CBCCCE',
                borderCollapse: 'collapse',
              }}
            >
              {row.used.map((used: number, index: number) => (
                <CustomRow key={index}>
                  <CustomData>{used}</CustomData>
                </CustomRow>
              ))}
            </CustomTableCell>
            <CustomTableCell
              rowSpan={row.coupon.length}
              style={{ padding: '0 20px' }}
            >
              <Button
                size='sm'
                variant='line'
                theme='mono'
                style={{ height: '29px', whiteSpace: 'nowrap' }}
              >
                상세보기
              </Button>
            </CustomTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
      {isOpenDetailModal && detailData && (
        <PromotionDetailModal
          data={detailData}
          isOpen={isOpenDetailModal}
          onClose={() => setIsOpenDetailModal(false)}
        />
      )}
    </>
  );
}

const StyledTableRow = styled(TableRow)<{ index?: number }>`
  cursor: pointer;
  background-color: ${({ index = 0 }) =>
    index % 2 === 0 ? '#ffffff' : '#F5F6F7'};

  :hover {
    background-color: #f7f9fc;
  }
`;

const CustomRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 20px;

  &:nth-child(2) {
    border-top: 1px solid #cbccce;
  }
`;

const CustomData = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default StoreTableList;
