import { Dropdown } from '@successmode-ktp/kui';
import { useState } from 'react';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';

type Props = {
  initialSize: string | number;
};

function SizeSelector({ initialSize }: Props) {
  const updateUrlParams = useUpdateUrlParams();
  const [size, setSize] = useState<string | number>(initialSize || '100');

  const viewOptions = [
    {
      label: '10개씩 보기',
      value: '10',
    },
    {
      label: '100개씩 보기',
      value: '100',
    },
    {
      label: '500개씩 보기',
      value: '500',
    },
  ];

  return (
    <Dropdown
      options={viewOptions}
      selectedValue={size}
      setSelectedValue={(filter) => {
        setSize(filter);
        updateUrlParams({ size: filter as string });
      }}
      size='sm'
      style={{
        width: '160px',
      }}
      variant='solid'
    />
  );
}

export default SizeSelector;
