import { Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  title: string;
  message: string;
  okLabel: string;
  onClose: () => void;
  onOK: () => void;
  isWarning?: boolean;
};

function ConfirmModal({
  isOpen,
  title,
  message,
  okLabel,
  onClose,
  onOK,
  isWarning = false,
}: Props) {
  return (
    <Modal isOpen={isOpen} hasBackdrop order={2}>
      <Modal.Header>
        <ModalTitle>{title}</ModalTitle>
      </Modal.Header>
      <ModalContent>{message}</ModalContent>
      <Modal.Footer>
        <Modal.Button variant='cancel' onClick={onClose}>
          닫기
        </Modal.Button>
        <Modal.Button
          variant='ok'
          onClick={onOK}
          style={isWarning ? { backgroundColor: '#ED0828' } : {}}
        >
          {okLabel}
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
}

const ModalTitle = styled.h4`
  width: 100%;
  text-align: center;
  font-size: 21px;
  font-weight: 700;
  color: #030303;
  margin: 0 0 16px 0;
`;

const ModalContent = styled.p`
  width: 400px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #5f6165;
`;

export default ConfirmModal;
