import styled from 'styled-components';
import FormWrapper from '../FormWrapper';
import { useEffect, useState } from 'react';
import { Icon } from '@successmode-ktp/kui';
import { useMutation } from 'react-query';
import { uploadStoreImg } from '@/api/coupon';
import { getFileName } from '@/utils/file.util';

type Props = {
  value?: string[];
  onChange?: (v: string[]) => void;
};

const StoreImages = ({ value, onChange }: Props) => {
  const [images, setImages] = useState<string[]>(value || []);

  useEffect(() => {
    onChange && onChange(images);
  }, [images]);

  useEffect(() => {
    if (value) {
      setImages(value);
    }
  }, [value]);

  const { mutate: uploadImg } = useMutation(
    (files: any) => uploadStoreImg(files),
    {
      onSuccess: (data) => {
        setImages(data?.images!);
      },
      onError: () => {
        alert('이미지 업로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
      },
    },
  );

  const getImagePath = (files: FileList | Array<File>) => {
    if (!files) return;

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const blob = new Blob([files[i]], { type: files[i].type }); // Blob 객체 생성
      formData.append('files', blob, files[i].name);
    }
    uploadImg(formData);
  };

  return (
    <FormWrapper
      label='가맹점 이미지'
      required
      addonAfter={<FileSelect htmlFor='images'>파일 선택</FileSelect>}
    >
      <HideInput
        id='images'
        type='file'
        multiple
        onChange={(e) => getImagePath(e.target.files || [])}
      />
      <ImageList>
        {images &&
          [...images].map((image, index) => {
            return (
              <ImageItem key={index}>
                {getFileName(image)}
                <button
                  type='button'
                  onClick={() => {
                    setImages(() => {
                      const newImages = [...images];
                      newImages.splice(index, 1);
                      return newImages;
                    });
                  }}
                >
                  <Icon.XFilled fillColor='#E5E6E8' size={18} />
                </button>
              </ImageItem>
            );
          })}
        {!images.length && (
          <ImagePlaceholder>이미지를 첨부해주세요</ImagePlaceholder>
        )}
      </ImageList>
    </FormWrapper>
  );
};
export default StoreImages;

const FileSelect = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #3a3b3e;
  border-radius: 8px;
  border: 1px solid #e5e6e8;
  background-color: #f5f6f7;
  white-space: nowrap;
  padding: 4px 12px;
  cursor: pointer;
`;

const HideInput = styled.input`
  position: absolute;
  top: -100px;
  left: -100px;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const ImageList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  min-height: 100px;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #e5e6e8;
  margin: 0;
`;

const ImagePlaceholder = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  color: #cbccce;
`;

const ImageItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3a3b3e;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.28px;
`;
