import store from '@/store';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const ModeSelector = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isProd = store.getState().serverState === 'prod';

  const changeMode = (mode: 'prod' | 'test') => {
    const [_, lastUrl] = pathname.split(mode);
    if (lastUrl) {
      return;
    }

    if (mode === 'prod') {
      const [firstUrl, lastUrl] = pathname.split('test');
      store.dispatch({ type: 'SET_SERVER_PROD' });
      history.push(firstUrl + mode + lastUrl);
    } else {
      const [firstUrl, lastUrl] = pathname.split('prod');
      store.dispatch({ type: 'SET_SERVER_TEST' });
      history.push(firstUrl + mode + lastUrl);
    }
    window.location.reload();
  };

  return (
    <Wrapper>
      <ModeButton
        type='button'
        $isActive={isProd}
        onClick={() => {
          changeMode('prod');
        }}
      >
        운영
      </ModeButton>
      <ModeButton
        type='button'
        $isActive={!isProd}
        onClick={() => {
          changeMode('test');
        }}
      >
        테스트
      </ModeButton>
    </Wrapper>
  );
};
export default ModeSelector;

const Wrapper = styled.div`
  display: flex;
  margin-left: auto;
  border-radius: 5px;
  overflow: hidden;
  height: 40px;
  border: 1px solid #b0b0b0;
`;

const ModeButton = styled.button<{ $isActive: boolean }>`
  background-color: ${({ $isActive }) => ($isActive ? '#354eff' : '#e0e0e0')};
  color: ${({ $isActive }) => ($isActive ? '#fff' : '#757575')};
  width: 50px;
  font-size: 14px;
  font-weight: 600;
`;
