import CouponCard from './CouponCard';
import styled from 'styled-components';
import { CreateCouponTemplateRequest } from '@/types/api/coupon.type';

type Props = {
  data: CreateCouponTemplateRequest[];
  setData: React.Dispatch<React.SetStateAction<CreateCouponTemplateRequest[]>>;
};

const CouponCardList = ({ data, setData }: Props) => {
  const newData =
    data?.map((coupon) => {
      return {
        name: coupon.name || '',
        discount: coupon.couponType?.discountMethod.discount || 0,
        created: coupon.issuedAt || '',
        condition: coupon.couponType?.paymentRange.min || 0,
      };
    }) || [];

  const removeItemByIndex = (index: number) => {
    setData(data.filter((_, itemIndex) => index !== itemIndex));
  };

  return (
    <>
      {newData.length > 0 && (
        <CouponCardWrapper>
          {newData.map((coupon, index) => (
            <CouponCard
              key={index}
              onDelete={() => removeItemByIndex(index)}
              {...coupon}
            />
          ))}
        </CouponCardWrapper>
      )}
    </>
  );
};
export default CouponCardList;

const CouponCardWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
`;
