import { Icon, Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import StoreName from './StoreName';
import StoreMainImage from './StoreMainImage';
import StoreImages from './StoreImages';
import StoreNumber from './StoreNumber';
import StoreAddress from './StoreAddress';
import StoreContents from './StoreContents';
import {
  AddressData,
  RequestLang,
  StoreCategory as StoreCategoryType,
} from '@/types/api/coupon.type';
import StoreCategory from './StoreCategory';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createCouponStore,
  getCouponStoreDetail,
  putCouponStore,
} from '@/api/coupon';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';
import StoreId from './StoreId';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
};

function StoreAddModal({ isOpen, onClose, id }: Props) {
  const ACTION = !!id ? '수정' : '추가';
  const updateUrlParams = useUpdateUrlParams();
  const [isOpenCheckModal, setIsOpenCheckModal] = useState(false);
  const [names, setNames] = useState<RequestLang>({
    KO: '',
    EN: '',
    ZH: '',
    JA: '',
  });
  const [tpayFranchiseeId, setTpayFranchiseeId] = useState('');
  const [category, setCategory] = useState<StoreCategoryType>('PHARMACY');
  const [mainImage, setMainImage] = useState('');
  const [images, setImages] = useState<string[]>([]);
  const [number, setNumber] = useState('');
  const [address, setAddress] = useState<AddressData>({
    base_address: '',
    address_detail: '',
    address_en: '',
    poi: {
      latitude: 0,
      longitude: 0,
    },
  });
  const [contents, setContents] = useState<RequestLang>({
    KO: '',
    EN: '',
    ZH: '',
    JA: '',
  });
  const queryClient = useQueryClient();
  const { data } = useQuery(
    ['getCouponStoreDetail', id],
    () => getCouponStoreDetail(id!),
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (data) {
      setNames(data.promote_store_name!);
      setTpayFranchiseeId(data.tpayFranchiseeId.toString());
      setCategory(data.category);
      setMainImage(data.promotion_image);
      setImages(data.promote_store_images);
      setNumber(data.contract);
      setAddress({
        base_address: data.base_address,
        address_detail: data.address_detail,
        address_en: data.address_en,
        poi: {
          latitude: data.poi.latitude!,
          longitude: data.poi.longitude!,
        },
      });
      setContents(data.promote_store_description);
    }
  }, [data]);

  const PAYLOAD = {
    promote_store_id: id,
    promote_store_name: names,
    promote_store_images: images,
    promotion_image: mainImage,
    contract: number,
    promote_store_description: contents,
    category,
    tpayFranchiseeId: Number(tpayFranchiseeId),
    ...address,
  };

  const { mutate: createStore } = useMutation(
    () => createCouponStore(PAYLOAD),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getCouponStores');
        setIsOpenCheckModal(false);
        onClose();
      },
    },
  );

  const { mutate: updateStore } = useMutation(() => putCouponStore(PAYLOAD), {
    onSuccess: () => {
      queryClient.invalidateQueries('getCouponStores');
      setIsOpenCheckModal(false);
      onClose();
      updateUrlParams({ name: names.KO });
    },
  });

  return (
    <>
      <Modal isOpen={isOpen} hasBackdrop>
        <Modal.Header>
          <Title>가맹점 정보</Title>
          <IconButton onClick={onClose}>
            <Icon.X size={24} fillColor='#CBCCCE' />
          </IconButton>
        </Modal.Header>
        <Content>
          <StoreName value={names} onChange={(v) => setNames(v)} />
          <StoreId
            value={tpayFranchiseeId}
            onChange={(v) => setTpayFranchiseeId(v)}
          />
          <StoreCategory value={category} onChange={(v) => setCategory(v)} />
          <StoreMainImage value={mainImage} onChange={(v) => setMainImage(v)} />
          <StoreImages value={images} onChange={(v) => setImages(v)} />
          <StoreNumber value={number} onChange={(v) => setNumber(v)} />
          <StoreAddress value={address} onChange={(v) => setAddress(v)} />
          <StoreContents value={contents} onChange={(v) => setContents(v)} />
        </Content>
        <Modal.Footer>
          <Modal.Button onClick={onClose} variant='cancel'>
            닫기
          </Modal.Button>
          <Modal.Button onClick={() => setIsOpenCheckModal(true)} variant='ok'>
            {ACTION}하기
          </Modal.Button>
        </Modal.Footer>
      </Modal>
      <Modal order={1} isOpen={isOpenCheckModal} hasBackdrop>
        <Modal.Header>
          <CheckModalTitle>가맹점을 {ACTION}하시겠습니까?</CheckModalTitle>
        </Modal.Header>
        <CheckModalDescription>
          입력된 정보로 가맹점이 {ACTION}됩니다.
        </CheckModalDescription>
        <Modal.Footer>
          <Modal.Button
            variant='cancel'
            onClick={() => setIsOpenCheckModal(false)}
          >
            닫기
          </Modal.Button>
          <Modal.Button
            variant='ok'
            onClick={() => (!!id ? updateStore() : createStore())}
          >
            {ACTION}하기
          </Modal.Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StoreAddModal;

const Title = styled.h3`
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  margin-bottom: 16px;
  text-align: center;
`;

const IconButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const Content = styled.div`
  width: 440px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CheckModalTitle = styled.h4`
  font-size: 21px;
  font-weight: 700;
  color: #030303;
  width: 100%;
  text-align: center;
  margin: 0 0 16px 0;
`;

const CheckModalDescription = styled.p`
  width: 400px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #5f6165;
`;

export const GrayButton = styled.button`
  font-size: 14px;
  font-weight: 600;
  color: #3a3b3e;
  border-radius: 8px;
  border: 1px solid #e5e6e8;
  background-color: #f5f6f7;
  white-space: nowrap;
  padding: 4px 12px;
`;
