import styled from 'styled-components';
import checkIcon from '@/assets/common/check.png';
import checkedIcon from '@/assets/common/checked.png';
import Icon from '@/components/common/Icon';
import { Button } from '@successmode-ktp/kui';

const RenderTableData = ({
  column,
  data,
  id,
  checkedRow,
  onClick,
  onOpenDetail,
  onDownload,
}: {
  column: any;
  data: any;
  id: number;
  checkedRow?: number[];
  returnDate?: string;
  onClick?: () => void;
  onOpenDetail?: ({ id }: { id: number }) => void;
  onDownload: () => void;
}) => {
  const handleOpenDetail = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onOpenDetail && onOpenDetail({ id });
  };

  const handleClickDownload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onDownload();
  };

  if (column === 'selection') {
    return (
      <CheckBox onClick={onClick}>
        <Icon
          imgUrl={checkedRow?.includes(id) ? checkedIcon : checkIcon}
          width='20px'
          height='20px'
        />
      </CheckBox>
    );
  }

  if (column === 'downloadButton') {
    return (
      <Button
        onClick={handleClickDownload}
        size='sm'
        enabled
        style={{ backgroundColor: 'transparent' }}
      >
        다운로드
      </Button>
    );
  }

  if (column === 'detailButton') {
    return (
      <Button onClick={handleOpenDetail} size='sm' enabled>
        상세보기
      </Button>
    );
  }

  return (
    <Data
      style={
        column === 'storeName' || column === 'hospitalName'
          ? { justifyContent: 'flex-start' }
          : {}
      }
    >
      {data}
    </Data>
  );
};

export default RenderTableData;

const Data = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

const CheckBox = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;
