import PreviewMailTemplate from '@/components/medical/nonDeparture/PreviewMailTemplate';
import { Button, Popover } from '@successmode-ktp/kui';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  count: number;
  price: number;
};

function PreviewMailModal({ isOpen, onClose, count, price }: Props) {
  return (
    <Popover
      isOpen={isOpen}
      hasBackdrop
      order={2}
      style={{
        backgroundColor: '#F4F4F4',
        padding: '40px',
        borderRadius: '40px',
      }}
    >
      <Header>메일 발송 미리보기</Header>
      <Content>
        <PreviewMailTemplate count={count} price={price} />
      </Content>
      <Footer>
        <Button
          theme='mono'
          onClick={onClose}
          style={{ backgroundColor: '#DDDDDD', width: '210px' }}
        >
          닫기
        </Button>
      </Footer>
    </Popover>
  );
}

const Header = styled.header`
  margin-bottom: 36px;
  color: #111;
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
`;

const Content = styled.main`
  padding: 40px;
  border-radius: 20px;
  background-color: #fff;
  width: 90vw;
  max-width: 1040px;
  height: 65vh;
  max-height: 712px;
  overflow-y: auto;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
`;

export default PreviewMailModal;
