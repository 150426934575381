import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import styled from 'styled-components';
import FormItemLayout from '@/components/medical/notice/FormItemLayout';
import { BANNER_HOSPITAL_TYPES } from '@/constants/medical';
import CheckBox from './CheckBox';
import DateRangePicker from './DateRangePicker';

interface IProps {
  state: any;
  onChange: (name: string, value: any) => void;
  disabled?: boolean;
}
function Form({ state, onChange, disabled }: IProps) {
  return (
    <FormContainer>
      <ContentContainer>
        <FormItemLayout label='공지기간' required labelWidth={160}>
          <DateRangePicker
            state={state}
            onChange={onChange}
            disabled={disabled}
          />
        </FormItemLayout>
        <FormItemLayout label='병원 선택' required labelWidth={160}>
          <CheckBoxList>
            <CheckBox
              id='total-type'
              label='전체'
              value='total'
              isCheck={
                state.originalVisible &&
                state.directVisible &&
                state.kioskVisible
              }
              onClick={() => {
                onChange('hospitalType', 'total');
              }}
              disabled={disabled}
            />
            {BANNER_HOSPITAL_TYPES.map(({ value, label }) => (
              <CheckBox
                id={value}
                label={label}
                value={value}
                isCheck={state[value]}
                onClick={(value: string) => onChange('hospitalType', value)}
                disabled={disabled}
              />
            ))}
          </CheckBoxList>
        </FormItemLayout>
        <FormItemLayout label='노출 여부' required labelWidth={160}>
          <RadioGroup
            value={!!state.isActivated ? 'visible' : 'invisible'}
            onChange={(e: any) =>
              onChange('isActivated', e.target.value === 'visible')
            }
            style={{ display: 'flex', flexDirection: 'row', gap: '56px' }}
          >
            <FormControlLabel
              value='visible'
              control={
                <Radio
                  checked={state.isActivated}
                  name='visible'
                  color='primary'
                  size='small'
                  disabled={disabled}
                />
              }
              label='노출'
            />
            <FormControlLabel
              value='invisible'
              control={
                <Radio
                  checked={!state.isActivated}
                  name='invisible'
                  color='primary'
                  size='small'
                  disabled={disabled}
                />
              }
              label='미노출'
            />
          </RadioGroup>
        </FormItemLayout>
        <FormItemLayout label='제목' required labelWidth={160}>
          <StyledInput
            name='title'
            defaultValue={state.title}
            onChange={(e: any) => onChange('title', e.target.value)}
            placeholder='제목을 입력해주세요.'
            disabled={disabled}
          />
        </FormItemLayout>
      </ContentContainer>
    </FormContainer>
  );
}
const FormContainer = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 12px 0;
`;

const StyledInput = styled.input`
  padding: 8px 12px;
  border: 1px solid lightgray;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  flex: 1;
`;

const CheckBoxList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 72px;
`;

export default Form;
