import { getAdList, getDefaultAdList } from '@/api/ktp/ad.api';
import { getMedicalAdList } from '@/api/medical/ad.api';
import { getEndPoint } from '@/utils/api.util';
import { useQuery } from 'react-query';

const useGetAdList = (id?: number) => {
  const endPoint = getEndPoint(id);

  const getTargetApi = (id?: number) => {
    if (!id) {
      return getDefaultAdList();
    }

    switch (endPoint) {
      case 'medical':
        return getMedicalAdList(id);
      case 'store':
        return getAdList(id);
      default:
        return getDefaultAdList();
    }
  };

  const { data, isLoading, isSuccess } = useQuery('getAdList', () =>
    getTargetApi(id),
  );

  return { data, isLoading, isSuccess };
};
export default useGetAdList;
