import { Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';
import { useState } from 'react';
import ConfirmModal from '../Confirm.modal';
import CouponName from './CouponName';
import CouponType from './CouponType';
import CouponPrice from './CouponPrice';
import CouponCount from './CouponCount';
import CouponRule from './CouponRule';
import {
  CreateCouponTemplateRequest,
  DiscountType,
} from '@/types/api/coupon.type';

type Period = {
  issuedAt: string;
  expireAt: string;
};

type Props = {
  storeId: string;
  promotionId?: string;
  date?: Period;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (v: CreateCouponTemplateRequest) => void;
};

function CouponAddModal({
  storeId,
  promotionId,
  date,
  isOpen,
  onClose,
  onSubmit,
}: Props) {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [name, setName] = useState('');
  const [discountType, setDiscountType] = useState<DiscountType>('AMOUNT');
  const [discount, setDiscount] = useState(0);
  const [count, setCount] = useState(0);
  const [min, setMin] = useState(0);

  const PAYLOAD = {
    storeId,
    promotionId: promotionId || '',
    ...date,
    name,
    couponType: {
      paymentRange: {
        min,
      },
      discountMethod: {
        discountType,
        discount,
      },
      issuanceMethod: {
        type: 'Counter',
        quantity: count,
      },
    },
  };

  return (
    <>
      <Modal isOpen={isOpen} hasBackdrop order={1}>
        <Modal.Header>
          <Title>쿠폰 추가하기</Title>
        </Modal.Header>
        <Content>
          <CouponName onChange={(v) => setName(v)} />
          <CouponType onChange={(v) => setDiscountType(v)} />
          <CouponPrice
            discountType={'AMOUNT'}
            onChange={(v) => setDiscount(v)}
          />
          <CouponCount onChange={(v) => setCount(v)} />
          <CouponRule onChange={(v) => setMin(v)} />
        </Content>
        <Modal.Footer>
          <Modal.Button onClick={onClose} variant='cancel'>
            닫기
          </Modal.Button>
          <Modal.Button
            onClick={() => setIsOpenConfirmModal(true)}
            variant='ok'
          >
            추가하기
          </Modal.Button>
        </Modal.Footer>
      </Modal>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        onClose={() => setIsOpenConfirmModal(false)}
        onOK={() => {
          onSubmit(PAYLOAD);
          onClose();
        }}
        title='쿠폰을 추가하시겠습니까?'
        message='쿠폰이 발행됩니다.'
        okLabel='추가하기'
      />
    </>
  );
}

export default CouponAddModal;

const Title = styled.h3`
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  margin-bottom: 16px;
  text-align: center;
`;

const Content = styled.div`
  width: 440px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
