import Title from '@/components/common/Title';
import { Container } from '@material-ui/core';
import styled from 'styled-components';
import DateSelector from '@/components/common/RefundStatement/DateSelector';
import { useState } from 'react';
import Search from '@/components/common/Search';
import Table from '@/components/common/RefundStatement/Table';
import { REFUND_STATEMENT_HEADER } from '@/constants/medical';
import SizeSelector from '@/components/common/RefundStatement/SizeSelector';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';
import { useLocation } from 'react-router-dom';
import { Button, Pagination } from '@successmode-ktp/kui';
import FileSelector from '@/components/common/RefundStatement/FileSelector';
import { useMutation, useQuery } from 'react-query';
import {
  downloadSelectedRefund,
  getHospitalKeywords,
  getRefundHospitalList,
} from '@/api/medical';
import JSZip from 'jszip';
import { format } from 'date-fns';

function HospitalRefundStatement() {
  const updateUrlParams = useUpdateUrlParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialSize = searchParams.get('size') || '100';
  const initialPage = searchParams.get('page') || '0';
  const searchKeyword = searchParams.get('search') || '';
  const year = searchParams.get('year') || new Date().getFullYear().toString();
  const month =
    searchParams.get('month') || (new Date().getMonth() + 1).toString();
  const [page, setPage] = useState(
    initialPage === '0' ? 1 : Number(initialPage) + 1,
  );
  const [totalPage, setTotalPage] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  const [checkedRow, setCheckedRow] = useState<number[]>([]);

  const [isOpenFile, setIsOpenFile] = useState(false);
  const [isOpenSummary, setIsOpenSummary] = useState(false);

  const { data: keywords } = useQuery('hospitalKeywords', getHospitalKeywords, {
    retry: false,
  });

  const { data } = useQuery(
    ['refundStoreList', year, month, page, initialSize, searchKeyword],
    () =>
      getRefundHospitalList({
        year,
        month,
        page: (page - 1).toString(),
        size: initialSize,
        keyword: searchKeyword,
      }),
    {
      onSuccess: (data) => {
        setTotalPage(data.totalPages);
        setIsFirstPage(data.first);
        setIsLastPage(data.last);
      },
      enabled: !!year && !!month,
    },
  );

  const handleToggleRow = (id: number) => {
    const newCheckedRow = checkedRow.includes(id)
      ? checkedRow.filter((item) => item !== id)
      : [...checkedRow, id];
    setCheckedRow(newCheckedRow);
  };

  const handleToggleAllRow = () => {
    if (checkedRow.length === 0) {
      setCheckedRow(data?.content.map((item: any) => item.hospitalId) || []);
    } else {
      setCheckedRow([]);
    }
  };

  const downloadZipData = async (
    files: { fileName: string; path: string }[],
    zipTitle?: string,
  ) => {
    try {
      const zip = new JSZip();

      for (const file of files) {
        const { fileName, path: filePath } = file;

        const fileType = filePath.split('.').pop();
        const finalFileName = `${fileName}.${fileType}`;
        const response = await fetch(filePath);
        const blob = await response.blob();

        zip.file(finalFileName, blob);
      }

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      const title =
        zipTitle || `(의료)_실적 파일-${format(new Date(), 'yyyyMMdd')}`;
      const link = document.createElement('a');
      const url = URL.createObjectURL(zipBlob);
      link.href = url;
      link.download = `${title}.zip`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);

      return { success: true };
    } catch (error) {
      console.error('압축 파일 생성 중 오류 발생:', error);
      return { success: false, error };
    }
  };

  const { mutate: mutateDownloadSelected, isLoading } = useMutation(
    downloadSelectedRefund,
  );

  const handleDownloadSelected = () => {
    mutateDownloadSelected(
      {
        hospitalIds: checkedRow,
        year,
        month,
      },
      {
        onSuccess: async (data) => {
          await downloadZipData(data.recordFiles);
          setCheckedRow([]);
        },
      },
    );
  };

  const { mutate: mutateDownloadItem } = useMutation(downloadSelectedRefund);

  const handleDownloadItem = ({ id, name }: { id: number; name: string }) => {
    mutateDownloadItem(
      {
        hospitalIds: [id],
        year,
        month,
      },
      {
        onSuccess: async (data) => {
          await downloadZipData(data.recordFiles, name);
        },
      },
    );
  };

  return (
    <>
      <StyledContainer maxWidth='xl'>
        <Title>실적관리</Title>
        <Section>
          <DateSelector year={year} month={month} />
          <DownloadSection>
            <FileSelector
              isOpen={isOpenFile}
              onToggle={() => setIsOpenFile((prev) => !prev)}
              title='실적파일'
              isMedical
            />
            <FileSelector
              isOpen={isOpenSummary}
              onToggle={() => setIsOpenSummary((prev) => !prev)}
              title='요약본'
              isSummary
              isMedical
            />
          </DownloadSection>
        </Section>
        <Search data={keywords || []} placeholder='상호명을 입력하세요.' />
        <Section style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button
            size='sm'
            style={{ width: '136px' }}
            disabled={checkedRow.length === 0 || isLoading}
            onClick={handleDownloadSelected}
          >
            {isLoading ? '다운로드 중' : '선택 다운로드'}
          </Button>
          <SizeSelector initialSize={initialSize} />
        </Section>
        <Table
          column={REFUND_STATEMENT_HEADER}
          row={data?.content || []}
          onClick={handleToggleRow}
          checkedRow={checkedRow}
          onCheckAllRow={handleToggleAllRow}
          isMedical
          onDownload={handleDownloadItem}
        />
        {totalPage > 0 && (
          <Pagination
            page={page}
            totalPage={totalPage}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            displaySize={5}
            setPage={(page) => {
              setPage(page);
              updateUrlParams({ page: (page - 1).toString() });
            }}
          />
        )}
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  width: 100%;
`;

const DownloadSection = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  gap: 12px;
`;

export default HospitalRefundStatement;
